import React, { ReactElement, useContext } from 'react'
import { Dialog } from '@reach/dialog'
import { useIntl } from 'react-intl'
import { Form, Formik } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { DialogHeaderArea } from '../../../../shared/dialog/DialogHeaderArea'
import { DialogAction } from '../../../../shared/dialog/DialogAction'
import { IconClose } from '../../../../shared/svgs'
import { DialogTitle } from '../../../../shared/dialog/DialogTitle'
import { ContentArea } from '../../../../shared/content'
import { HeaderLeftArea } from '../../../../shared/header'
import { AuthContext } from '../../../../auth/AuthContext'
import {
  NotificationAssignmentType,
  NotificationFormFields,
} from '../../../notification/forms/types'
import { ResourceType } from '../../../../shared/utils/resourceTypes'
import { GeneralEditSection } from '../../../notification/forms/GeneralEditSection'
import { AssignEditSection } from '../../../notification/forms/AssignEditSection'
import { SectionActions } from '../../../../shared/section/SectionActions'
import { CancelButton } from '../../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../../shared/form/button/SubmitButton'
import { ButtonVariant } from '../../../../shared/button'
import { useGetValidationSchema } from '../../../notification/forms/notificationFormValidation'
import { Group } from '../../types'
import { translationsShared } from '../../../../shared/translations/translationsShared'
import { translationsActivity } from '../../translations/translationsActivity'
import { formatDateForAPI } from '../../../../shared/utils/apiUtils'

export type Props = {
  isOpen: boolean
  providedValues: NotificationFormFields | null
  activityGroup: Group
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (values: NotificationFormFields) => void
}

function NotificationDialog({
  isOpen,
  providedValues,
  activityGroup,
  onDismiss,
  onConfirm,
}: Props): ReactElement {
  const intl = useIntl()

  const title = intl.formatMessage(translationsActivity.notificationAdd)

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={title}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={onDismiss}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="h-7 w-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{title}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        {activityGroup && (
          <AsyncView
            data={activityGroup}
            renderSuccess={(data) => (
              <NotificationSectionForm
                onCancel={onDismiss}
                onSubmit={onConfirm}
                providedValues={providedValues}
                activityGroup={data}
              />
            )}
          />
        )}
      </ContentArea>
    </Dialog>
  )
}

function NotificationSectionForm({
  onCancel,
  onSubmit,
  providedValues,
  activityGroup,
}: {
  onCancel: () => void
  onSubmit: (values: NotificationFormFields) => void
  providedValues: NotificationFormFields | null
  activityGroup: Group
}): ReactElement {
  const initialValues = useGetInitialValues(providedValues, activityGroup)

  const schema = useGetValidationSchema()

  const prepareAndSubmit = (values: NotificationFormFields) => {
    onSubmit({
      ...values,
      userId: values.userId || null,
      addressGroupId: values.addressGroupId || null,
      time: values.time ? formatDateForAPI(values.time) : null,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={prepareAndSubmit}
      validationSchema={schema}
      validateOnChange={false}
    >
      {(props) => (
        <Form>
          <GeneralEditSection formik={props} />
          <AssignEditSection formik={props} />
          <SectionActions>
            <CancelButton
              onClick={onCancel}
              variant={ButtonVariant.primaryLightOutlined}
            />
            <SubmitButton variant={ButtonVariant.primaryLight} />
          </SectionActions>
        </Form>
      )}
    </Formik>
  )
}

const useGetInitialValues = (
  providedValues: NotificationFormFields | null,
  activityGroup: Group
): NotificationFormFields => {
  const auth = useContext(AuthContext)
  const userId = auth.data?.user.id || ''

  const defaultType = activityGroup.defaultNotificationType ?? ''

  const defaultValues: NotificationFormFields = {
    id: '',
    sort: '-',
    type: defaultType,
    userId: userId,
    addressGroupId: '',
    referencedResourceType: ResourceType.activity,
    referencedResourceId: '', // This will be replace with the activity id after form submit
    assignmentType: NotificationAssignmentType.user,
    time: null,
    message: '',
    archived: false,
    visibility: {
      dashboard: true,
    },
    createdAt: '',
    updatedAt: '',
  }

  return {
    ...defaultValues,
    ...providedValues,
  }
}

export { NotificationDialog }
