import { Translations } from '../shared/translations/types'
import deMessages from './de.json'
import itMessages from './it.json'

const translations: Translations = {
  en: {},
  de: deMessages,
  it: itMessages,
}

export default translations
