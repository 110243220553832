import React, { useState } from 'react'
import { useField } from 'formik'
import { FormattedMessage } from 'react-intl'

import { useGetProjectById } from '../../project/db/projects'
import { ProjectVisibility } from '../../project/types'
import { SelectProjectDialog } from './SelectProjectDialog'
import { SelectWithDialogField } from './SelectWithDialogField'

type Props = {
  projectIdFieldName: string
  projectVisibility?: ProjectVisibility
  disabled?: boolean
}

const SelectProject: React.FC<Props> = ({
  projectIdFieldName,
  projectVisibility,
  disabled,
}) => {
  const [, meta, helpers] = useField({ name: projectIdFieldName })
  const [showPopUp, setShowPopUp] = useState(false)
  const { value } = meta
  const { data: selectedProject } = useGetProjectById(value || '')

  const { setValue } = helpers

  return (
    <>
      <SelectWithDialogField
        touched={meta.touched}
        hasError={!!meta.error}
        fieldName={projectIdFieldName}
        value={selectedProject?.name || ''}
        disabled={disabled}
        onClick={(): void => {
          setShowPopUp(true)
        }}
        label={
          <FormattedMessage
            id="timeTracking.edit.project.label"
            defaultMessage="Project"
          />
        }
      />
      <SelectProjectDialog
        projectVisibility={projectVisibility}
        isOpen={showPopUp}
        onDismiss={(): void => {
          setShowPopUp(false)
        }}
        onConfirm={(value): void => {
          setValue(value)
          setShowPopUp(false)
        }}
      />
    </>
  )
}

export { SelectProject }
