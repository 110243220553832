import Axios, { CancelToken } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { FileToUpload, RemoteFile } from './types'

async function uploadFile(file: FileToUpload, cancelToken: CancelToken) {
  let fd = new FormData()
  fd.append('file', file.file)
  return Axios.post(`/v1/user/files/${file.fileId}`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: cancelToken,
  })
}

export function getFiles(): Promise<Array<RemoteFile>> {
  return axiosGet('/v1/user/files')
}

export { uploadFile }
