import { Address, AddressGroup, AddressType } from '../address/types'
import { Activity, Category, Group } from '../activity/types'
import { AddressGroupReference } from './types'

export function populateUserAddressGroups(
  userAddressGroups: Array<AddressGroupReference> | null,
  addressGroups: Array<AddressGroup> | null
): Array<AddressGroup> {
  if (!userAddressGroups || !addressGroups) {
    return []
  }

  const result: AddressGroup[] = []

  userAddressGroups.forEach((userAddressGroup) => {
    const foundAddressGroup = addressGroups.find((addressGroup) => {
      return addressGroup.id === userAddressGroup.id
    })

    if (foundAddressGroup !== undefined) {
      result.push(foundAddressGroup)
    }
  })

  return result
}

export function populateUserAddressTypes(
  userAddresses: Array<Address> | null,
  addressTypes: Array<AddressType> | null
): Array<AddressType> {
  const result: Array<AddressType> = []
  if (!userAddresses || !addressTypes) {
    return result
  }
  const userAddressTypeIds = userAddresses.map(
    (userAddress) => userAddress.addressTypeId
  )
  return addressTypes.filter((addressType) =>
    userAddressTypeIds.includes(addressType.id)
  )
}
export function populateUserActivityCategories(
  activityGroup: Group | null,
  activityCategories: Array<Category> | null
): Array<Category> {
  const result: Array<Category> = []
  if (!activityGroup || !activityCategories || !activityGroup.categoryIds) {
    return result
  }
  return activityCategories.filter((category) =>
    activityGroup.categoryIds.includes(category.id)
  )
}
export function getUsedCategories(
  categories: Array<Category> | null,
  activities: Array<Activity> | null
): Array<Category> {
  const result: Array<Category> = []
  if (!categories || !activities) {
    return result
  }
  const usedCategoryIds = activities.map(
    (activity) => activity.activityCategoryId
  )
  return categories.filter((category) => usedCategoryIds.includes(category.id))
}
