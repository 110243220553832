import React from 'react'
import { Address } from '../../../address/types'
import { DialogSelectionListItemWithText } from '../../../../shared/dialog/selection/DialogSelectionListItemWithText'
import { AddressTypeBadge } from '../../../address/shared/AddressTypeBadge'

const AddressDialogItem: React.FC<{
  address: Address
  onSelect: (addressId: string) => void
}> = ({ address, onSelect }) => {
  return (
    <DialogSelectionListItemWithText
      text={address.name}
      adornment={<AddressTypeBadge typeId={address.addressTypeId} />}
      itemId={address.id}
      onSelect={onSelect}
    />
  )
}

export { AddressDialogItem }
