import React from 'react'
import { useIntl } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { SectionListVariant } from '../../../shared/section/SectionList'
import { Address } from '../types'
import { AddressOverviewItem } from './AddressOverviewItem'

const AddressOverviewFavorites: React.FC<{
  addresses: Array<Address> | null
}> = ({ addresses }) => {
  const intl = useIntl()

  if (addresses === null || addresses.length === 0) return null

  return (
    <Section>
      <SectionTitle>
        {intl.formatMessage({
          id: 'address.overview.list.favorites',
          defaultMessage: 'Favorites',
        })}
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {addresses.map((address, index) => (
          <AddressOverviewItem address={address} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { AddressOverviewFavorites }
