import { useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import React, { ReactElement } from 'react'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { useGetNotificationsByResource } from '../db/notifications'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { translationsDashboard } from '../../dashboard/translations/translationsDashboard'
import { NotificationOverviewList } from './NotificationOverviewList'

type Props = {
  resourceType: ResourceType
  resourceId: string
  showReferencedResource?: boolean
}

function NotificationOverviewByResource({
  resourceType,
  resourceId,
  showReferencedResource = false,
}: Props): ReactElement {
  const intl = useIntl()
  const { data } = useGetNotificationsByResource(resourceType, resourceId)

  const title = intl.formatMessage(
    translationsDashboard.overviewNotificationsTitle
  )

  return (
    <AsyncView
      data={data}
      renderLoading={<SectionListLoading title={title} />}
      renderSuccess={(data) =>
        data && (
          <NotificationOverviewList
            title={title}
            notifications={data}
            showAddress={true}
            showReferencedResource={showReferencedResource}
          />
        )
      }
    />
  )
}

export { NotificationOverviewByResource }
