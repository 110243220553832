import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'

const GeneralEditSection: React.FC = () => {
  const intl = useIntl()

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="address.edit.title.general"
            defaultMessage="General information"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <InputWithLabel
              type="text"
              name="name"
              placeholder={intl.formatMessage({
                id: 'address.edit.name.placeholder',
                defaultMessage: 'Name of the address',
              })}
              label={
                <FormattedMessage
                  id="address.edit.name.label"
                  defaultMessage="Name"
                />
              }
            />
            <InputWithLabel
              type="text"
              name="address.street"
              placeholder={intl.formatMessage({
                id: 'address.edit.street.placeholder',
                defaultMessage: 'Name of the street',
              })}
              label={
                <FormattedMessage
                  id="address.edit.street.label"
                  defaultMessage="Street"
                />
              }
            />
            <InputWithLabel
              type="text"
              name="address.cap"
              placeholder={intl.formatMessage({
                id: 'address.edit.cap.placeholder',
                defaultMessage: 'Postal code',
              })}
              label={
                <FormattedMessage
                  id="address.edit.cap.label"
                  defaultMessage="ZIP Code"
                />
              }
            />
            <InputWithLabel
              type="text"
              name="address.city"
              placeholder={intl.formatMessage({
                id: 'address.edit.city.placeholder',
                defaultMessage: 'Name of the city ',
              })}
              label={
                <FormattedMessage
                  id="address.edit.city.label"
                  defaultMessage="City"
                />
              }
            />
            <InputWithLabel
              type="text"
              name="address.country"
              placeholder={intl.formatMessage({
                id: 'address.edit.country.placeholder',
                defaultMessage: 'Name of the country',
              })}
              label={
                <FormattedMessage
                  id="address.edit.country.label"
                  defaultMessage="Country"
                />
              }
            />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { GeneralEditSection }
