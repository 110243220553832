import React, { ReactElement, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { DeleteDialog } from '../../../shared/dialog/DeleteDialog'
import { deleteActivity } from '../api'
import { deleteActivity as deleteActivityFromDB } from '../db/activities'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { AppContext } from '../../../app/AppContext'

function ActivityDeleteDialog({
  activityId,
  isOpen,
  onDismiss,
  activityGroupId,
}: {
  activityId: string
  isOpen: boolean
  onDismiss: () => void
  activityGroupId?: string
}): ReactElement {
  const intl = useIntl()
  const { addAlertErrorMessage } = useContext(AppContext)

  const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(false)
  const navigate = useNavigate()
  const onActivityDelete = async () => {
    try {
      setDisableDeleteButton(true)
      await deleteActivity(activityId)
      await deleteActivityFromDB(activityId)
      activityGroupId
        ? navigate(`/activity-groups/${activityGroupId}`)
        : navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    } finally {
      onDismiss()
      setDisableDeleteButton(false)
    }
  }
  return (
    <DeleteDialog
      isOpen={isOpen}
      disableDelete={disableDeleteButton}
      onDismiss={onDismiss}
      onConfirm={onActivityDelete}
      title={intl.formatMessage({
        id: 'activity.detail.delete.title',
        defaultMessage: 'Delete activity',
      })}
      text={intl.formatMessage({
        id: 'activity.detail.delete.text',
        defaultMessage: 'Are you sure you want to delete this activity?',
      })}
    />
  )
}

export { ActivityDeleteDialog }
