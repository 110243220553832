import React, { ReactNode } from 'react'
import { DialogSelectionListItemWithText } from './DialogSelectionListItemWithText'

type Id = string | number

type Props<T extends Id> = {
  action?: ReactNode | undefined
  text: string
  adornment?: ReactNode
  itemId: T
  onSelect: (itemId: T) => void
}

function DialogSelectionListItemWithActionAndText<T extends Id>({
  action,
  text,
  adornment,
  itemId,
  onSelect,
}: Props<T>) {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 ml-3 flex items-center md:ml-5">
        {action}
      </div>
      <DialogSelectionListItemWithText
        itemId={itemId}
        text={text}
        adornment={adornment}
        onSelect={onSelect}
        className="pl-9 md:pl-10"
      />
    </div>
  )
}

export { DialogSelectionListItemWithActionAndText }
