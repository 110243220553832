import React from 'react'
import { Navigate } from 'react-router-dom'
import { HomeLayout } from './HomeLayout'

const HomePage: React.FC = () => {
  if (window.innerWidth > 760) {
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
        replace
      />
    )
  }

  return <HomeLayout />
}

export { HomePage }
