import React, { useState } from 'react'
import { FormikProps } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { ContentArea } from '../../../shared/content'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { useGetAddressById } from '../../address/db/addresses'
import { SectionListFormError } from '../../../shared/section/SectionListFormError'
import { AddressTypeBadge } from '../../address/shared/AddressTypeBadge'
import { ActivityFieldState } from '../types'
import { ActivityFormFields } from './types'
import { FormDialogSelectItem } from './FormDialogSelectItem'
import { AddressDialog } from './addressDialog/AddressDialog'
import { FormItem } from './FormItem'

const AddressFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
  fieldState: ActivityFieldState | undefined
}> = ({ formik, fieldState }) => {
  const { values, setFieldValue } = formik
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const { data } = useGetAddressById(values.addressId)

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="activity.form.address.title"
            defaultMessage="Linked address"
          />
        </SectionTitle>
        <SectionList>
          {data ? (
            <AsyncView
              data={data}
              renderSuccess={(data) =>
                values.projectId !== '' ||
                fieldState === ActivityFieldState.ReadOnly ? (
                  <FormItem>
                    <ItemContent
                      name={data.name}
                      addressTypeId={data.addressTypeId}
                    />
                  </FormItem>
                ) : (
                  <FormDialogSelectItem onClick={() => setShowDialog(true)}>
                    <ItemContent
                      name={data.name}
                      addressTypeId={data.addressTypeId}
                    />
                  </FormDialogSelectItem>
                )
              }
            />
          ) : (
            <FormDialogSelectItem onClick={() => setShowDialog(true)}>
              <FormattedMessage
                id="activity.form.address.empty"
                defaultMessage="No linked address"
              />
            </FormDialogSelectItem>
          )}
          <SectionListFormError name="addressId" />
        </SectionList>
      </Section>
      <AddressDialog
        onDismiss={() => setShowDialog(false)}
        onConfirm={(addressId) => {
          setFieldValue('addressId', addressId)
          setShowDialog(false)
        }}
        isOpen={showDialog}
      />
    </ContentArea>
  )
}

function ItemContent({
  name,
  addressTypeId,
}: {
  name: string
  addressTypeId: string
}) {
  return (
    <div className="flex">
      <div className="flex-1">{name}</div>
      <AddressTypeBadge typeId={addressTypeId} />
    </div>
  )
}

export { AddressFormSection }
