import React from 'react'
import { Dialog } from '@reach/dialog'
import { ArticleAmountView } from './steps/ArticleAmountView'

const ArticleEditDialog: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  isOpen: boolean
  onConfirm: (amount: string, descriptionText: string) => void
  amount?: string
  label: string
  descriptionText?: string
  articleId: string
}> = ({
  isOpen,
  onConfirm,
  onDismiss,
  amount = '1',
  label,
  descriptionText = '',
  articleId,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      aria-label={label}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <ArticleAmountView
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        amount={amount}
        descriptionText={descriptionText}
        articleId={articleId}
      />
    </Dialog>
  )
}

export { ArticleEditDialog }
