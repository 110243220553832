import { defineMessages } from 'react-intl'

export const translationsUser = defineMessages({
  overviewTitle: {
    id: 'user.overview.title',
    defaultMessage: 'User Overview',
  },
  overviewList: {
    id: 'user.overview.list',
    defaultMessage: 'List of users',
  },
  overviewListEmpty: {
    id: 'user.overview.list.empty',
    defaultMessage: 'No users available',
  },
})
