import React, { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { FormattedMessage, useIntl } from 'react-intl'
import { ErrorMessage } from 'formik'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitleWithAction,
} from '../../../shared/section'
import { ContentArea } from '../../../shared/content'
import { IconSync } from '../../../shared/svgs'
import { ActivitySignatureValues } from './ActivitySignature'

function useRemountComponent(): [number, () => void] {
  const [value, setValue] = useState<number>(0)
  return [value, () => setValue((value) => value + 1)]
}

const SignaturePadFieldError: React.FC = ({ children }) => {
  return (
    <SectionItem>
      <div className="text-sm text-red-500">{children}</div>
    </SectionItem>
  )
}

const SignaturePadField: React.FC<{ values: ActivitySignatureValues }> = ({
  values,
}) => {
  const intl = useIntl()
  const [signPadKey, remountSignPad] = useRemountComponent()

  let signPad: SignatureCanvas

  return (
    <ContentArea>
      <Section>
        <SectionTitleWithAction
          onClick={(evt) => {
            evt.preventDefault()
            signPad.clear()
            values.signature = ''

            // remount component to fix de-sync on canvas resize
            // see https://github.com/agilgur5/react-signature-canvas/issues/43
            remountSignPad()
          }}
          buttonIcon={IconSync}
          label={intl.formatMessage({
            id: 'activity.sign.clear',
            defaultMessage: 'Clear signature',
          })}
        >
          <FormattedMessage
            id="activity.sign.form.signature"
            defaultMessage="Signature"
          />
        </SectionTitleWithAction>
        <SectionList>
          <SectionItem>
            <SignatureCanvas
              key={signPadKey}
              /*
                disable clearOnResize since resize is triggered
                on mobile when keyboard shows/hides
                see https://github.com/agilgur5/react-signature-canvas/issues/65
              */
              clearOnResize={false}
              ref={(ref) => {
                if (ref) signPad = ref
              }}
              onBegin={() => {
                const activeElement = document?.activeElement as
                  | HTMLElement
                  | undefined
                activeElement?.blur && activeElement.blur()
              }}
              onEnd={() =>
                (values.signature = signPad.getTrimmedCanvas().toDataURL())
              }
              canvasProps={{
                style: { width: '100%', height: 300 },
              }}
            />
          </SectionItem>
          <ErrorMessage name="signature" component={SignaturePadFieldError} />
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { SignaturePadField }
