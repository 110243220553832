import { ReactElement, ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasModulePermission } from '../../../auth/hooks'
import { Module, Permission } from '../../../auth/types'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { ActivityAddDialog } from '../add/ActivityAddDialog'
import { Group } from '../types'

export function RelatedActivitiesAddButton({
  label,
  projectId,
  addressId,
  parentId,
  filter,
}: {
  label?: ReactNode
  projectId?: string
  addressId?: string | null
  parentId?: string
  filter?: (activityGroup: Group) => boolean
}): ReactElement | null {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const hasCreatePermission = useHasModulePermission(
    Module.activities,
    Permission.create
  )

  return hasCreatePermission ? (
    <>
      <Button
        onClick={() => setShowDialog(true)}
        variant={Variant.primaryOutlined}
      >
        {label || (
          <FormattedMessage
            id="activity.related.add.button"
            defaultMessage="Add Activity"
          />
        )}
      </Button>
      <ActivityAddDialog
        isOpen={showDialog}
        query={{
          project: projectId ?? undefined,
          address: addressId ?? undefined,
          parent: parentId ?? undefined,
        }}
        filter={filter}
        onDismis={() => setShowDialog(false)}
      />
    </>
  ) : null
}
