import { intervalToDuration } from 'date-fns'
import { PopulatedActivity } from '../../domain/activity/overview/populateActivityData'
import { formatDateAndTimeForAPI } from './apiUtils'

export function extractDateFromTimestamp(date: string): string {
  return date.substr(0, 10)
}

export function extractTimeFromTimestamp(date: string): string {
  return date.substr(11, 5)
}

export function convertDateAndTimeToDate(date: string, time: string): Date {
  return new Date(formatDateAndTimeForAPI(date, time))
}

export function getDurationAsString(
  startDate: string,
  endDate: string
): string {
  const duration = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  })
  const hours =
    (duration?.days ? duration.days : 0) * 24 +
    (duration?.hours ? duration.hours : 0)
  const minutes = duration?.minutes ? duration.minutes : 0

  return `${hours}:${minutes < 10 ? '0' + minutes : minutes + ''}`
}

export function getDurationSumAsString(
  activities: PopulatedActivity[]
): string {
  const totalDuration = activities.reduce((total, activity) => {
    const duration = intervalToDuration({
      start: new Date(activity.startDate),
      end: new Date(activity.endDate),
    })
    const minutes =
      (duration?.days ? duration.days : 0) * 24 * 60 +
      (duration?.hours ? duration.hours : 0) * 60 +
      (duration?.minutes ? duration.minutes : 0)
    return total + minutes
  }, 0)

  const hours = Math.floor(totalDuration / 60)
  const minutes = totalDuration % 60

  return `${hours}:${minutes < 10 ? '0' + minutes : minutes + ''}`
}
