import React from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { Signature } from '../types'
import { Size } from '../../../shared/section/SectionItem'
import { SectionListLink } from '../../../shared/section/SectionListLink'
import { IconAddCircleOutline } from '../../../shared/svgs'

const ActivitySignaturSection: React.FC<{
  signature: Signature | null
  activityId: string
  activityGroupId: string
}> = ({ signature, activityId, activityGroupId }) => {
  return (
    <>
      {signature && signature.name && signature.date && (
        <Section>
          <SectionTitle>
            <FormattedMessage
              id="activity.detail.signature.title"
              defaultMessage="Customer's signature"
            />
          </SectionTitle>
          <SectionList>
            <SectionItem size={Size.xs}>
              <div className="flex-1">
                <h2>{signature.name}</h2>
                <h3 className="pt-1 text-sm">
                  <FormattedDate value={signature.date} /> {' - '}
                  <FormattedTime value={signature.date} />
                </h3>
              </div>
            </SectionItem>
          </SectionList>
        </Section>
      )}
      <SectionListLink
        to={`/activity-groups/${activityGroupId}/activities/${activityId}/sign`}
      >
        <IconAddCircleOutline height="24" />
        <span className="ml-1">
          <FormattedMessage
            id="activity.detail.signature.add"
            defaultMessage="Sign activity"
          />
        </span>
      </SectionListLink>
    </>
  )
}

export { ActivitySignaturSection }
