import React from 'react'
import { defineMessage } from 'react-intl'
import { AppLayout } from '../../layouts/app'
import { DashboardOverview } from './overview/DashboardOverview'
import { translationsDashboard } from './translations/translationsDashboard'

const DashboardOverviewPage: React.FC = () => (
  <AppLayout
    title={{
      messageDescriptor: defineMessage(translationsDashboard.overviewTitle),
    }}
  >
    <DashboardOverview />
  </AppLayout>
)

export { DashboardOverviewPage }
