import axios from 'axios'
import { APP_VERSION, BACKEND_API } from './env'

// In order to be able to read cross domain cookies, we need to set withCredentials = true
axios.defaults.withCredentials = true
axios.defaults.baseURL = BACKEND_API
axios.defaults.withXSRFToken = true

axios.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['APP_VERSION'] = APP_VERSION as string
  }

  return config
})
