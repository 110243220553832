import React from 'react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { formatDateForAPI } from '../../../shared/utils/apiUtils'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { NotificationFormFields } from './types'
import { GeneralEditSection } from './GeneralEditSection'
import { AssignEditSection } from './AssignEditSection'
import { useGetValidationSchema } from './notificationFormValidation'

const NotificationForm: React.FC<{
  initialValues: NotificationFormFields
  onSubmit: (values: NotificationFormFields) => void
}> = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate()

  const schema = useGetValidationSchema()

  const prepareAndSubmit = (values: NotificationFormFields) => {
    onSubmit({
      ...values,
      userId: values.userId || null,
      addressGroupId: values.addressGroupId || null,
      time: values.time ? formatDateForAPI(values.time) : null,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: NotificationFormFields) => prepareAndSubmit(values)}
      validationSchema={schema}
      validateOnChange={false}
    >
      {(props) => (
        <Form>
          <GeneralEditSection formik={props} />
          <AssignEditSection formik={props} />
          <SectionActions>
            <CancelButton onClick={() => navigate(-1)} />
            <SubmitButton />
          </SectionActions>
        </Form>
      )}
    </Formik>
  )
}

export { NotificationForm }
