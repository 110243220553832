import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityEdit } from './edit/ActivityEdit'
import { translationsActivity } from './translations/translationsActivity'

const ActivityEditPage: React.FC = () => {
  const { activityId, activityGroupId } = useParams<{
    activityId: string
    activityGroupId: string
  }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsActivity.editTitle }}>
      <ActivityEdit
        id={activityId as string}
        activityGroupId={activityGroupId as string}
      />
    </AppLayout>
  )
}

export { ActivityEditPage }
