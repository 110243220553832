import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Loading } from '../layouts/Loading'
import { syncAuth } from '../db'
import { useInterval } from '../packages/reactUseInterval'
import { getAuthMe } from '../db/tables/settings'
import { Me, RetryQueueItem } from './types'

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000

export type AuthContextValue = {
  authenticated: boolean
  data: null | Me
}

const initialContext = {
  authenticated: false,
  data: null,
}

export const AuthContext = React.createContext<AuthContextValue>(initialContext)

const AuthChecker: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextValue>()
  const location = useLocation()
  const requestQueue = useRef<RetryQueueItem[]>([])
  const isRefreshing = useRef<boolean>(false)

  const updateAuthContext = useCallback((me: Me | null): void => {
    if (!me) {
      setAuth({ authenticated: false, data: null })
    } else {
      setAuth({ authenticated: true, data: me })
    }
  }, [])

  const refresh = useCallback(async () => {
    try {
      const me = await syncAuth()
      updateAuthContext(me)
    } catch (e: any) {
      if (e?.response?.status === 401) {
        updateAuthContext(null)
      }
    }
  }, [updateAuthContext])

  useInterval(() => {
    void refresh()
  }, FIVE_MINUTES_IN_MILLISECONDS)

  useEffect(() => {
    // On first render, set the user from DB if present, else ignore
    // We have to ignore otherwise after the login, the user will be redirected back to the login page, as there is still nothing in the DB
    getAuthMe().then((me) => {
      if (me !== null) {
        updateAuthContext(me)
      }
    })

    void refresh()
  }, [refresh, updateAuthContext])

  const pathNameRef = useRef(location.pathname)
  useEffect(() => {
    pathNameRef.current = location.pathname
  }, [location.pathname])

  /*
   Our application uses a short session duration (typically 2 hours). If a page remains open for an extended period,
   the session cookie may expire, causing synchronization attempts to fail. In such cases, we attempt to refresh the
   session using the remember_me token, if available, and then retry the failed requests.
   However, it is crucial to exclude the /me endpoint from this logic.
   Otherwise, we risk creating a loop: if the /me endpoint returns a 401 Unauthorized
   response because the remember_me token is invalid and the user has logged out,
   the application could continuously attempt to refresh the session unnecessarily.
    */
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        if (
          error.response &&
          error.response.status === 401 &&
          pathNameRef.current !== '/login' &&
          error.request.responseURL.indexOf('auth/me') === -1
        ) {
          if (!isRefreshing.current) {
            isRefreshing.current = true
            try {
              await refresh()

              // Retry all the requests in the queue
              requestQueue.current.forEach(({ config, resolve, reject }) => {
                axios
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err))
              })

              requestQueue.current.length = 0

              return axios(originalRequest)
            } catch (refreshError) {
              throw refreshError
            } finally {
              isRefreshing.current = false
            }
          }
          // Put all requests in the queue, while the session is refreshed.
          return new Promise<void>((resolve, reject) => {
            requestQueue.current.push({
              config: originalRequest,
              resolve,
              reject,
            })
          })
        }

        return Promise.reject(error)
      }
    )
    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [refresh])

  if (auth === undefined) {
    return <Loading />
  } else {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  }
}

export { AuthChecker }
