import classnames from 'classnames'
type FilterButtonProps = {
  children: React.ReactNode
  onClick: () => void
  selected: boolean
}

export const ChipButton: React.FC<FilterButtonProps> = ({
  children,
  onClick,
  selected,
}) => {
  const css = selected
    ? 'bg-primary-400 focus:outline focus:outline-primary-700'
    : 'bg-gray-500 hover:bg-primary-700 focus:outline focus:outline-gray-700 hover:focus:outline-primary-500'

  return (
    <button
      className={classnames(
        'rounded-full py-1 px-4 text-xs font-medium uppercase outline-0',
        css
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
