import React, { ComponentType } from 'react'
import classnames from 'classnames'
import { IconProps } from '../svgs/types'

const SectionItemContentWithIcon: React.FC<{
  icon: ComponentType<IconProps>
  className?: string
}> = ({ children, icon: Icon, className = '' }) => {
  return (
    <div className={classnames('flex', className)}>
      <Icon height="24" className="mr-3 inline-flex shrink-0" />
      <span className="flex items-center break-all">{children}</span>
    </div>
  )
}

export { SectionItemContentWithIcon }
