import React from 'react'
import { Project } from '../../../project/types'
import { DialogSelectionListItemWithText } from '../../../../shared/dialog/selection/DialogSelectionListItemWithText'
import { ProjectTypeBadge } from '../../../project/shared/ProjectTypeBadge'

const ProjectDialogItem: React.FC<{
  project: Project
  onSelect: (projectId: string, project: Project) => void
}> = ({ project, onSelect }) => {
  return (
    <DialogSelectionListItemWithText
      text={project.name}
      adornment={<ProjectTypeBadge projectType={project.type} />}
      itemId={project.id}
      onSelect={(projectId: string) => onSelect(projectId, project)}
    />
  )
}

export { ProjectDialogItem }
