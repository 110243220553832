import { FileReference } from '../file/types'
import { NotificationType } from '../notification/types'
import { SortDirection } from '../../shared/types'

export type { FileReference }

// Activity types
export type Activity = {
  id: string
  name: string
  description: string
  search: string | null
  sort: string | null
  activityCategoryId: string | null
  activityTypeId: string | null
  activityProgressId: string | null
  startDate: string
  endDate: string
  dueDate: string | null
  billable: boolean
  projectId: string
  addressId: string
  assignedAddressIds: Array<string>
  assignedAddressGroupIds: Array<string>
  articles: Array<ActivityArticle>
  files: Array<FileReference>
  signature: Signature | null
  startDateChanged: boolean
  endDateChanged: boolean
  durationChanged: boolean
}

export type ActivityArticle = {
  id: string
  articleId: string
  count: string
  description: string
}

export type Signature = {
  image: string
  name: string
  date: string
  location: {
    longitude: number
    latitude: number
  } | null
}

// Activity config
export type Category = {
  id: string
  name: string
  sort: string
  defaultTypeId: string | null
  types: Array<Type>
  defaultProgressId: string | null
  progresses: Array<Progress>
}

export type Type = {
  id: string
  name: string
  sort: string
}

export type Progress = {
  id: string
  name: string
  sort: string
  activityGroupIds: string[]
}

export enum GroupIcon {
  task = 'task',
  checklist = 'checklist',
  calendar = 'calendar',
  mail = 'mail',
  phone = 'phone',
}

export enum GroupListView {
  classic = 'classic',
  clusterByStartDate = 'clusterByStartDate',
}

type ListConfig =
  | {
      listView: GroupListView.clusterByStartDate
      listConfig: ClusterByStartDateListConfig
    }
  | { listView: GroupListView.classic; listConfig: SharedListConfig }

type DetailsConfig = {
  subActivityCreateHidden: boolean
  selectableSubActivityGroupIds: string[]
}

export type Group = {
  id: string
  name: string
  sort?: string
  icon: GroupIcon
  listView: GroupListView
  listSortDirection: SortDirection
  categoryIds: Array<string>
  defaultNotificationType: NotificationType
  fields: GroupFieldConfig
  detailsConfig: DetailsConfig
} & ListConfig

export type GroupFieldConfig = {
  name: FieldConfigState & FieldConfigDefault<string>
  description: FieldConfigState &
    FieldConfigRequired &
    FieldConfigDefault<string>
  activityCategoryId: FieldConfigState &
    FieldConfigRequired &
    FieldConfigDefault<string>
  activityTypeId: FieldConfigState & FieldConfigRequired
  activityProgressId: FieldConfigState & FieldConfigRequired
  startDate: TimeConfig
  endDate: TimeConfig
  dueDate: TimeConfig
  duration: FieldConfigState
  billable: FieldConfigState & FieldConfigDefault<boolean>
  employee: FieldConfigState & FieldConfigRequired & FieldConfigDefault<string>
  addressId: FieldConfigState & FieldConfigRequired & FieldConfigDefault<string>
  projectId: FieldConfigState & FieldConfigRequired & FieldConfigDefault<string>
  articles: FieldConfigState &
    FieldConfigRequired &
    FieldConfigDefault<ActivityArticle[]> & {
      selectableIds: string[]
    }
  notifications: FieldConfigState
  files: FieldConfigState &
    FieldConfigRequired &
    FieldConfigDefault<FileDefaults>
  signature: FieldConfigState
}

export enum ActivityFieldState {
  Hidden = 'hidden',
  ReadOnly = 'readOnly',
  Editable = 'editable',
}

export type FieldConfigState = {
  state: ActivityFieldState
}

export type FieldConfigRequired = {
  required: boolean
}

export type FieldConfigDefault<T> = {
  default: T | null
}

// Activity hierarchies
export type ActivityHierarchy = {
  parentId: string
  childId: string
}

// Article types
export type Article = {
  id: string
  codes: Array<string>
  name: string
  search?: string
  sort?: string
}

// File defaules
export type FileDefaults = {
  description?: string
}

export type ActivityGroupSettings = {
  id: string
  assignedUserId: string
}

export type TimeConfig = {
  dateState: ActivityFieldState
  timeState: ActivityFieldState
  required: boolean
  default: number | null
}

export type ClusterByStartDateListConfig = {
  dayDurationHidden: boolean
  itemDurationHidden: boolean
} & SharedListConfig

export type SharedListConfig = {
  activityCreateHidden: boolean
}
