import React, { useCallback, useState } from 'react'
import { useField } from 'formik'
import { FormattedMessage } from 'react-intl'

import { useGetCostCenterById } from '../../costCenter/db/costCenters'
import { DismissType } from '../../../shared/dialog/types'
import { SelectWithDialogField } from './SelectWithDialogField'
import { SelectCostCenterDialog } from './SelectCostCenterDialog'
import { SelectCostCenterGroupDialog } from './SelectCostCenterGroupDialog'

type Props = {
  costCenterIdFieldName: string
  disabled?: boolean
}

enum SelectionState {
  closed,
  step_cost_center_group,
  step_cost_center,
}

const SelectCostCenter: React.FC<Props> = ({
  costCenterIdFieldName,
  disabled,
}) => {
  const [, meta, helpers] = useField({ name: costCenterIdFieldName })
  const [selectionState, setSelectionState] = useState<SelectionState>(
    SelectionState.closed
  )
  const [costCenterGroupId, setCostCenterGroupId] = useState<string | null>(
    null
  )
  const { value } = meta
  const { data: selectedCostCenter } = useGetCostCenterById(value || '')

  const { setValue } = helpers

  const onConfirmCostCenterGroup = useCallback((value): void => {
    setCostCenterGroupId(value)
    setSelectionState(SelectionState.step_cost_center)
  }, [])

  const onDismissCostCenterGroup = useCallback((): void => {
    setSelectionState(SelectionState.closed)
  }, [])

  const onConfirmCostCenter = useCallback(
    (value): void => {
      setValue(value)
      setSelectionState(SelectionState.closed)
    },
    [setValue]
  )

  const onDismissCostCenter = useCallback((): void => {
    setSelectionState(SelectionState.step_cost_center_group)
  }, [])

  return (
    <>
      <SelectWithDialogField
        touched={meta.touched}
        hasError={!!meta.error}
        fieldName={costCenterIdFieldName}
        value={selectedCostCenter?.name || ''}
        disabled={disabled}
        onClick={(): void => {
          setSelectionState(SelectionState.step_cost_center_group)
        }}
        label={
          <FormattedMessage
            id="timeTracking.edit.costCenter.label"
            defaultMessage="Cost Center"
          />
        }
      />
      <SelectCostCenterGroupDialog
        isOpen={selectionState === SelectionState.step_cost_center_group}
        onDismiss={onDismissCostCenterGroup}
        onConfirm={onConfirmCostCenterGroup}
        onConfirmFavorite={onConfirmCostCenter}
      />
      <SelectCostCenterDialog
        isOpen={selectionState === SelectionState.step_cost_center}
        costCenterGroupId={costCenterGroupId}
        dismissType={DismissType.back}
        onDismiss={onDismissCostCenter}
        onConfirm={onConfirmCostCenter}
      />
    </>
  )
}

export { SelectCostCenter }
