import React from 'react'
import { defineMessage } from 'react-intl'
import { GuestLayout } from '../../../layouts/guest'
import { Login } from './Login'

const LoginPage: React.FC = () => {
  return (
    <GuestLayout
      title={{
        messageDescriptor: defineMessage({
          id: 'guest.login.page.title',
          defaultMessage: 'ZagZag - Login',
        }),
      }}
    >
      <Login />
    </GuestLayout>
  )
}

export { LoginPage }
