import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { useGetQueryParam } from '../../../shared/utils/routerUtils'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityAddSection } from './ActivityAddSection'

const ActivityAdd: React.FC<{ activityGroupId: string }> = ({
  activityGroupId,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const addressId = useGetQueryParam('address')
  const projectId = useGetQueryParam('project')
  const parentId = useGetQueryParam('parent')

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage {...translationsActivity.addTitle} />
        </HeaderTitle>
      </HeaderArea>
      <ActivityAddSection
        addressId={addressId}
        projectId={projectId}
        parentId={parentId}
        activityGroupId={activityGroupId}
      />
    </>
  )
}

export { ActivityAdd }
