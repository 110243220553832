import React from 'react'
import { AppLayout } from '../../layouts/app'
import { AddressAdd } from './add/AddressAdd'
import { translationsAddress } from './translations/translationsAddress'

const AddressAddPage: React.FC = () => {
  return (
    <AppLayout title={{ messageDescriptor: translationsAddress.addTitle }}>
      <AddressAdd />
    </AppLayout>
  )
}

export { AddressAddPage }
