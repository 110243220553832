import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { SectionItem } from '../../../../shared/section'
import { Size } from '../../../../shared/section/SectionItem'
import { ActivityArticleDetail } from '../../detail/populateArticleData'
import { SectionItemActionButton } from '../../../../shared/section/SectionItemActionButton'
import { SectionItemActionContainer } from '../../../../shared/section/SectionItemActionContainer'
import { IconDelete } from '../../../../shared/svgs'
import { translationsShared } from '../../../../shared/translations/translationsShared'
import { ActivityFieldState } from '../../types'

const ArticleFormItem: React.FC<{
  article: ActivityArticleDetail
  showActions: boolean
  onClick: (value: string) => void
  onEditClick: (value: string) => void
  onDeleteClick: (value: string) => void
  fieldState?: ActivityFieldState
}> = ({
  article,
  onClick,
  showActions,
  onDeleteClick,
  onEditClick,
  fieldState,
}) => {
  const intl = useIntl()

  return (
    <div
      className={classnames(
        showActions ? 'bg-white shadow-lg' : '',
        'divide-y divide-gray-500'
      )}
    >
      <>
        <button
          className={classnames(
            'block w-full text-left focus:outline-none',
            fieldState === ActivityFieldState.ReadOnly
              ? 'cursor-default'
              : 'cursor-pointer'
          )}
          type="button"
          onClick={() => onClick(article.id)}
        >
          <SectionItem size={Size.xs}>
            <div className="flex-1">
              <h2>{article.name}</h2>
              <h3 className="break-all pt-1 text-sm">{article.code}</h3>
              {article.description !== '' && (
                <p className="pt-1 text-sm">{article.description}</p>
              )}
            </div>
            <div className="break-all">{article.count}</div>
          </SectionItem>
        </button>
        {showActions && (
          <SectionItemActionContainer>
            <SectionItemActionButton
              onClick={() => onEditClick(article.id)}
              label={intl.formatMessage(translationsShared.edit)}
            />
            <SectionItemActionButton
              onClick={() => onDeleteClick(article.id)}
              icon={IconDelete}
              label={intl.formatMessage(translationsShared.delete)}
            />
          </SectionItemActionContainer>
        )}
      </>
    </div>
  )
}

export { ArticleFormItem }
