import React, { useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { registerLocale } from 'react-datepicker'
import { de } from 'date-fns/locale'
import { AuthContext } from '../auth/AuthContext'
import { i18n } from '../config/i18n'
import translations from '../translations'

registerLocale('de', de)

const Internationalization: React.FC = ({ children }) => {
  const auth = useContext(AuthContext)
  const currentLanguage = auth.data?.user.language || i18n.detectLanguage()

  const messages = Object.assign({}, translations[currentLanguage])

  return (
    <IntlProvider locale={currentLanguage} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { Internationalization }
