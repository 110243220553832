import React, { ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { TabPanels, Tabs } from '@reach/tabs'
import {
  overrideAddress,
  useGetAddressById,
  useGetRelatedAddresses,
} from '../db/addresses'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import {
  IconAddCircleOutline,
  IconFavorite,
  IconFavoriteBorder,
} from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { ContentArea } from '../../../shared/content'
import { Error } from '../../../shared/error'
import { useGetProjectByAddressId } from '../../project/db/projects'
import {
  Button,
  ButtonInternalLink,
  Variant,
} from '../../../shared/button/Button'
import { SectionActions } from '../../../shared/section/SectionActions'
import { SectionListLink } from '../../../shared/section/SectionListLink'
import { TabListNavigation } from '../../../shared/tab/TabListNavigation'
import { TabListElement } from '../../../shared/tab/TabListElement'
import { LazyTabPanel } from '../../../shared/tab/LazyTabPanel'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { useHasModulePermission } from '../../../auth/hooks'
import { Module, Permission } from '../../../auth/types'
import { FileSection } from '../../file/detail/FileSection'
import { NotificationOverviewByResource } from '../../notification/overview/NotificationOverviewByResource'
import { AppContext } from '../../../app/AppContext'
import { ActivityAddDialog } from '../../activity/add/ActivityAddDialog'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsAddress } from '../translations/translationsAddress'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { RelatedActivitiesList } from '../../activity/related/RelatedActivitiesList'
import { RelatedActivitiesAddButton } from '../../activity/related/RelatedActivitiesAddButton'
import { useGetActivitiesForAddress } from '../../activity/db/activities'
import { RelatedProjectsList } from './RelatedProjectsList'
import { RelatedAddressesList } from './RelatedAddressesList'
import { AddressDetailHero } from './AddressDetailHero'
import { AddressDetailFloatingMenu } from './AddressDetailFloatingMenu'

const tabs = ['details', 'activities', 'notifications']

function EditButton({ id }: { id: string }): ReactElement | null {
  const hasEditPermission = useHasModulePermission(
    Module.addresses,
    Permission.edit
  )

  return hasEditPermission ? (
    <ButtonInternalLink to={`/addresses/${id}/edit`} variant={Variant.primary}>
      <FormattedMessage {...translationsShared.edit} />
    </ButtonInternalLink>
  ) : null
}

function AddActivityButton({
  id,
  showDialog,
  setShowDialog,
}: {
  id: string
  showDialog: boolean
  setShowDialog: (show: boolean) => void
}): ReactElement | null {
  const hasCreatePermission = useHasModulePermission(
    Module.activities,
    Permission.create
  )

  return hasCreatePermission ? (
    <>
      <Button
        onClick={() => setShowDialog(true)}
        variant={Variant.primaryOutlined}
      >
        <FormattedMessage
          id="address.detail.addActivity"
          defaultMessage="Add Activity"
        />
      </Button>
      <ActivityAddDialog
        isOpen={showDialog}
        query={{ address: id }}
        onDismis={() => setShowDialog(false)}
      />
    </>
  ) : null
}

const AddressDetail: React.FC<{ id: string; tab: string | null }> = ({
  id,
  tab,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const onBack = (): void => {
    navigate(-1)
  }

  const { data: detailData, error: detailError } = useGetAddressById(id)
  const { data: relatedData, error: relatedError } =
    useGetRelatedAddresses(detailData)
  const { data: projectData, error: projectError } =
    useGetProjectByAddressId(id)
  const { data: relatedActivitiesData } = useGetActivitiesForAddress(id)

  const tabIndex = tab ? Math.max(tabs.indexOf(tab), 0) : 0

  const hasEditPermissions = useHasModulePermission(
    Module.addresses,
    Permission.edit
  )

  const hasActivitiesCreatePermissions = useHasModulePermission(
    Module.activities,
    Permission.create
  )

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={onBack}
          />
        }
      >
        {detailData ? (
          <>
            <HeaderTitle>{detailData.name}</HeaderTitle>
            <HeaderRightArea>
              <HeaderSmallAction
                icon={detailData.favorite ? IconFavorite : IconFavoriteBorder}
                label={
                  detailData.favorite
                    ? intl.formatMessage(translationsAddress.favoriteUnmark)
                    : intl.formatMessage(translationsAddress.favoriteMark)
                }
                disabled={disabled}
                onClick={async () => {
                  setDisabled(true)

                  try {
                    const updateAddress = await (detailData?.favorite
                      ? unmarkAsFavorite(detailData.id)
                      : markAsFavorite(detailData.id))

                    overrideAddress(updateAddress).finally(() => {
                      setDisabled(false)
                    })
                  } catch (error: any) {
                    addAlertErrorMessage(extractErrorMessage(error))
                    setDisabled(false)
                  }
                }}
              />
            </HeaderRightArea>
          </>
        ) : (
          <HeaderTitle>
            {intl.formatMessage(translationsAddress.detailTitle)}
          </HeaderTitle>
        )}
      </HeaderArea>
      <Tabs
        index={tabIndex}
        onChange={(index) =>
          navigate({ search: '?tab=' + tabs[index] }, { replace: true })
        }
      >
        <TabListNavigation>
          <TabListElement index={0}>
            <FormattedMessage
              id="address.detail.tab.details"
              defaultMessage="Details"
            />
          </TabListElement>
          <TabListElement index={1}>
            <FormattedMessage
              id="address.detail.tab.activities"
              defaultMessage="Activities"
            />
          </TabListElement>
          <TabListElement index={2}>
            <FormattedMessage
              id="address.detail.tab.notifications"
              defaultMessage="Notification"
            />
          </TabListElement>
        </TabListNavigation>

        <TabPanels>
          <LazyTabPanel index={0}>
            <ContentArea>
              <AsyncView
                data={detailData}
                error={detailError}
                renderSuccess={(detailData) => (
                  <AddressDetailHero data={detailData} />
                )}
                renderError={(detailError) => (
                  <Error text={detailError && detailError.message} />
                )}
              />
              {relatedData && relatedData.length > 0 && (
                <AsyncView
                  data={relatedData}
                  error={relatedError}
                  renderSuccess={(relatedData) => (
                    <RelatedAddressesList
                      title={
                        detailData?.parentId
                          ? intl.formatMessage({
                              id: 'address.detail.parent.addresses',
                              defaultMessage: 'Parent addresses',
                            })
                          : intl.formatMessage({
                              id: 'address.detail.related.addresses',
                              defaultMessage: 'Related addresses',
                            })
                      }
                      data={relatedData}
                    />
                  )}
                  renderError={(relatedError) => (
                    <Error text={relatedError && relatedError.message} />
                  )}
                />
              )}
              {projectData && projectData.length > 0 && (
                <AsyncView
                  data={projectData}
                  error={projectError}
                  renderSuccess={(projectData) => (
                    <RelatedProjectsList
                      data={projectData}
                      title={intl.formatMessage({
                        id: 'address.detail.related.projects',
                        defaultMessage: 'Linked projects',
                      })}
                    />
                  )}
                  renderError={(projectError) => (
                    <Error text={projectError && projectError.message} />
                  )}
                />
              )}
              {detailData && detailData.files?.length > 0 && (
                <FileSection files={detailData.files} />
              )}
              <SectionActions>
                {hasActivitiesCreatePermissions && (
                  <AddActivityButton
                    id={id}
                    setShowDialog={setShowDialog}
                    showDialog={showDialog}
                  />
                )}
                {hasEditPermissions && <EditButton id={id} />}
              </SectionActions>
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={1}>
            <ContentArea>
              {relatedActivitiesData && relatedActivitiesData.length > 0 && (
                <AsyncView
                  data={relatedActivitiesData}
                  renderSuccess={(relatedActivitiesData) => (
                    <RelatedActivitiesList activities={relatedActivitiesData} />
                  )}
                />
              )}
              {hasActivitiesCreatePermissions && (
                <SectionActions>
                  <RelatedActivitiesAddButton addressId={id} />
                </SectionActions>
              )}
            </ContentArea>
          </LazyTabPanel>
          <LazyTabPanel index={2}>
            <ContentArea>
              <NotificationOverviewByResource
                resourceType={ResourceType.address}
                resourceId={id}
              />
              <SectionListLink to={`/addresses/${id}/notifications/add`}>
                <IconAddCircleOutline height="24" />
                <span className="ml-1">
                  <FormattedMessage {...translationsNotification.resourceAdd} />
                </span>
              </SectionListLink>
            </ContentArea>
          </LazyTabPanel>
        </TabPanels>
      </Tabs>
      <AddressDetailFloatingMenu
        addressId={id}
        canEdit={hasEditPermissions}
        canAddActivity={hasActivitiesCreatePermissions}
        setShowActivityAddDialog={setShowDialog}
      />
    </>
  )
}

export { AddressDetail }
