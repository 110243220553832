import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { NotificationEdit } from '../notification/edit/NotificationEdit'
import { translationsNotification } from '../notification/translations/translationsNotification'

const AddressNotificationEditPage: React.FC = () => {
  const { notificationId } = useParams<{ id: string; notificationId: string }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsNotification.addTitle }}>
      <NotificationEdit id={notificationId as string} />
    </AppLayout>
  )
}

export { AddressNotificationEditPage }
