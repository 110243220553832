import { useMemo } from 'react'
import { TABLE_COST_CENTER_GROUPS, TABLE_COST_CENTERS } from '../../../db/types'
import { CostCenter, CostCenterGroup } from '../types'
import { useGetById, useGetList, useGetPaginatedList } from '../../../db/hooks'
import { db } from '../../../db'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const generateIndexedSearchForCostCenterGroup = (
  costCenterGroup: CostCenterGroup
): Array<string> => {
  return costCenterGroup.name.toLocaleLowerCase().split(' ')
}

const generateIndexedSearchForCostCenter = (
  costCenter: CostCenter
): Array<string> => {
  return costCenter.name.toLocaleLowerCase().split(' ')
}

const generateIndexedSortForCostCenterGroup = (
  costCenterGroup: CostCenterGroup
): string => {
  return costCenterGroup.sort ?? ''
}

const generateIndexedSortForCostCenter = (costCenter: CostCenter): string => {
  return costCenter.sort ?? ''
}

const useGetAllCostCenters = (
  options: {
    filter?: (item: CostCenter) => boolean
  } = {}
) => useGetList<CostCenter>(TABLE_COST_CENTERS, options)

const useGetCostCenterGroupById = (id: string) =>
  useGetById<CostCenterGroup>(TABLE_COST_CENTER_GROUPS, id)

const useGetCostCenterById = (id: string) =>
  useGetById<CostCenter>(TABLE_COST_CENTERS, id)

const useGetCostCenterGroupsBySearch = (
  search: string,
  page: number,
  size: number
) =>
  useGetPaginatedList<CostCenter>(
    TABLE_COST_CENTER_GROUPS,
    search,
    page,
    size,
    {
      sortBy: 'sort',
    }
  )

const useGetCostCentersBySearch = (
  search: string,
  page: number,
  size: number,
  filter?: (item: CostCenter) => boolean
) =>
  useGetPaginatedList<CostCenter>(TABLE_COST_CENTERS, search, page, size, {
    sortBy: 'sort',
    filter,
  })

const useGetFavoriteCostCenters = (filter?: (item: CostCenter) => boolean) => {
  const options = useMemo(
    () => ({
      filter: (costCenter: CostCenter) => {
        if (filter) {
          return filter(costCenter) && costCenter.favorite
        } else {
          return costCenter.favorite
        }
      },
    }),
    [filter]
  )

  return useGetList<CostCenter>(TABLE_COST_CENTERS, options)
}

const overrideCostCenterGroups = async (
  costCenterGroups: Array<CostCenterGroup>
): Promise<void> => {
  const searchableCostCenterGroups = costCenterGroups.map((costCenterGroup) => {
    return {
      ...costCenterGroup,
      searchIndexed: generateIndexedSearchForCostCenterGroup(costCenterGroup),
      sort: generateIndexedSortForCostCenterGroup(costCenterGroup),
    }
  })

  await updateDatabase(db, TABLE_COST_CENTER_GROUPS, searchableCostCenterGroups)
}

const overrideCostCenters = async (
  costCenters: Array<CostCenter>
): Promise<void> => {
  const searchableCostCenters = costCenters.map((costCenter) => {
    return {
      ...costCenter,
      searchIndexed: generateIndexedSearchForCostCenter(costCenter),
      sort: generateIndexedSortForCostCenter(costCenter),
    }
  })

  await updateDatabase(db, TABLE_COST_CENTERS, searchableCostCenters)
}

const overrideCostCenter = async (costCenter: CostCenter): Promise<void> => {
  const searchableCostCenter = {
    ...costCenter,
    searchIndexed: generateIndexedSearchForCostCenter(costCenter),
  }

  await db.table(TABLE_COST_CENTERS).put(searchableCostCenter)
}

const clearCostCenterGroups = async (): Promise<void> => {
  await db.table(TABLE_COST_CENTER_GROUPS).clear()
}

const clearCostCenters = async (): Promise<void> => {
  await db.table(TABLE_COST_CENTERS).clear()
}

export const getCostCenterById = (id: string): Promise<CostCenter> => {
  return db.table(TABLE_COST_CENTERS).where({ id }).first()
}

export {
  useGetAllCostCenters,
  useGetCostCenterGroupById,
  useGetCostCenterById,
  useGetCostCenterGroupsBySearch,
  useGetCostCentersBySearch,
  useGetFavoriteCostCenters,
  overrideCostCenterGroups,
  overrideCostCenters,
  overrideCostCenter,
  clearCostCenterGroups,
  clearCostCenters,
}
