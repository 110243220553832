import React from 'react'
import classnames from 'classnames'

import styles from './DialogContent.module.css'

const DialogContent: React.FC = ({ children }) => {
  return (
    <div
      className={classnames(
        styles['center-dialog'],
        'max-w-lg rounded-lg bg-white p-4'
      )}
    >
      {children}
    </div>
  )
}

export { DialogContent }
