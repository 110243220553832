import { useEffect, useState } from 'react'
import { parseISO } from 'date-fns'
import { Activity, ActivityHierarchy, Category, Group } from '../types'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { db } from '../../../db'
import {
  TABLE_ACTIVITIES,
  TABLE_ACTIVITY_CATEGORIES,
  TABLE_ACTIVITY_GROUPS,
  TABLE_ACTIVITY_HIERARCHIES,
} from '../../../db/types'
import {
  attachTableListener,
  useGetById,
  useGetByIds,
  useGetList,
  useGetPaginatedList,
} from '../../../db/hooks'
import { SortDirection } from '../../../shared/types'

const generateIndexedSearch = (activity: Activity): Array<string> => {
  if (activity.search === undefined || activity.search === null) {
    return []
  }

  return activity.search.toLocaleLowerCase().split(' ')
}

function generateIndexedSort(activity: Activity) {
  return activity.sort ?? ''
}

function generateIndexedStartDate(activity: Activity) {
  return parseISO(activity.startDate)
}

const overrideActivities = async (
  activities: Array<Activity>
): Promise<void> => {
  const enrichedActivities = activities.map((activity) => {
    return {
      ...activity,
      searchIndexed: generateIndexedSearch(activity),
      sortIndexed: generateIndexedSort(activity),
      startDateIndexed: generateIndexedStartDate(activity),
    }
  })

  await updateDatabase(db, TABLE_ACTIVITIES, enrichedActivities)
}

const overrideActivity = async (activity: Activity): Promise<void> => {
  await db.table(TABLE_ACTIVITIES).put({
    ...activity,
    searchIndexed: generateIndexedSearch(activity),
    sortIndexed: generateIndexedSort(activity),
    startDateIndexed: generateIndexedStartDate(activity),
  })
}

const overrideActivityCategories = async (
  activityCategories: Array<Category>
): Promise<void> => {
  await updateDatabase(db, TABLE_ACTIVITY_CATEGORIES, activityCategories)
}

const overrideActivityGroups = async (
  activityGroups: Array<Group>
): Promise<void> => {
  const enrichedActivityGroups = activityGroups.map((activityGroup) => {
    return {
      ...activityGroup,
      sortIndexed: activityGroup.sort ?? '',
    }
  })

  await updateDatabase(db, TABLE_ACTIVITY_GROUPS, enrichedActivityGroups)
}

const overrideActivityHierarchies = async (
  activityHierarchies: Array<ActivityHierarchy>
): Promise<void> => {
  await updateDatabase(db, TABLE_ACTIVITY_HIERARCHIES, activityHierarchies)
}

const overrideActivityHierarchy = async (
  activityHierarchy: ActivityHierarchy
): Promise<void> => {
  await db.table(TABLE_ACTIVITY_HIERARCHIES).put(activityHierarchy)
}

const deleteActivity = async (id: string): Promise<void> => {
  await db.table(TABLE_ACTIVITIES).delete(id)
}

const useGetActivitiesForProject = (projectId: string) => {
  const [data, setData] = useState<Array<Activity> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_ACTIVITIES)
        .filter(
          (activity: Activity) =>
            activity.projectId !== null && activity.projectId === projectId
        )
        .sortBy('sortIndexed')
        .then((data) => {
          setData(data)
          setError(null)
        })
        .catch((err) => {
          setData(null)
          setError(err)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ACTIVITIES)
  }, [projectId])

  return { data, error }
}

const useGetActivitiesForAddress = (addressId: string) => {
  const [data, setData] = useState<Array<Activity> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_ACTIVITIES)
        .filter(
          (activity: Activity) =>
            activity.addressId !== null && activity.addressId === addressId
        )
        .sortBy('sortIndexed')
        .then((data) => {
          setData(data)
          setError(null)
        })
        .catch((err) => {
          setData(null)
          setError(err)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ACTIVITIES)
  }, [addressId])

  return { data, error }
}

const useGetActivitiesByIds = (ids: string[]) => {
  return useGetByIds<Activity>(TABLE_ACTIVITIES, ids)
}

const useGetActivities = (
  search: string,
  page: number,
  size: number,
  options: {
    sortBy?: string
    sortDirection?: 'asc' | 'desc'
    filter?: (item: Activity) => boolean
  }
) =>
  useGetPaginatedList<Activity>(TABLE_ACTIVITIES, search, page, size, {
    sortBy: 'sortIndexed',
    sortDirection: 'asc',
    ...options,
  })

const useGetActivityById = (id: string) =>
  useGetById<Activity>(TABLE_ACTIVITIES, id)

const useGetAllActivities = (
  filter?: (item: Activity) => boolean,
  sortDirection?: SortDirection
) =>
  useGetList<Activity>(TABLE_ACTIVITIES, {
    filter: filter,
    sortDirection: sortDirection,
  })

const useGetActivityCategories = (filter?: (item: Category) => boolean) =>
  useGetList<Category>(TABLE_ACTIVITY_CATEGORIES, { filter: filter })
const useGetActivityGroups = (filter?: (group: Group) => boolean) =>
  useGetList<Group>(TABLE_ACTIVITY_GROUPS, {
    filter: filter,
    sortBy: 'sortIndexed',
  })
const useGetActivityGroup = (id: string) =>
  useGetById<Group>(TABLE_ACTIVITY_GROUPS, id)

export {
  overrideActivities,
  overrideActivity,
  overrideActivityCategories,
  overrideActivityGroups,
  overrideActivityHierarchies,
  overrideActivityHierarchy,
  deleteActivity,
  useGetActivities,
  useGetActivityById,
  useGetActivitiesByIds,
  useGetAllActivities,
  useGetActivitiesForProject,
  useGetActivitiesForAddress,
  useGetActivityCategories,
  useGetActivityGroups,
  useGetActivityGroup,
}
