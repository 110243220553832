import Axios, { AxiosResponse } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { Address, AddressGroup, AddressType } from './types'

type AddressResponse = Array<Address>

const getAddresses = (): Promise<AddressResponse> =>
  axiosGet('/v1/user/addresses')

const getAddressConfig = (): Promise<{
  addressGroups: Array<AddressGroup>
  addressTypes: Array<AddressType>
}> => axiosGet('/v1/user/addresses/config')

async function markAsFavorite(id: string): Promise<Address> {
  const res = await Axios.put<Record<string, unknown>, AxiosResponse<Address>>(
    `/v1/user/addresses/${id}/favorite`,
    {}
  )

  return res.data
}

async function unmarkAsFavorite(id: string): Promise<Address> {
  const res = await Axios.delete<
    Record<string, unknown>,
    AxiosResponse<Address>
  >(`/v1/user/addresses/${id}/favorite`, {})

  return res.data
}

async function createAddress(address: Address): Promise<Address> {
  const res = await Axios.post<Record<string, unknown>, AxiosResponse<Address>>(
    `/v1/user/addresses/${address.id}`,
    address
  )

  return res.data
}

async function updateAddress(address: Address): Promise<Address> {
  const res = await Axios.put<Record<string, unknown>, AxiosResponse<Address>>(
    `/v1/user/addresses/${address.id}`,
    address
  )

  return res.data
}

export {
  getAddresses,
  markAsFavorite,
  unmarkAsFavorite,
  createAddress,
  updateAddress,
  getAddressConfig,
}
export type { AddressResponse }
