import React from 'react'
import { useIntl } from 'react-intl'

import { HeaderRightArea } from '../header'
import { IconSearch } from '../svgs'
import { translationsProject } from '../../domain/project/translations/translationsProject'
import { DialogAction } from './DialogAction'

const DialogStartSearchAction: React.FC<{
  startSearch: () => void
  label: string
}> = ({ label, startSearch }) => {
  const intl = useIntl()

  return (
    <HeaderRightArea className="self-end md:self-start">
      <DialogAction
        label={intl.formatMessage(translationsProject.overviewSearchStart)}
        onClick={startSearch}
        className="-mr-4 -mb-4 rounded-full bg-gray-700 text-white hover:bg-gray-600 md:mr-0 md:mb-0"
      >
        <IconSearch
          className="h-6 w-6 fill-current md:h-8 md:w-8"
          title={label}
        />
      </DialogAction>
    </HeaderRightArea>
  )
}

export { DialogStartSearchAction }
