import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { NotificationDetail } from '../notification/detail/NotificationDetail'
import { translationsAddress } from './translations/translationsAddress'

const AddressNotificationDetailPage: React.FC = () => {
  const { notificationId } = useParams<{ notificationId: string }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsAddress.detailTitle }}>
      <NotificationDetail id={notificationId as string} />
    </AppLayout>
  )
}

export { AddressNotificationDetailPage }
