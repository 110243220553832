import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Activity, Group } from '../types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { ActivityOverviewItemLink } from '../overview/ActivityOverviewItem'
import { Size } from '../../../shared/section/SectionItem'
import { useGetActivityGroups } from '../db/activities'
import { findActivityGroup } from '../form/activityGroupUtils'
import { translationsActivity } from '../translations/translationsActivity'

const RelatedActivitiesList: React.FC<{
  title?: ReactNode
  activities: Array<Activity>
}> = ({ title, activities }) => {
  const { data: activityGroups } = useGetActivityGroups()

  return (
    <Section>
      <SectionTitle>
        {title || <FormattedMessage {...translationsActivity.overviewTitle} />}
      </SectionTitle>
      <SectionList>
        {activities.map((activity) => (
          <RelatedActivity
            key={activity.id}
            activity={activity}
            activityGroups={activityGroups}
          />
        ))}
      </SectionList>
    </Section>
  )
}

const RelatedActivity: React.FC<{
  activity: Activity
  activityGroups: Group[] | null
}> = ({ activity, activityGroups }) => {
  const activityGroup = findActivityGroup(activity, activityGroups)

  return activityGroup != null ? (
    <ActivityOverviewItemLink
      activity={activity}
      key={activity.id}
      size={Size.sm}
      activityGroupId={activityGroup.id}
    />
  ) : null
}

export { RelatedActivitiesList }
