import React from 'react'

const DialogHeaderArea: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col rounded-b-lg bg-gray-700 p-4 md:mb-12 md:flex-row md:items-center md:bg-transparent md:p-0">
      {children}
    </div>
  )
}

export { DialogHeaderArea }
