import React from 'react'
import { useParams } from 'react-router-dom'
import { AsyncView } from '@aboutbits/react-toolbox'
import { AppLayout } from '../../layouts/app'
import { ResourceType } from '../../shared/utils/resourceTypes'
import { NotificationAdd } from '../notification/add/NotificationAdd'
import { translationsNotification } from '../notification/translations/translationsNotification'
import { useGetActivityGroup } from './db/activities'

const ActivityNotificationAddPage: React.FC = () => {
  const { activityId, activityGroupId } = useParams<{
    activityId: string
    activityGroupId: string
  }>()

  const { data } = useGetActivityGroup(activityGroupId as string)

  return (
    <AppLayout title={{ messageDescriptor: translationsNotification.addTitle }}>
      <AsyncView
        data={data}
        renderSuccess={(activityGroup) =>
          activityGroup && (
            <NotificationAdd
              resourceType={ResourceType.activity}
              resourceId={activityId as string}
              activtyGroup={activityGroup}
            />
          )
        }
      />
    </AppLayout>
  )
}

export { ActivityNotificationAddPage }
