import React from 'react'
import { ErrorMessage, FieldAttributes, useField } from 'formik'
import { FieldErrorMessage } from '../FieldErrorMessage'
import { RadioGroupContext } from './RadioGroupContext'

const RadioGroup: React.FC<
  FieldAttributes<any> &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > & { name: string; label: string }
> = ({ name, label, children, ...props }) => {
  const [field] = useField({ name, ...props })

  return (
    <RadioGroupContext.Provider
      value={{
        value: field.value,
        name,
      }}
    >
      <div
        role="radiogroup"
        aria-label={label}
        className="flex flex-wrap gap-x-7 gap-y-2"
      >
        {children}
      </div>
      <ErrorMessage name={name} component={FieldErrorMessage} />
    </RadioGroupContext.Provider>
  )
}

export { RadioGroup }
