import React from 'react'
import { CostCenterGroup } from '../types'
import { DialogSelectionListItemWithText } from '../../../shared/dialog/selection/DialogSelectionListItemWithText'

const CostCenterGroupsSelectionItem: React.FC<{
  costCenterGroup: CostCenterGroup
  onSelect: (costCenterGroupId: string) => void
}> = ({ costCenterGroup, onSelect }) => {
  return (
    <DialogSelectionListItemWithText
      text={costCenterGroup.name}
      itemId={costCenterGroup.id}
      onSelect={onSelect}
    />
  )
}

export { CostCenterGroupsSelectionItem }
