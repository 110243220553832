import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { LocationContext } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { overrideActivity, useGetActivityById } from '../db/activities'
import { updateActivity } from '../api'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { IconClose } from '../../../shared/svgs'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { AppContext } from '../../../app/AppContext'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { SignaturePadField } from './SignaturePadField'
import { SignatureNameField } from './SignatureNameField'

type ActivitySignatureValues = {
  name: string
  signature: string
}

const ActivitySignature: React.FC<{ activityId: string }> = ({
  activityId,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { location } = useContext(LocationContext)
  const { addAlertErrorMessage } = useContext(AppContext)

  const { data: activityData, error: activityError } =
    useGetActivityById(activityId)

  const initialValue: ActivitySignatureValues = {
    name: '',
    signature: '',
  }

  const onSubmit = async (
    values: ActivitySignatureValues,
    { resetForm }: FormikHelpers<ActivitySignatureValues>
  ) => {
    if (!activityData) {
      activityError && addAlertErrorMessage(activityError.message)

      return
    }

    const activity = activityData
    const timestamp = new Date().toISOString()
    activity.signature = {
      date: timestamp.substring(0, timestamp.length - 5),
      image: values.signature,
      name: values.name,
      location: null,
    }

    if (location !== null) {
      activity.signature.location = {
        longitude: location.coords.longitude,
        latitude: location.coords.latitude,
      }
    }

    try {
      const updatedActivity = await updateActivity(activity)
      await overrideActivity(updatedActivity)

      resetForm({ values })

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )
  const schema = Yup.object().shape({
    name: Yup.string().required(requiredField),
    signature: Yup.string().required(requiredField),
  })

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsActivity.detailBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage
            id="activity.sign.title"
            defaultMessage="Sign activity"
          />
        </HeaderTitle>
      </HeaderArea>
      <Formik
        initialValues={initialValue}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({ values }) => (
          <Form>
            <LeaveDirtyFormPrompt />
            <SignatureNameField />
            <SignaturePadField values={values} />
            <SectionActions>
              <CancelButton onClick={() => navigate(-1)} />
              <SubmitButton />
            </SectionActions>
          </Form>
        )}
      </Formik>
    </>
  )
}

export type { ActivitySignatureValues }
export { ActivitySignature }
