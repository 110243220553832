import { createContext } from 'react'

interface RadioGroupContextValues {
  name: string
  value: string
}

export const RadioGroupContext = createContext<RadioGroupContextValues | null>(
  null
)
