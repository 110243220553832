import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Dialog } from '@reach/dialog'
import { useGetAddresses } from '../../../address/db/addresses'
import { useSearchAndPaginationInMemory } from '../../../../shared/pagination/inMemoryPagination'
import { DialogSearchHeader } from '../../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../../shared/content'
import { translationsAddress } from '../../../address/translations/translationsAddress'
import { translationsActivity } from '../../translations/translationsActivity'
import { Address, AddressType } from '../../../address/types'
import { useGetAllAddressTypes } from '../../../address/db/addressTypes'
import { AddressDialogList } from './AddressDialogList'

const AddressDialog: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  isOpen: boolean
  onConfirm: (value: string) => void
}> = ({ onDismiss, onConfirm, isOpen }) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const filterAddressTypes = useCallback((addressType: AddressType) => {
    return addressType.visibility.activities
  }, [])

  const { data: addressTypes } = useGetAllAddressTypes(filterAddressTypes)

  const addressTypeIds = useMemo(() => {
    return addressTypes === null || addressTypes === undefined
      ? []
      : addressTypes.map((addressType) => addressType.id)
  }, [addressTypes])

  const filterAddresses = useCallback(
    (address: Address) => {
      return addressTypeIds.includes(address.addressTypeId)
    },
    [addressTypeIds]
  )

  const { data } = useGetAddresses(search, page, size, filterAddresses)

  const title = searching
    ? intl.formatMessage(translationsActivity.addressDialogSearchResult)
    : intl.formatMessage(translationsAddress.overviewList)

  const empty = searching
    ? intl.formatMessage(translationsActivity.addressDialogSearchEmpty)
    : intl.formatMessage(translationsActivity.addressDialogListEmpty)

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={intl.formatMessage(translationsAddress.overviewTitle)}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={title}
        searchLabel={intl.formatMessage(
          translationsAddress.overviewSearchStart
        )}
        search={search}
        searchActions={searchActions}
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <AddressDialogList
                title={title}
                empty={empty}
                onSelect={onConfirm}
                paginationActions={paginationActions}
                data={data}
              />
            )
          }
        />
      </ContentArea>
    </Dialog>
  )
}

export { AddressDialog }
