import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

export enum Variant {
  primary = 'primary',
  primaryLight = 'primaryLight',
  secondary = 'secondary',
  primaryOutlined = 'primaryOutlined',
  primaryLightOutlined = 'primaryLightOutlined',
}

export type ButtonProps = {
  variant: Variant
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export type ButtonInternalLinkProps = {
  variant: Variant
} & LinkProps

export type ButtonExternalLinkProps = {
  variant: Variant
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

const variantStyles: Record<
  Variant,
  { variantClass: string; variantDisabledClass: string }
> = {
  [Variant.primary]: {
    variantClass:
      'px-4 py-2 rounded-full text-white bg-black border-2 border-black hover:bg-gray-700 focus:bg-gray-700',
    variantDisabledClass:
      'px-4 py-2 rounded-full text-gray-700 bg-gray-500 border-gray-500',
  },
  [Variant.primaryLight]: {
    variantClass:
      'px-4 py-2 rounded-full text-black bg-white border-2 border-white hover:bg-gray-300 focus:bg-gray-300',
    variantDisabledClass:
      'px-4 py-2 rounded-full text-black bg-gray-500 border-2 border-gray-500',
  },
  [Variant.primaryOutlined]: {
    variantClass:
      'px-4 py-2 rounded-full text-back border-2 border-black hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white',
    variantDisabledClass:
      'px-4 py-2 rounded-full text-gray-500 border-gray-500',
  },
  [Variant.primaryLightOutlined]: {
    variantClass:
      'px-4 py-2 bg-gray-700 rounded-full text-white border-2 border-withe hover:bg-gray-600 focus:bg-gray-600',
    variantDisabledClass:
      'px-4 py-2 rounded-full text-gray-500 border-2 border-gray-500',
  },
  [Variant.secondary]: {
    variantClass: 'text-black hover:underline focus:underline',
    variantDisabledClass: 'text-gray-500',
  },
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, type = 'button', className = '', children, ...props }, ref) => {
    return (
      <button
        className={classNames(
          'focus:outline-none fill-current',
          props.disabled
            ? variantStyles[variant].variantDisabledClass
            : variantStyles[variant].variantClass,
          className
        )}
        ref={ref}
        type={type}
        {...props}
      >
        {children}
      </button>
    )
  }
)

const ButtonInternalLink: React.FC<ButtonInternalLinkProps> = ({
  variant,
  className = '',
  children,
  ...props
}) => {
  return (
    <Link
      className={classNames(
        'focus:outline-none fill-current text-center flex justify-center items-center',
        variantStyles[variant].variantClass,
        className
      )}
      {...props}
    >
      {children}
    </Link>
  )
}

const ButtonExternalLink: React.FC<ButtonExternalLinkProps> = ({
  variant,
  className = '',
  children,
  ...props
}) => {
  return (
    <a
      className={classNames(
        'focus:outline-none fill-current text-center flex justify-center items-center',
        variantStyles[variant].variantClass,
        className
      )}
      {...props}
    >
      {children}
    </a>
  )
}

export { Button, ButtonInternalLink, ButtonExternalLink }
