import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SelectionInput } from '../../../shared/form/SelectionInput'
import { RadioGroup, RadioGroupRadio } from '../../../shared/form/radioGroup'
import { AuthContext } from '../../../auth/AuthContext'
import { useGetAllUsers } from '../../user/db/users'
import { useGetAddressGroupsForNotifications } from '../../address/db/addressGroups'
import { NOTIFICATION_TYPES_FIELDS } from '../types'
import { NotificationAssignmentType, NotificationFormFields } from './types'

const AssignEditSection: React.FC<{
  formik: FormikProps<NotificationFormFields>
}> = ({ formik }) => {
  const intl = useIntl()

  const { values, handleChange, setFieldValue } = formik

  const auth = useContext(AuthContext)
  const userId = auth.data?.user.id || ''

  const addressGroups = useGetAddressGroupsForNotifications()
  const users = useGetAllUsers()

  useEffect(() => {
    if (
      values.assignmentType === NotificationAssignmentType.none &&
      !NOTIFICATION_TYPES_FIELDS[values.type]?.enableAssignmentTypeNone
    ) {
      setFieldValue('assignmentType', NotificationAssignmentType.user)
      setFieldValue('userId', userId)
    }
  }, [values.assignmentType, values.type, userId, setFieldValue])

  useEffect(() => {
    if (values.assignmentType !== NotificationAssignmentType.user) {
      setFieldValue('userId', '')
    }
    if (values.assignmentType !== NotificationAssignmentType.addressGroup) {
      setFieldValue('addressGroupId', '')
    }
  }, [values.assignmentType, setFieldValue])

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="notification.form.title.assign"
            defaultMessage="Assign"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <RadioGroup name="assignmentType">
              {NOTIFICATION_TYPES_FIELDS[values.type]
                ?.enableAssignmentTypeNone ? (
                <RadioGroupRadio
                  value={NotificationAssignmentType.none}
                  label={intl.formatMessage({
                    id: `notification.form.assignmentTypes.none`,
                    defaultMessage: 'None',
                  })}
                />
              ) : null}
              <RadioGroupRadio
                value={NotificationAssignmentType.user}
                label={intl.formatMessage({
                  id: `notification.form.assignmentTypes.user`,
                  defaultMessage: 'User',
                })}
              />
              <RadioGroupRadio
                value={NotificationAssignmentType.addressGroup}
                label={intl.formatMessage({
                  id: `notification.form.assignmentTypes.addressGroup`,
                  defaultMessage: 'Group',
                })}
              />
            </RadioGroup>
            {values.assignmentType === NotificationAssignmentType.user ? (
              <SelectionInput
                onChange={handleChange}
                name="userId"
                label={intl.formatMessage({
                  id: 'notification.form.user.label',
                  defaultMessage: 'User',
                })}
              >
                <option value="" disabled>
                  {intl.formatMessage({
                    id: `notification.form.user.default`,
                    defaultMessage: 'Choose user',
                  })}
                </option>
                {users.data?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </SelectionInput>
            ) : values.assignmentType ===
              NotificationAssignmentType.addressGroup ? (
              <SelectionInput
                onChange={handleChange}
                name="addressGroupId"
                label={intl.formatMessage({
                  id: 'notification.form.addressGroup.label',
                  defaultMessage: 'Group',
                })}
              >
                <option value="" disabled>
                  {intl.formatMessage({
                    id: `notification.form.addressGroup.default`,
                    defaultMessage: 'Choose group',
                  })}
                </option>
                {addressGroups.data?.map((addressGroup) => (
                  <option key={addressGroup.id} value={addressGroup.id}>
                    {addressGroup.name}
                  </option>
                ))}
              </SelectionInput>
            ) : null}
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { AssignEditSection }
