import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Activity, Group } from '../types'
import { ActivityForm } from '../form/ActivityForm'
import { ActivityFormFields } from '../form/types'
import { updateActivity } from '../api'
import {
  extractErrorMessage,
  formatDateAndTimeForAPI,
} from '../../../shared/utils/apiUtils'
import { overrideActivity, useGetActivityGroup } from '../db/activities'
import {
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
} from '../../../shared/utils/dateUtils'
import { AppContext } from '../../../app/AppContext'
import { Error } from '../../../shared/error'
import { Notification } from '../../notification/types'
import { getAssignmentType } from '../../notification/utils'
import {
  deleteNotification as deleteNotificationFromDB,
  overrideNotification,
  useGetNotificationsByResource,
} from '../../notification/db/notifications'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import {
  createNotification,
  deleteNotification,
  saveNotification,
} from '../../notification/api'
import { NotificationFormFields } from '../../notification/forms/types'

const ActivityEditSection: React.FC<{
  activity: Activity
  activityGroupId: string
}> = ({ activity, activityGroupId }) => {
  const { data, error } = useGetActivityGroup(activityGroupId)

  return data ? (
    <AsyncView
      data={data}
      error={error}
      renderSuccess={(activityGroup) => (
        <ActivityEditSectionForm
          activity={activity}
          activityGroup={activityGroup}
        />
      )}
      renderError={(activityError) => (
        <Error text={activityError && activityError.message} />
      )}
    />
  ) : (
    <></>
  )
}

function ActivityEditSectionForm({
  activity,
  activityGroup,
}: {
  activity: Activity
  activityGroup: Group
}) {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)
  const { data: notifications } = useGetNotificationsByResource(
    ResourceType.activity,
    activity.id
  )

  const onSubmit = async (
    values: ActivityFormFields,
    { resetForm }: FormikHelpers<ActivityFormFields>
  ): Promise<void> => {
    try {
      const apiActivity: Activity = {
        ...values,
        id: activity.id,
        search: activity.search,
        sort: activity.sort,
        signature: activity.signature,
        activityCategoryId:
          values.activityCategoryId === '' ? null : values.activityCategoryId,
        activityTypeId:
          values.activityTypeId === '' ? null : values.activityTypeId,
        activityProgressId:
          values.activityProgressId === '' ? null : values.activityProgressId,
        startDate: formatDateAndTimeForAPI(values.startDate, values.startTime),
        endDate: formatDateAndTimeForAPI(values.endDate, values.endTime),
        dueDate:
          values.dueDate && values.dueTime
            ? formatDateAndTimeForAPI(values.dueDate, values.dueTime)
            : null,
      }

      const updatedActivity = await updateActivity(apiActivity)
      await overrideActivity(updatedActivity)

      const notificationToCreate = [] as NotificationFormFields[]
      const notificationToUpdate = [] as NotificationFormFields[]
      const notificationToDelete = [] as Notification[]

      for (const notification of values.notifications) {
        if (notification.id === '') {
          notificationToCreate.push(notification)
        } else {
          notificationToUpdate.push(notification)
        }
      }

      if (notifications) {
        for (const notification of notifications) {
          if (
            !notificationToUpdate.find(
              (notificationToUpdate) =>
                notificationToUpdate.id === notification.id
            )
          ) {
            notificationToDelete.push(notification)
          }
        }
      }

      for (const notification of notificationToCreate) {
        const result = await createNotification({
          ...(notification as Notification),
          referencedResourceType: ResourceType.activity,
          referencedResourceId: activity.id,
        })

        await overrideNotification(result)
      }

      for (const notification of notificationToUpdate) {
        const result = await saveNotification({
          ...(notification as Notification),
          referencedResourceType: ResourceType.activity,
          referencedResourceId: activity.id,
        })

        await overrideNotification(result)
      }

      for (const notification of notificationToDelete) {
        await deleteNotification(notification.id)

        await deleteNotificationFromDB(notification.id)
      }

      resetForm({ values })

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  const initialValues = useGetInitialValues(activity, notifications)

  if (!initialValues) return null

  return (
    <ActivityForm
      initialValues={initialValues}
      activityGroup={activityGroup}
      onSubmit={onSubmit}
    />
  )
}

const useGetInitialValues = (
  activity: Activity,
  notifications: Notification[] | null
): ActivityFormFields | null => {
  if (!notifications) {
    return null
  }

  return {
    ...activity,
    description: activity.description || '',
    activityCategoryId:
      activity.activityCategoryId === null ? '' : activity.activityCategoryId,
    activityTypeId:
      activity.activityTypeId === null ? '' : activity.activityTypeId,
    activityProgressId:
      activity.activityProgressId === null ? '' : activity.activityProgressId,
    files: activity.files.map((file) => {
      return {
        fileId: file.fileId,
        description: file.description || '',
      }
    }),
    startDate: extractDateFromTimestamp(activity.startDate),
    startTime: extractTimeFromTimestamp(activity.startDate),
    endDate: extractDateFromTimestamp(activity.endDate),
    endTime: extractTimeFromTimestamp(activity.endDate),
    dueDate:
      activity.dueDate !== null
        ? extractDateFromTimestamp(activity.dueDate)
        : '',
    dueTime:
      activity.dueDate !== null
        ? extractTimeFromTimestamp(activity.dueDate)
        : '',
    billable: false,
    addressId: activity.addressId ?? '',
    projectId: activity.projectId ?? '',
    notifications: notifications.map((notification) => {
      return {
        ...notification,
        assignmentType: getAssignmentType(notification),
      }
    }),
    startDateChanged: activity.startDateChanged ?? false,
    endDateChanged: activity.endDateChanged ?? false,
    durationChanged: activity.durationChanged ?? false,
  }
}

export { ActivityEditSection }
