import React, { useContext } from 'react'
import { FormattedMessage, defineMessage, useIntl } from 'react-intl'
import {
  IconDashboard,
  IconAlarm,
  IconFormatListBulleted,
  IconPermContactCalendar,
  IconStore,
} from '../../shared/svgs'
import { AppLayout } from '../../layouts/app'
import { AuthContext } from '../../auth/AuthContext'
import { Module } from '../../auth/types'
import { HeaderArea, HeaderTitle } from '../../shared/header'
import { translationsLayouts } from '../../layouts/app/translations/translationsLayouts'
import { useGetActivityGroups } from '../activity/db/activities'
import { HomeSection } from './HomeSection'
import { translationsHome } from './translations/translationsHome'

const HomeLayout: React.FC = () => {
  const intl = useIntl()
  const authContext = useContext(AuthContext)

  const modules = authContext.data === null ? [] : authContext.data.modules

  const hasModule = (searchedModule: Module): boolean => {
    const filteredModules = modules.filter((currentModule) => {
      return currentModule.module === searchedModule
    })

    return filteredModules.length > 0
  }

  const { data: activityGroups } = useGetActivityGroups()

  return (
    <AppLayout
      title={{ messageDescriptor: defineMessage(translationsHome.homeTitle) }}
    >
      <HeaderArea>
        <HeaderTitle>
          <FormattedMessage {...translationsHome.homeTitle} />
        </HeaderTitle>
      </HeaderArea>
      <nav
        className="flex flex-col"
        aria-label={intl.formatMessage(
          translationsLayouts.navAccessibilityMain
        )}
      >
        <HomeSection
          active={true}
          title={intl.formatMessage({
            id: 'home.nav.dashboard',
            defaultMessage: 'Dashboard',
          })}
          to="/dashboard"
          Icon={IconDashboard}
        />
        <HomeSection
          active={true}
          title={intl.formatMessage({
            id: 'home.nav.projects',
            defaultMessage: 'Projects',
          })}
          to="/projects"
          Icon={IconStore}
        />
        <HomeSection
          active={hasModule(Module.timeTacking)}
          title={intl.formatMessage({
            id: 'home.nav.timeTracking',
            defaultMessage: 'Time Tracking',
          })}
          to="/time-tracking"
          Icon={IconAlarm}
        />
        <HomeSection
          active={hasModule(Module.addresses)}
          title={intl.formatMessage({
            id: 'home.nav.addresses',
            defaultMessage: 'Addresses',
          })}
          to="/addresses"
          Icon={IconPermContactCalendar}
        />
        {activityGroups &&
          activityGroups.map((activityGroup) => (
            <HomeSection
              key={activityGroup.id}
              active={hasModule(Module.activities)}
              title={activityGroup.name}
              to={'/activity-groups/' + activityGroup.id}
              Icon={IconFormatListBulleted}
            />
          ))}
      </nav>
    </AppLayout>
  )
}

export { HomeLayout }
