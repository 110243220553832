import { FileReference } from '../file/types'
import { Address } from '../address/types'

export type Project = {
  id: string
  name: string
  sort: string
  search: string | null
  favorite: boolean
  addressId: string | null
  type: ProjectType
  additionalAddresses: Array<AdditionalAddress>
  options: {
    visibility: {
      projects: boolean
      timeTracking: boolean
    }
  }
  files: Array<FileReference>
}

export enum ProjectVisibility {
  projects = 'projects',
  timeTracking = 'timeTracking',
}

export type AdditionalAddress = {
  label: string
  addressId: string
  address?: Address
}

export enum ProjectType {
  Opportunity = 'opportunity',
  Offer = 'offer',
  Project = 'project',
  Production = 'production',
  Charge = 'charge',
  DeliveryPartial = 'deliverypartial',
  Delivery = 'delivery',
  InvoicePartial = 'invoicepartial',
  InvoiceAdvance = 'invoiceadvance',
  Credit = 'credit',
  Invoice = 'invoice',
  InvoiceIntern = 'invoiceintern',
}
