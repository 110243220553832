import React from 'react'
import {
  Section,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../../shared/section'
import { PaginatedData } from '../../../../db/types'
import { PaginationActions } from '../../../../shared/pagination/types'
import { Project } from '../../../project/types'
import { Empty } from '../../../../shared/empty'
import { IconStore } from '../../../../shared/svgs'
import { ProjectDialogItem } from './ProjectDialogItem'

type ProjectDialogListProps = {
  title: string
  empty: string
  data: PaginatedData<Project>
  onSelect: (projectId: string, project: Project) => void
} & PaginationActions

const ProjectDialogList: React.FC<ProjectDialogListProps> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((project) => (
          <ProjectDialogItem
            project={project}
            key={project.id}
            onSelect={onSelect}
          />
        ))}
        {data.items.length === 0 && <Empty icon={IconStore} text={empty} />}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { ProjectDialogList }
