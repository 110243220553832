import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { FileReference } from '../types'
import { BACKEND_API } from '../../../config/env'
import { FilePreview } from '../shared/FilePreview'
import { ButtonExternalLink, Variant } from '../../../shared/button/Button'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { useGetFileById } from '../db/files'

const FileItem: React.FC<{ fileReference: FileReference }> = ({
  fileReference,
}) => {
  const { data: file } = useGetFileById(fileReference.fileId)
  const intl = useIntl()
  const hasCloudLink = file?.cloudLink && file.cloudLink !== ''
  const link = hasCloudLink
    ? file?.cloudLink
    : `${BACKEND_API}/v1/user/files/${fileReference.fileId}/download`

  return (
    <SectionItem>
      <FilePreview url={link} alt={fileReference.description} />
      <div className="flex w-4/6 flex-col items-start space-y-8">
        <p>
          {fileReference.description ||
            intl.formatMessage(translationsShared.fileDetailNoDescription)}
        </p>
        <ButtonExternalLink variant={Variant.primaryOutlined} href={link}>
          {hasCloudLink ? (
            <FormattedMessage {...translationsShared.fileDetailOpen} />
          ) : (
            <FormattedMessage {...translationsShared.fileDetailDownload} />
          )}
        </ButtonExternalLink>
      </div>
    </SectionItem>
  )
}

const FileSection: React.FC<{ files: FileReference[] }> = ({ files }) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage {...translationsShared.fileDetailSectionTitle} />
      </SectionTitle>
      <SectionList>
        {files.map((file, index) => (
          <FileItem fileReference={file} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { FileSection }
