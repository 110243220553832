import React, { ReactElement, ReactNode } from 'react'
import { ErrorMessage, useField } from 'formik'
import classNames from 'classnames'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { useIntl } from 'react-intl'
import { FieldErrorMessage } from './FieldErrorMessage'

type DateTimeInputProps = {
  label?: ReactNode
  name: string
  disabled?: boolean
} & Partial<ReactDatePickerProps>

export function DateTimeInput({
  label,
  name,
  disabled,
  ...datePickerProps
}: DateTimeInputProps): ReactElement {
  const intl = useIntl()
  const [field, meta, helpers] = useField({ name: name })

  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-black'

  if (meta.touched && meta.error) {
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (disabled) {
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }

  return (
    <div className="flex-1 flex-col">
      <div
        className={classNames(
          'flex flex-row items-center border-b border-gray-500',
          borderCss
        )}
      >
        <label className="w-full">
          <span className="block text-2xs">{label}</span>
          <DatePicker
            selected={field.value ? new Date(field.value) : null}
            timeIntervals={15}
            locale={intl.locale}
            showTimeSelect
            showTimeInput
            dateFormat="dd.MM.yyyy - HH:mm"
            onChange={(date: Date) => {
              helpers.setTouched(true)
              helpers.setValue(date)
            }}
            className={classNames(
              'flex-1 pl-0 pb-1 pt-0.5 bg-transparent text-black focus:outline-none w-full border-none',
              inputCss
            )}
            placeholderText="dd.mm.yyyy - hh:mm"
            {...datePickerProps}
          />
        </label>
      </div>
      <ErrorMessage name={name} component={FieldErrorMessage} />
    </div>
  )
}
