import { defineMessages } from 'react-intl'

export const translationsShared = defineMessages({
  logoName: {
    id: 'shared.logo.name',
    defaultMessage: 'ZagZag',
  },
  logoTitle: {
    id: 'shared.logo.title',
    defaultMessage: 'Project Documentation',
  },
  logoSubtitle: {
    id: 'shared.logo.subtitle',
    defaultMessage: 'Captured & Check',
  },
  footerCompany: {
    id: 'shared.footer.company',
    defaultMessage: 'CAMID',
  },
  footerPrivacy: {
    id: 'shared.footer.privacy',
    defaultMessage: 'Privacy',
  },
  footerImprint: {
    id: 'shared.footer.imprint',
    defaultMessage: 'Imprint',
  },
  searchPlaceholder: {
    id: 'shared.search.placeholder',
    defaultMessage: 'Search',
  },
  searchBack: {
    id: 'shared.search.back',
    defaultMessage: 'Cancel search',
  },
  searchClear: {
    id: 'shared.search.clear',
    defaultMessage: 'Clear search',
  },
  username: {
    id: 'shared.username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'shared.password',
    defaultMessage: 'Password',
  },
  errorTitle: {
    id: 'shared.error.title',
    defaultMessage: 'Error',
  },
  paginationPrevious: {
    id: 'shared.pagination.previous',
    defaultMessage: 'Previous',
  },
  paginationNext: {
    id: 'shared.pagination.next',
    defaultMessage: 'Next',
  },
  paginationPage: {
    id: 'shared.pagination.page',
    defaultMessage: 'Page {page}',
  },
  validationRequired: {
    id: 'shared.validation.required',
    defaultMessage: 'Required',
  },
  ok: {
    id: 'shared.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'shared.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'shared.save',
    defaultMessage: 'Save',
  },
  goBack: {
    id: 'shared.goBack',
    defaultMessage: 'Go Back',
  },
  close: {
    id: 'shared.close',
    defaultMessage: 'Close',
  },
  time: {
    id: 'shared.time',
    defaultMessage: 'Time',
  },
  date: {
    id: 'shared.date',
    defaultMessage: 'Date',
  },
  edit: {
    id: 'shared.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'shared.delete',
    defaultMessage: 'Delete',
  },
  select: {
    id: 'shared.select',
    defaultMessage: 'Select',
  },
  details: {
    id: 'shared.details',
    defaultMessage: 'Details',
  },
  validationDate: {
    id: 'shared.validation.date',
    defaultMessage: 'Invalid date (YYYY-MM-DD)',
  },
  validationTime: {
    id: 'shared.validation.time',
    defaultMessage: 'Invalid time (HH:MM)',
  },
  fileUnknownError: {
    id: 'shared.file.unknown.error',
    defaultMessage:
      'There was an unknown error. Please try to delete and upload the file again.',
  },
  fileFiletypeError: {
    id: 'shared.file.filetype.error',
    defaultMessage:
      'The selected file is not valid. Please select another file.',
  },
  fileFormTitle: {
    id: 'shared.file.form.title',
    defaultMessage: 'Files',
  },
  fileFormNoFiles: {
    id: 'shared.file.form.no.files',
    defaultMessage: 'There are no files',
  },
  fileFormAdd: {
    id: 'shared.file.form.add',
    defaultMessage: 'Add file',
  },
  fileDetailSectionTitle: {
    id: 'shared.file.detail.section.title',
    defaultMessage: 'Files',
  },
  fileFormDelete: {
    id: 'shared.file.form.delete',
    defaultMessage: 'Delete file',
  },
  fileFormFieldDescription: {
    id: 'shared.file.form.field.description',
    defaultMessage: 'Description',
  },
  fileFormFieldCloudStorage: {
    id: 'shared.file.form.field.cloudStorage',
    defaultMessage: 'Save in cloud',
  },
  fileDetailNoDescription: {
    id: 'shared.file.detail.no.description',
    defaultMessage: 'No description',
  },
  fileDetailDownload: {
    id: 'shared.file.detail.download',
    defaultMessage: 'Download file',
  },
  fileDetailOpen: {
    id: 'shared.file.detail.open',
    defaultMessage: 'Open file',
  },
  favorites: {
    id: 'shared.favorites',
    defaultMessage: 'Favorites',
  },
  leaveDirtyFormDialogTitle: {
    id: 'shared.leaveDirtyFormDialog.title',
    defaultMessage: 'Leave?',
  },
  leaveDirtyFormDialogText: {
    id: 'shared.leaveDirtyFormDialog.text',
    defaultMessage: 'Are you sure you want to leave the page without saving?',
  },
  leaveDirtyFormDialogLeave: {
    id: 'shared.leaveDirtyFormDialog.leave',
    defaultMessage: 'Leave',
  },
  help: {
    id: 'shared.help',
    defaultMessage: 'See helper video',
  },
  filterButtonClear: {
    id: 'shared.filterButton.clear',
    defaultMessage: 'Clear selection',
  },
  scanQRCodeTitle: {
    id: 'shared.scanQRCode.title',
    defaultMessage: 'Scan QR Code',
  },
})
