import { Activity, Group } from '../types'

export const findActivityGroup = (
  activity: Activity,
  activityGroups: Group[] | null
) => {
  if (activityGroups == null || activity.activityCategoryId == null) {
    return null
  }

  return activityGroups.find((activityGroup) =>
    activityGroup.categoryIds.find(
      (categoryId) => activity.activityCategoryId === categoryId
    )
  )
}
