import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import { useGetActivityCategories, useGetActivityGroup } from '../db/activities'
import { Activity } from '../types'
import { ActivityOverviewContent } from './ActivityOverviewContent'
import { ActivityOverviewHeader } from './ActivityOverviewHeader'

const ActivityOverviewGroup: React.FC<{ id: string }> = ({ id }) => {
  const { page, size, search } = useSearchAndPaginationQueryUrlParameters()
  const [filterParams] = useSearchParams()

  const [showSearch, setShowSearch] = useState<boolean>(search !== '')

  const searching = showSearch && search !== ''
  const { data: activityGroup } = useGetActivityGroup(id)
  const { data: activityCategories } = useGetActivityCategories()

  return (
    <>
      <ActivityOverviewHeader
        title={activityGroup?.name}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        activityId={id}
      />
      {activityGroup && activityCategories && (
        <ActivityOverviewContent
          searching={searching}
          search={search}
          page={page}
          size={size}
          activityGroup={activityGroup}
          filter={(item: Activity) => {
            const filteredAssignedAddress = filterParams.get('assignedAddress')
            const filteredAddress = filterParams.get('address')

            const activityCategory = activityCategories?.find(
              (activityCategory) => {
                return activityCategory.id === item.activityCategoryId
              }
            )
            const activityProgress = activityCategory?.progresses.find(
              (activityProgress) => {
                return activityProgress.id === item.activityProgressId
              }
            )

            const matchesFilteredAssignedAddress =
              filteredAssignedAddress == null ||
              item.assignedAddressIds.includes(filteredAssignedAddress)
            const matchesFilteredAddress =
              filteredAddress == null || item.addressId === filteredAddress
            const matchesActivityCategory =
              item.activityCategoryId === null ||
              activityGroup === null ||
              activityGroup?.categoryIds.includes(item.activityCategoryId)
            const matchesActivityProgress =
              activityProgress !== undefined &&
              activityProgress.activityGroupIds &&
              activityProgress.activityGroupIds.includes(id)
            const matchesActivityGroup =
              activityProgress !== undefined &&
              activityProgress.activityGroupIds &&
              activityProgress.activityGroupIds.includes(id)

            return (
              matchesFilteredAssignedAddress &&
              matchesFilteredAddress &&
              matchesActivityCategory &&
              matchesActivityProgress &&
              matchesActivityGroup
            )
          }}
        />
      )}
    </>
  )
}

export { ActivityOverviewGroup }
