import React, { useCallback, useState } from 'react'
import { FormikProps } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { ContentArea } from '../../../shared/content'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { useGetProjectById } from '../../project/db/projects'
import { SectionListFormError } from '../../../shared/section/SectionListFormError'
import { ProjectTypeBadge } from '../../project/shared/ProjectTypeBadge'
import { ActivityFieldState } from '../types'
import { ActivityFormFields } from './types'
import { ProjectDialog } from './projectDialog/ProjectDialog'
import { FormDialogSelectItem } from './FormDialogSelectItem'
import { FormItem } from './FormItem'

const ProjectFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
  addressHidden: boolean | undefined
  fieldState?: ActivityFieldState
}> = ({ formik, addressHidden, fieldState }) => {
  const { values, setFieldValue } = formik
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const filter = useCallback(
    (project) => {
      if (values.addressId) {
        return project.addressId === values.addressId
      }
      return true
    },
    [values.addressId]
  )

  const { data } = useGetProjectById(values.projectId)

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="activity.form.project.title"
            defaultMessage="Linked project"
          />
        </SectionTitle>
        <SectionList>
          {data ? (
            <AsyncView
              data={data}
              renderSuccess={(data) =>
                fieldState !== ActivityFieldState.ReadOnly ? (
                  <FormDialogSelectItem onClick={() => setShowDialog(true)}>
                    <div className="flex">
                      <div className="flex-1">{data.name}</div>
                      <ProjectTypeBadge projectType={data.type} />
                    </div>
                  </FormDialogSelectItem>
                ) : (
                  <FormItem>
                    <div className="flex">
                      <div className="flex-1">{data.name}</div>
                      <ProjectTypeBadge projectType={data.type} />
                    </div>
                  </FormItem>
                )
              }
            />
          ) : (
            <FormDialogSelectItem onClick={() => setShowDialog(true)}>
              <FormattedMessage
                id="activity.form.project.empty"
                defaultMessage="No linked project"
              />
            </FormDialogSelectItem>
          )}
          <SectionListFormError name="projectId" />
        </SectionList>
      </Section>
      <ProjectDialog
        onDismiss={() => setShowDialog(false)}
        onConfirm={(projectId, project) => {
          setFieldValue('projectId', projectId)
          setFieldValue('addressId', project.addressId)
          setShowDialog(false)
        }}
        onClear={() => {
          setFieldValue('projectId', '')
          setFieldValue('addressId', '')
          setShowDialog(false)
        }}
        isOpen={showDialog}
        filter={addressHidden ? undefined : filter}
      />
    </ContentArea>
  )
}

export { ProjectFormSection }
