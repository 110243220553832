import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  NotificationAssignmentType,
  NotificationFormFields,
} from '../forms/types'
import { NotificationForm } from '../forms/NotificationForm'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { Notification } from '../types'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { createNotification } from '../api'
import { addNotification } from '../db/notifications'
import { AuthContext } from '../../../auth/AuthContext'
import { AppContext } from '../../../app/AppContext'
import { Group } from '../../activity/types'

type Props = {
  resourceType: ResourceType
  resourceId: string
  activityGroup?: Group
}

const NotificationAddSection: React.FC<Props> = ({
  resourceType,
  resourceId,
  activityGroup,
}) => {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const initialValues = useGetInitialValues(
    resourceType,
    resourceId,
    activityGroup
  )

  const onSubmit = async (value: NotificationFormFields): Promise<void> => {
    try {
      const createdNotification = await createNotification(
        value as Notification
      )

      await addNotification(createdNotification)

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return <NotificationForm initialValues={initialValues} onSubmit={onSubmit} />
}

const useGetInitialValues = (
  resourceType: ResourceType,
  resourceId: string,
  activityGroup?: Group
): NotificationFormFields => {
  const auth = useContext(AuthContext)
  const userId = auth.data?.user.id || ''

  const defaultType = activityGroup?.defaultNotificationType ?? ''

  return {
    id: '',
    sort: '-',
    type: defaultType,
    userId: userId,
    addressGroupId: '',
    referencedResourceType: resourceType,
    referencedResourceId: resourceId,
    assignmentType: NotificationAssignmentType.user,
    time: null,
    message: '',
    archived: false,
    visibility: {
      dashboard: false,
    },
    createdAt: '',
    updatedAt: '',
  }
}

export { NotificationAddSection }
