import React from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { IconClose } from '../../../shared/svgs'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { Error } from '../../../shared/error'
import { useGetNotificationById } from '../db/notifications'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { NotificationEditSection } from './NotificationEditSection'

const NotificationEdit: React.FC<{
  id: string
}> = ({ id }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { data, error } = useGetNotificationById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage
            id="notification.edit.title"
            defaultMessage="Edit notification"
          />
        </HeaderTitle>
      </HeaderArea>
      {data && (
        <AsyncView
          data={data}
          error={error}
          renderSuccess={(data) => (
            <NotificationEditSection notification={data} />
          )}
          renderError={(error) => <Error text={error && error.message} />}
        />
      )}
    </>
  )
}

export { NotificationEdit }
