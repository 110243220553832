import React from 'react'
import { defineMessage } from 'react-intl'
import { useState } from 'react'
import { AppLayout } from '../../layouts/app'
import { useSearchAndPaginationQueryUrlParameters } from '../../shared/pagination/routerPagination'
import { translationsProject } from './translations/translationsProject'
import { ProjectOverviewHeader } from './overview/ProjectOverviewHeader'
import { ProjectsOverviewContent } from './overview/ProjectOverviewContent'

const ProjectsOverviewPage: React.FC = () => {
  const { page, size, search } = useSearchAndPaginationQueryUrlParameters()
  const [showSearch, setShowSearch] = useState<boolean>(search !== '')
  const searching = showSearch && search !== ''
  return (
    <AppLayout
      title={{
        messageDescriptor: defineMessage(translationsProject.overviewTitle),
      }}
    >
      <ProjectOverviewHeader
        showSearch={showSearch}
        setShowSearch={setShowSearch}
      />
      <ProjectsOverviewContent
        searching={searching}
        search={search}
        page={page}
        size={size}
      />
    </AppLayout>
  )
}
export { ProjectsOverviewPage }
