import React, { useCallback, useState } from 'react'
import { useGetAllAddressTypes } from '../db/addressTypes'
import { Address } from '../types'
import { populateUserAddressTypes } from '../../settings/populate'
import { useGetAllAddresses } from '../db/addresses'
import { ChipsArea } from '../../../shared/filter/ChipsArea'
import { ClearChipButton } from '../../../shared/filter/ClearChipButton'
import { ContentArea } from '../../../shared/content'
import { ChipButton } from '../../../shared/filter/ChipButton'
import { AddressOverview } from './AddressOverview'

type Props = {
  searching: boolean
  search: string
  page: number
  size: number
}

const AddressOverviewContent: React.FC<Props> = ({
  searching,
  search,
  page,
  size,
}) => {
  const { data: addressTypes } = useGetAllAddressTypes()
  const { data: allAddresses } = useGetAllAddresses()
  const userAddressTypes = populateUserAddressTypes(allAddresses, addressTypes)
  const [selectedAddressTypes, setSelectedAddressTypes] = useState<string[]>([])

  const filterAddressBasedOnAddressType = useCallback(
    (item: Address) => {
      if (selectedAddressTypes.length === 0) {
        return true
      }
      return selectedAddressTypes.includes(item.addressTypeId)
    },
    [selectedAddressTypes]
  )

  return (
    <div>
      <ChipsArea>
        {userAddressTypes.map((addressType) => (
          <ChipButton
            key={addressType.id}
            onClick={() => {
              if (selectedAddressTypes.includes(addressType.id)) {
                setSelectedAddressTypes(
                  selectedAddressTypes.filter(
                    (selectedId) => selectedId !== addressType.id
                  )
                )
              } else {
                setSelectedAddressTypes([
                  ...selectedAddressTypes,
                  addressType.id,
                ])
              }
            }}
            selected={selectedAddressTypes.includes(addressType.id)}
          >
            {addressType.name}
          </ChipButton>
        ))}
        {selectedAddressTypes.length > 0 && (
          <ClearChipButton
            onClick={() => {
              setSelectedAddressTypes([])
            }}
          />
        )}
      </ChipsArea>
      <ContentArea>
        <AddressOverview
          searching={searching}
          search={search}
          page={page}
          size={size}
          filter={filterAddressBasedOnAddressType}
          selectedAddressTypeIds={selectedAddressTypes}
        />
      </ContentArea>
    </div>
  )
}
export default AddressOverviewContent
