import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../../shared/section'
import { SectionListVariant } from '../../../../shared/section/SectionList'
import { Project } from '../../../project/types'
import { translationsShared } from '../../../../shared/translations/translationsShared'
import { ProjectDialogItem } from './ProjectDialogItem'

type Props = {
  data: Array<Project>
  onSelect: (projectId: string, project: Project) => void
}

export function ProjectDialogFavorites({
  data,
  onSelect,
}: Props): ReactElement | null {
  if (data.length === 0) {
    return null
  }

  return (
    <Section>
      <SectionTitle>
        <FormattedMessage {...translationsShared.favorites} />
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {data.map((project) => (
          <ProjectDialogItem
            project={project}
            key={project.id}
            onSelect={onSelect}
          />
        ))}
      </SectionList>
    </Section>
  )
}
