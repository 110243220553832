import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { AddressDetail } from './details/AddressDetail'
import { translationsAddress } from './translations/translationsAddress'

const AddressDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('tab')

  return (
    <AppLayout title={{ messageDescriptor: translationsAddress.detailTitle }}>
      <AddressDetail id={id as string} tab={currentTab} />
    </AppLayout>
  )
}

export { AddressDetailPage }
