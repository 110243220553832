import { useEffect, useMemo, useState } from 'react'
import { Project, ProjectVisibility } from '../types'
import { ListResponse, TABLE_PROJECTS } from '../../../db/types'
import { db } from '../../../db'
import {
  attachTableListener,
  useGetById,
  useGetByIds,
  useGetList,
  useGetPaginatedList,
} from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

type IndexedProject = Project & { searchIndexed: Array<string>; sort: string }

const generateIndexedSearch = (project: Project): Array<string> => {
  if (project.search === undefined || project.search === null) {
    return []
  }

  return project.search.toLocaleLowerCase().split(' ')
}

const generateIndexedSort = (project: Project): string => {
  return project.sort ?? ''
}

const overrideProjects = async (projects: Project[]): Promise<void> => {
  const searchableProjects = projects.map((project) => {
    return {
      ...project,
      searchIndexed: generateIndexedSearch(project),
      sort: generateIndexedSort(project),
    }
  })

  await updateDatabase(db, TABLE_PROJECTS, searchableProjects)
}

const overrideProject = async (project: Project): Promise<void> => {
  const searchableProject = {
    ...project,
    searchIndexed: generateIndexedSearch(project),
  }

  await db.table(TABLE_PROJECTS).put(searchableProject)
}

const useGetFavoriteProjects = (
  visibility?: ProjectVisibility,
  filter?: (project: Project) => boolean
) => {
  const options = useMemo(
    () => ({
      sortDirection: 'desc' as const,
      filter: (project: Project) => {
        if (!project.favorite) {
          return false
        }

        if (filter && !filter(project)) {
          return false
        }

        if (visibility) {
          return project.options.visibility[visibility]
        }

        return true
      },
    }),
    [visibility, filter]
  )

  return useGetList<Project>(TABLE_PROJECTS, options)
}

const useGetProjects = (
  search: string,
  page: number,
  size: number,
  visibility?: ProjectVisibility,
  filter?: (project: Project) => boolean
) => {
  const options = useMemo(
    () => ({
      sortBy: 'sort',
      sortDirection: 'desc' as const,
      filter: (project: Project) => {
        if (filter && !filter(project)) {
          return false
        }

        if (visibility) {
          return project.options.visibility[visibility]
        }

        return true
      },
    }),
    [visibility, filter]
  )

  return useGetPaginatedList<Project>(
    TABLE_PROJECTS,
    search,
    page,
    size,
    options
  )
}
const useGetProjectsForOverview = (
  search: string,
  page: number,
  size: number,
  filter?: (project: Project) => boolean
) =>
  useGetPaginatedList<Project>(TABLE_PROJECTS, search, page, size, {
    sortBy: 'sort',
    sortDirection: 'desc',
    filter,
  })

const useGetAllProjects = (filter: (project: Project) => boolean) => {
  return useGetList<Project>(TABLE_PROJECTS, { sortDirection: 'desc', filter })
}
const useGetProjectById = (id: string) =>
  useGetById<Project>(TABLE_PROJECTS, id)

const useGetProjectsByIds = (ids: string[]) => {
  return useGetByIds<Project>(TABLE_PROJECTS, ids)
}

const useGetProjectByAddressId = (id: string): ListResponse<Project> => {
  const [data, setData] = useState<Array<Project> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_PROJECTS)
        .filter((p: Project) => p.addressId === id)
        .sortBy('name')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setError(error)
          setData(null)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_PROJECTS)
  }, [id])

  return { data, error }
}

const getProjectById = (id: string, visibility?: ProjectVisibility) => {
  return db
    .table(TABLE_PROJECTS)
    .filter((p: IndexedProject) => {
      if (visibility) {
        return p.id === id && p.options.visibility[visibility]
      }

      return p.id === id
    })
    .first()
}

export {
  overrideProjects,
  overrideProject,
  useGetFavoriteProjects,
  useGetProjects,
  useGetProjectById,
  useGetProjectByAddressId,
  useGetProjectsByIds,
  useGetAllProjects,
  useGetProjectsForOverview,
  getProjectById,
}
