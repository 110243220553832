import {
  useGetActivityById,
  useGetActivityGroups,
} from '../activity/db/activities'
import { findActivityGroup } from '../activity/form/activityGroupUtils'
import { Activity, Group } from '../activity/types'
import { Notification, RESOURCE_TYPE_URL_MAPPER } from './types'
import { NotificationAssignmentType } from './forms/types'

export const useGetNotificationLink = (notification: Notification) => {
  const { data: activity } = useGetActivityById(
    notification.referencedResourceId
  )
  const { data: activityGroups } = useGetActivityGroups()

  return getNotificationLink(notification, activityGroups, activity)
}

export const getNotificationLink = (
  notification: Notification,
  activityGroups: Group[] | null,
  activity: Activity | null | undefined
) => {
  if (notification.referencedResourceType === 'activity' && activity != null) {
    var activityGroup = findActivityGroup(activity, activityGroups)

    return `/activity-groups/${activityGroup?.id}/${
      RESOURCE_TYPE_URL_MAPPER[notification.referencedResourceType]
    }/${notification.referencedResourceId}/notifications/${notification.id}`
  }

  return `/${RESOURCE_TYPE_URL_MAPPER[notification.referencedResourceType]}/${
    notification.referencedResourceId
  }/notifications/${notification.id}`
}

export const getAssignmentType = (notification: Notification) => {
  return notification.userId
    ? NotificationAssignmentType.user
    : notification.addressGroupId
    ? NotificationAssignmentType.addressGroup
    : NotificationAssignmentType.none
}
