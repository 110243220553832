import { useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useCallback } from 'react'
import { Error } from '../../../shared/error'
import {
  useGetFavoriteProjects,
  useGetProjectsForOverview,
} from '../db/projects'
import { ContentArea } from '../../../shared/content'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { Project, ProjectVisibility } from '../types'
import { Help } from '../../../shared/help/Help'
import { translationsProject } from '../translations/translationsProject'
import { ProjectsOverviewList } from './ProjectsOverviewList'
import { ProjectsOverviewFavorites } from './ProjectsOverviewFavorites'

type Props = {
  searching: boolean
  search: string
  page: number
  size: number
  filter?: (item: Project) => boolean
  projectTypes: string[]
}
const ProjectsOverview: React.FC<Props> = ({
  searching,
  search,
  page,
  size,
  filter,
  projectTypes,
}) => {
  const intl = useIntl()

  const { data: favoritesData } = useGetFavoriteProjects(
    ProjectVisibility.projects,
    useCallback(
      (project) =>
        projectTypes.includes(project.type) || project.type.length === 0,
      [projectTypes]
    )
  )

  const { data: projectData, error: projectError } = useGetProjectsForOverview(
    search,
    page,
    size,
    filter
  )

  const title = searching
    ? intl.formatMessage(translationsProject.overviewSearchResults)
    : intl.formatMessage(translationsProject.overviewListAll)

  const empty = searching
    ? intl.formatMessage(translationsProject.overviewSearchEmpty)
    : intl.formatMessage(translationsProject.overviewListEmpty)

  return (
    <>
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoritesData}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <ProjectsOverviewFavorites data={favoritesData} />
              )
            }
          />
        )}
        <AsyncView
          data={projectData}
          error={projectError}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(projectData) =>
            projectData && (
              <ProjectsOverviewList
                title={title}
                empty={empty}
                data={projectData}
              />
            )
          }
          renderError={(projectError) => (
            <Error text={projectError && projectError.message} />
          )}
        />
        <Help href="https://camid.eu" />
      </ContentArea>
    </>
  )
}

export { ProjectsOverview }
