import React from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import classNames from 'classnames'
import { Translations } from '../shared/translations/types'
import { ButtonInternalLink } from '../shared/button'
import { IconWarning } from '../shared/svgs'
import { i18n } from '../config/i18n'
import { Variant } from '../shared/button/Button'
import styles from './Error.module.css'

const messages: Translations = {
  en: {
    notFoundMessage: 'This page does not exist.',
    notFoundButton: 'Go back home',
  },
  de: {
    notFoundMessage: 'Diese Seite existiert nicht.',
    notFoundButton: 'Zurück zur Startseite',
  },
  it: {
    notFoundMessage: 'This page does not exist.',
    notFoundButton: 'Go back home',
  },
}

const NotFoundError: React.FC = () => {
  const language = i18n.detectBrowserLanguage()

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div data-testid="network-error" className="flex h-screen flex-col">
        <div className="flex flex-1 flex-col justify-center bg-primary-500 text-center md:bg-white">
          <h1 className="text-3xl font-bold">Ups!</h1>
          <p className="mt-3 text-lg">
            <FormattedMessage
              id="notFoundMessage"
              defaultMessage="This page does not exist"
            />
          </p>
        </div>

        <div
          className={classNames(
            styles.lines,
            'flex-1 py-10 w-full flex justify-center items-center rounded-b-lg bg-primary-500 md:bg-white'
          )}
        >
          <div className="rounded-full border border-black bg-primary-500 p-4 md:border-primary-500 md:bg-white">
            <div className="rounded-full bg-black p-4 text-primary-500 md:bg-primary-500 md:text-black">
              <IconWarning className="h-10 w-10 fill-current md:h-16 md:w-16" />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.button,
            'flex justify-center items-center'
          )}
        >
          <ButtonInternalLink
            to="/"
            className="w-full max-w-xs"
            variant={Variant.primary}
          >
            <FormattedMessage
              id="notFoundButton"
              defaultMessage="Go back home"
            />
          </ButtonInternalLink>
        </div>
      </div>
    </IntlProvider>
  )
}

export { NotFoundError }
