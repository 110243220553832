import { MessageDescriptor, defineMessages } from 'react-intl'
import { ProjectType } from '../types'

export const translationsProject = defineMessages({
  overviewTitle: {
    id: 'project.overview.title',
    defaultMessage: 'Projects',
  },
  overviewListFavorites: {
    id: 'project.overview.list.favorites',
    defaultMessage: 'Favorites',
  },
  overviewListAll: {
    id: 'project.overview.list.all',
    defaultMessage: 'List of projects',
  },
  overviewListEmpty: {
    id: 'project.overview.list.empty',
    defaultMessage: 'No projects available',
  },
  overviewSearchResults: {
    id: 'project.overview.search.result',
    defaultMessage: 'Search results',
  },
  overviewSearchEmpty: {
    id: 'project.overview.search.empty',
    defaultMessage: 'No projects could be found',
  },
  detailTitle: {
    id: 'project.detail.title',
    defaultMessage: 'Project details',
  },
  detailBack: {
    id: 'project.detail.back',
    defaultMessage: 'Back to projects',
  },
  favoriteMark: {
    id: 'project.favorite.mark',
    defaultMessage: 'Mark as favorite',
  },
  favoriteUnmark: {
    id: 'project.favorite.unmark',
    defaultMessage: 'Unmark as favorite',
  },
  overviewSearchStart: {
    id: 'project.overview.search.start',
    defaultMessage: 'Search projects',
  },
  overviewProjectTypeOpportunity: {
    id: 'project.overview.type.opportunity',
    defaultMessage: 'Opportunity',
  },
  overviewProjectTypeOffer: {
    id: 'project.overview.type.offer',
    defaultMessage: 'Offer',
  },
  overviewProjectTypeProject: {
    id: 'project.overview.type.project',
    defaultMessage: 'Order',
  },
  overviewProjectTypeProduction: {
    id: 'project.overview.type.production',
    defaultMessage: 'Production Order',
  },
  overviewProjectTypeDelivery: {
    id: 'project.overview.type.delivery',
    defaultMessage: 'Delivery bill',
  },
  overviewProjectTypeDeliveryPartial: {
    id: 'project.overview.type.deliverypartial',
    defaultMessage: 'Part delivery bill',
  },
  overviewProjectTypeInvoice: {
    id: 'project.overview.type.invoice',
    defaultMessage: 'Invoice',
  },
  overviewProjectTypeInvoicePartial: {
    id: 'project.overview.type.invoicepartial',
    defaultMessage: 'Partial invoice',
  },
  overviewProjectTypeInvoiceAdvance: {
    id: 'project.overview.type.invoiceadvance',
    defaultMessage: 'Down payment invoice',
  },
  overviewProjectTypeCredit: {
    id: 'project.overview.type.credit',
    defaultMessage: 'Credit note',
  },
  overviewProjectTypeInvoiceIntern: {
    id: 'project.overview.type.invoiceintern',
    defaultMessage: 'Internal invoice',
  },
  overviewProjectTypeCharge: {
    id: 'project.overview.type.charge',
    defaultMessage: 'Production lot',
  },
})
export function translationsProjectType(
  projectType: ProjectType
): MessageDescriptor {
  switch (projectType) {
    case ProjectType.Opportunity:
      return translationsProject.overviewProjectTypeOpportunity
    case ProjectType.Offer:
      return translationsProject.overviewProjectTypeOffer
    case ProjectType.Project:
      return translationsProject.overviewProjectTypeProject
    case ProjectType.Production:
      return translationsProject.overviewProjectTypeProduction
    case ProjectType.Delivery:
      return translationsProject.overviewProjectTypeDelivery
    case ProjectType.DeliveryPartial:
      return translationsProject.overviewProjectTypeDeliveryPartial
    case ProjectType.Invoice:
      return translationsProject.overviewProjectTypeInvoice
    case ProjectType.InvoicePartial:
      return translationsProject.overviewProjectTypeInvoicePartial
    case ProjectType.InvoiceAdvance:
      return translationsProject.overviewProjectTypeInvoiceAdvance
    case ProjectType.Credit:
      return translationsProject.overviewProjectTypeCredit
    case ProjectType.InvoiceIntern:
      return translationsProject.overviewProjectTypeInvoiceIntern
    case ProjectType.Charge:
      return translationsProject.overviewProjectTypeCharge
  }
}
