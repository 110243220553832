import React from 'react'
import { Dialog, DialogContent } from '@reach/dialog'
import { FormattedMessage } from 'react-intl'
import { Button } from '../button'
import { Variant } from '..//button/Button'
import { translationsShared } from '../translations/translationsShared'

const DeleteDialog: React.FC<{
  isOpen: boolean
  disableDelete: boolean
  onDismiss: () => void
  onConfirm: () => void
  title: string
  text: string
}> = ({ isOpen, disableDelete, onDismiss, onConfirm, title, text }) => {
  return (
    <Dialog
      isOpen={isOpen}
      className="mx-auto bg-white md:my-64 md:w-full md:max-w-lg md:bg-transparent"
      aria-label={title}
    >
      <DialogContent aria-label={title}>
        <div className="rounded-lg bg-white p-4">
          <div className="text-xl font-bold">{title}</div>
          <p className="mt-3">{text}</p>
          <div className="mt-10 space-x-4 text-right">
            <Button variant={Variant.secondary} onClick={onDismiss}>
              <FormattedMessage {...translationsShared.cancel} />
            </Button>
            <Button
              disabled={disableDelete}
              variant={Variant.primary}
              onClick={onConfirm}
            >
              <FormattedMessage {...translationsShared.delete} />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { DeleteDialog }
