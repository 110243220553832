import React, { useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { AddressFormFields } from '../forms/types'
import { AddressForm } from '../forms/AddressForm'
import { Address } from '../types'
import { createAddress } from '../api'
import { addAddress } from '../db/addresses'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { AppContext } from '../../../app/AppContext'

const AddressAddSection: React.FC = () => {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const initialValues: AddressFormFields = {
    name: '',
    address: {
      street: '',
      cap: '',
      city: '',
      country: '',
      latitude: 0,
      longitude: 0,
    },
    phoneNumbers: [],
    emailAddresses: [],
    files: [],
  }

  const onSubmit = async (
    values: AddressFormFields,
    { resetForm }: FormikHelpers<AddressFormFields>
  ): Promise<void> => {
    try {
      const address: Address = values as Address

      address.id = uuid()
      address.search = address.name
      address.sort = address.name
      address.parentId = null
      address.favorite = false
      address.label = ''

      const createdAddress = await createAddress(address)
      await addAddress(createdAddress)

      resetForm({ values })

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return <AddressForm initialValues={initialValues} onSubmit={onSubmit} />
}

export { AddressAddSection }
