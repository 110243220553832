import React, { ReactElement } from 'react'
import { Dialog, DialogContent } from '@reach/dialog'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../button'
import { Variant } from '../button/Button'
import { translationsShared } from '../translations/translationsShared'

function LeaveDirtyFormDialog({
  isOpen,
  onDismiss,
  onConfirm,
}: {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
}): ReactElement {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      className="mx-auto bg-white md:my-64 md:w-full md:max-w-lg md:bg-transparent"
      aria-label={intl.formatMessage(
        translationsShared.leaveDirtyFormDialogTitle
      )}
    >
      <DialogContent
        aria-label={intl.formatMessage(
          translationsShared.leaveDirtyFormDialogTitle
        )}
      >
        <div className="rounded-lg bg-white p-4">
          <div className="text-xl font-bold">
            <FormattedMessage
              {...translationsShared.leaveDirtyFormDialogTitle}
            />
          </div>
          <p className="mt-3">
            <FormattedMessage
              {...translationsShared.leaveDirtyFormDialogText}
            />
          </p>
          <div className="mt-10 space-x-4 text-right">
            <Button variant={Variant.secondary} onClick={onDismiss}>
              <FormattedMessage {...translationsShared.goBack} />
            </Button>
            <Button variant={Variant.primary} onClick={onConfirm}>
              <FormattedMessage
                {...translationsShared.leaveDirtyFormDialogLeave}
              />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { LeaveDirtyFormDialog }
