import { useEffect, useState } from 'react'
import { Address } from '../types'
import { db } from '../../../db'
import { ListResponse, TABLE_ADDRESSES } from '../../../db/types'
import {
  attachTableListener,
  useGetById,
  useGetByIds,
  useGetList,
  useGetPaginatedList,
} from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const generateIndexedSearch = (address: Address): Array<string> => {
  if (address.search === undefined || address.search === null) {
    return []
  }

  return address.search.toLocaleLowerCase().split(' ')
}

const generateIndexedSort = (address: Address) => {
  return address.sort ?? ''
}

const overrideAddresses = async (addresses: Array<Address>): Promise<void> => {
  const searchableAddresses = addresses.map((address: Address) => {
    return {
      ...address,
      searchIndexed: generateIndexedSearch(address),
      sortIndexed: generateIndexedSort(address),
    }
  })

  await updateDatabase(db, TABLE_ADDRESSES, searchableAddresses)
}

const overrideAddress = async (address: Address): Promise<void> => {
  await db.table(TABLE_ADDRESSES).put({
    ...address,
    searchIndexed: generateIndexedSearch(address),
    sortIndexed: generateIndexedSort(address),
  })
}

const addAddress = async (address: Address): Promise<void> => {
  await db.table(TABLE_ADDRESSES).put({
    ...address,
    searchIndexed: generateIndexedSearch(address),
    sortIndexed: generateIndexedSort(address),
  })
}

const clearAddresses = async (): Promise<void> => {
  await db.table(TABLE_ADDRESSES).clear()
}

const useGetAddresses = (
  search: string,
  page: number,
  size: number,
  filter?: (item: Address) => boolean
) =>
  useGetPaginatedList<Address>(TABLE_ADDRESSES, search, page, size, {
    filter: filter,
    sortBy: 'sortIndexed',
    sortDirection: 'asc',
  })

const useGetFavoriteAddresses = (
  addressTypeIds: string[]
): ListResponse<Address> => {
  const [data, setData] = useState<Array<Address> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      db.table(TABLE_ADDRESSES)
        .filter(
          (address: Address) =>
            address.favorite &&
            (addressTypeIds.length === 0 ||
              addressTypeIds.includes(address.addressTypeId))
        )
        .sortBy('sortIndexed')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ADDRESSES)
  }, [addressTypeIds])

  return { data, error }
}

const useGetAddressById = (id: string) => {
  return useGetById<Address>(TABLE_ADDRESSES, id)
}

const useGetAddressesByIds = (ids: string[]) => {
  return useGetByIds<Address>(TABLE_ADDRESSES, ids)
}

const useGetAllAddresses = () => {
  return useGetList<Address>(TABLE_ADDRESSES)
}

const useGetRelatedAddresses = (
  address: Address | null
): ListResponse<Address> => {
  const [data, setData] = useState<Array<Address> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      const query = async (address: Address): Promise<Array<Address>> => {
        if (address.parentId !== null) {
          return db
            .table(TABLE_ADDRESSES)
            .where('id')
            .equalsIgnoreCase(address.parentId)
            .sortBy('sortIndexed')
        }

        return db
          .table(TABLE_ADDRESSES)
          .where('parentId')
          .equalsIgnoreCase(address.id)
          .sortBy('sortIndexed')
      }

      if (address) {
        query(address)
          .then((items) => {
            setData(items)
            setError(null)
          })
          .catch((error) => {
            setError(error)
            setData(null)
          })
      }
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ADDRESSES)
  }, [address])

  return { data, error }
}

export {
  overrideAddresses,
  overrideAddress,
  useGetAddresses,
  useGetFavoriteAddresses,
  useGetAddressById,
  useGetRelatedAddresses,
  addAddress,
  clearAddresses,
  useGetAddressesByIds,
  useGetAllAddresses,
}
