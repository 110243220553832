import { ReactElement, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ProjectType } from '../types'

const ProjectColorRecord: Record<ProjectType, string> = {
  [ProjectType.Opportunity]: '#a0a5ac',
  [ProjectType.Offer]: '#1753e3',
  [ProjectType.Project]: '#3b8524',
  [ProjectType.Production]: '#f5cc69',
  [ProjectType.Delivery]: '#ed8432',
  [ProjectType.DeliveryPartial]: '#f1ad68',
  [ProjectType.Invoice]: '#d0311f',
  [ProjectType.InvoicePartial]: '#e8745a',
  [ProjectType.InvoiceAdvance]: '#e8745a',
  [ProjectType.Credit]: '#e8745a',
  [ProjectType.InvoiceIntern]: '#e8745a',
  [ProjectType.Charge]: '#b78e45',
}

export const projectTypeTranslations = defineMessages({
  [ProjectType.Opportunity]: {
    id: 'project.detail.type.opportunity',
    defaultMessage: 'Opportunity',
  },
  [ProjectType.Offer]: {
    id: 'project.detail.type.offer',
    defaultMessage: 'Offer',
  },
  [ProjectType.Project]: {
    id: 'project.detail.type.project',
    defaultMessage: 'Order',
  },
  [ProjectType.Production]: {
    id: 'project.detail.type.production',
    defaultMessage: 'Production Order',
  },
  [ProjectType.Delivery]: {
    id: 'project.detail.type.delivery',
    defaultMessage: 'Delivery bill',
  },
  [ProjectType.DeliveryPartial]: {
    id: 'project.detail.type.deliverypartial',
    defaultMessage: 'Part delivery bill',
  },
  [ProjectType.Invoice]: {
    id: 'project.detail.type.invoice',
    defaultMessage: 'Invoice',
  },
  [ProjectType.InvoicePartial]: {
    id: 'project.detail.type.invoicepartial',
    defaultMessage: 'Partial invoice',
  },
  [ProjectType.InvoiceAdvance]: {
    id: 'project.detail.type.invoiceadvance',
    defaultMessage: 'Down payment invoice',
  },
  [ProjectType.Credit]: {
    id: 'project.detail.type.credit',
    defaultMessage: 'Credit note',
  },
  [ProjectType.InvoiceIntern]: {
    id: 'project.detail.type.invoiceintern',
    defaultMessage: 'Internal invoice',
  },
  [ProjectType.Charge]: {
    id: 'project.detail.type.charge',
    defaultMessage: 'Production lot',
  },
})

type Props = {
  projectType: ProjectType
}

export function ProjectTypeBadge({ projectType }: Props): ReactElement {
  const intl = useIntl()
  const typeName = useMemo(
    () => intl.formatMessage(projectTypeTranslations[projectType]),
    [intl, projectType]
  )

  const projectColor = ProjectColorRecord[projectType]

  return (
    <div className="inline-flex items-center space-x-2 font-normal">
      <span>
        <FormattedMessage {...projectTypeTranslations[projectType]} />
      </span>
      <span
        style={{
          backgroundColor: projectColor,
        }}
        className="block h-6 w-6 rounded-full"
        title={typeName}
        aria-label={typeName}
      ></span>
    </div>
  )
}
