import React, { ReactElement } from 'react'
import { useGetAddressTypeById } from '../db/addressTypes'
import { getIcon } from '../icons'

type Props = {
  typeId: string
}

export function AddressTypeBadge({ typeId }: Props): ReactElement {
  const { data: addressType } = useGetAddressTypeById(typeId)
  const Icon = getIcon(addressType?.icon)

  return <Icon className="h-6 w-6" />
}
