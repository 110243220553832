import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import {
  getProjectById,
  useGetFavoriteProjects,
  useGetProjects,
} from '../db/projects'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import { DialogSelectionLoading } from '../../../shared/dialog/selection/DialogSelectionLoading'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { ProjectVisibility } from '../types'
import { DismissType } from '../../../shared/dialog/types'
import { translationsProject } from '../translations/translationsProject'
import { QrCodeView } from '../../activity/form/activityForm/steps/QrCodeView'
import { DialogGenericAction } from '../../../shared/dialog/DialogGenericAction'
import { IconQrCodeScanner } from '../../../shared/svgs'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { ProjectsSelectionListFavorites } from './ProjectSelectionListFavorites'
import { ProjectsSelectionList } from './ProjectsSelectionList'

type Props = {
  projectVisibility?: ProjectVisibility
  dismissType?: DismissType
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
}

const ProjectsSelection: React.FC<Props> = ({
  projectVisibility,
  dismissType = DismissType.close,
  onDismiss,
  onConfirm,
}) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()
  const [scanning, setScanning] = useState<boolean>(false)

  const searching = search !== ''

  const { data: favoritesData } = useGetFavoriteProjects(projectVisibility)

  const { data: listData, error: listError } = useGetProjects(
    search,
    page,
    size,
    projectVisibility
  )

  const title = searching
    ? intl.formatMessage(translationsProject.overviewSearchResults)
    : intl.formatMessage(translationsProject.overviewListAll)

  const empty = searching
    ? intl.formatMessage(translationsProject.overviewSearchEmpty)
    : intl.formatMessage(translationsProject.overviewListEmpty)

  return !scanning ? (
    <>
      <DialogSearchHeader
        searchActions={searchActions}
        search={search}
        searchLabel={intl.formatMessage(
          translationsProject.overviewSearchStart
        )}
        title={<FormattedMessage {...translationsProject.overviewTitle} />}
        dismissType={dismissType}
        onDismiss={onDismiss}
        additionalAction={
          <DialogGenericAction
            icon={IconQrCodeScanner}
            label={intl.formatMessage(translationsShared.scanQRCodeTitle)}
            onClick={() => {
              setScanning(!scanning)
            }}
          />
        }
      />
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoritesData}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <ProjectsSelectionListFavorites
                  data={favoritesData}
                  onSelect={onConfirm}
                />
              )
            }
          />
        )}
        <AsyncView
          data={listData}
          error={listError}
          renderLoading={<DialogSelectionLoading title={title} />}
          renderSuccess={(listData) =>
            listData && (
              <ProjectsSelectionList
                title={title}
                empty={empty}
                data={listData}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(listError) => (
            <Error text={listError && listError.message} />
          )}
        />
      </ContentArea>
    </>
  ) : (
    <QrCodeView
      onDismiss={() => setScanning(false)}
      onError={() => {
        setScanning(false)
      }}
      onScan={async (result) => {
        if (result) {
          try {
            const item = await getProjectById(result, projectVisibility)

            if (item) {
              onConfirm(item.id)
            } else {
              setScanning(false)
            }
          } catch (e) {
            console.error(e)
            setScanning(false)
          }
        }
      }}
    />
  )
}

export { ProjectsSelection }
