import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { defineMessage } from 'react-intl'
import { AppLayout } from '../../layouts/app'
import { ProjectDetail } from './detail/ProjectDetail'
import { translationsProject } from './translations/translationsProject'

const ProjectDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('tab')

  return (
    <AppLayout
      title={{
        messageDescriptor: defineMessage(translationsProject.detailTitle),
      }}
    >
      <ProjectDetail id={id as string} tab={currentTab} />
    </AppLayout>
  )
}

export { ProjectDetailPage }
