import React from 'react'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { InputWithLabel } from '../../../shared/form'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { IconRemoveCircleOutline } from '../../../shared/svgs'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { AddressFormFields } from './types'

const EditFields: React.FC<{
  index: number
  labelText: string
  numberText: string
  removeAction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onBlur?: () => void
}> = ({ index, labelText, numberText, removeAction, onBlur }) => {
  const intl = useIntl()

  return (
    <div className="flex">
      <div className="mr-5 w-2/6">
        <InputWithLabel
          onBlur={onBlur}
          name={`phoneNumbers.${index}.label`}
          placeholder={labelText}
          label={
            <FormattedMessage
              id="address.edit.type.label"
              defaultMessage="Type"
            />
          }
        />
      </div>
      <div className="w-4/6">
        <InputWithLabel
          onBlur={onBlur}
          name={`phoneNumbers.${index}.phoneNumber`}
          placeholder={numberText}
          label={
            <FormattedMessage
              id="address.edit.number.label"
              defaultMessage="Phone number"
            />
          }
        />
      </div>
      <Button onClick={removeAction} variant={Variant.secondary}>
        <IconRemoveCircleOutline
          height="24"
          title={intl.formatMessage(translationsShared.delete)}
        />
      </Button>
    </div>
  )
}

const PhoneEditSection: React.FC<{ data: AddressFormFields }> = ({ data }) => {
  const intl = useIntl()
  let arrayHelperBinding: FieldArrayRenderProps

  return (
    <div className="mb-10 space-y-5">
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="address.edit.title.phone"
            defaultMessage="Phone"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <FieldArray
              name="phoneNumbers"
              validateOnChange={false}
              render={(arrayHelpers) => {
                arrayHelperBinding = arrayHelpers

                return (
                  <div className="space-y-5">
                    {data.phoneNumbers && data.phoneNumbers.length > 0 ? (
                      data.phoneNumbers.map((number, index) => (
                        <EditFields
                          index={index}
                          key={index}
                          labelText={intl.formatMessage({
                            id: 'address.edit.type.placeholder',
                            defaultMessage: 'Type',
                          })}
                          numberText={intl.formatMessage({
                            id: 'address.edit.number.placeholder',
                            defaultMessage: 'Phone number',
                          })}
                          removeAction={() => arrayHelpers.remove(index)}
                        />
                      ))
                    ) : (
                      <FormattedMessage
                        id="address.edit.empty.phone.label"
                        defaultMessage="No phone numbers"
                      />
                    )}
                  </div>
                )
              }}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
      <SectionListAddButton
        text={intl.formatMessage({
          id: 'address.button.add.phone',
          defaultMessage: 'Add phone number',
        })}
        onClick={() => {
          arrayHelperBinding.push({
            label: intl.formatMessage({
              id: 'address.edit.type.default',
              defaultMessage: 'Mobile',
            }),
            phoneNumber: '',
          })
        }}
      />
    </div>
  )
}

export { PhoneEditSection }
