import { useEffect, useRef, useState } from 'react'

import useDeepCompareEffect from 'use-deep-compare-effect'
import debounce from 'lodash.debounce'
import { DexieError } from 'dexie'
import {
  ItemResponse,
  ListResponse,
  PaginatedData,
  PaginatedResponse,
} from './types'
import { getPaginated, getPaginatedWithPrefixSearch } from './queryUtils'
import { db } from './index'

function attachTableListener(queryAndSetState: () => void, tableName: string) {
  const debouncedQueryAndSetState = debounce(
    () => {
      queryAndSetState()
    },
    500,
    { trailing: true }
  )

  const listener = function (this: any) {
    this.onsuccess = () => {
      debouncedQueryAndSetState()
    }
  }

  db.table(tableName).hook('creating').subscribe(listener)
  db.table(tableName).hook('updating').subscribe(listener)
  db.table(tableName).hook('deleting').subscribe(listener)

  return () => {
    debouncedQueryAndSetState.cancel()
    db.table(tableName).hook('creating').unsubscribe(listener)
    db.table(tableName).hook('updating').unsubscribe(listener)
    db.table(tableName).hook('deleting').unsubscribe(listener)
  }
}

function useGetList<T>(
  tableName: string,
  options: {
    enabled?: boolean
    sortBy?: string
    sortDirection?: 'desc' | 'asc'
    filter?: (item: T) => boolean
  } = {}
): ListResponse<T> {
  const [data, setData] = useState<Array<T> | null>(null)
  const [error, setError] = useState(null)
  const requestIdRef = useRef<number>(0)

  useEffect(() => {
    const currentRequestId = ++requestIdRef.current

    function queryAndSetState() {
      if (options.enabled === false) {
        setData(null)
        setError(null)
        return
      }

      let result = options.sortBy
        ? db.table(tableName).orderBy(options.sortBy)
        : db.table(tableName)

      if (options.sortDirection === 'desc') {
        result = result.reverse()
      }

      if (options.filter) {
        result = result.filter(options.filter)
      }

      result
        .toArray()
        .then((items) => {
          if (currentRequestId === requestIdRef.current) {
            setData(items)
            setError(null)
          }
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [
    tableName,
    options.enabled,
    options.sortBy,
    options.sortDirection,
    options.filter,
  ])

  return { data, error }
}

function useGetById<T>(tableName: string, id: string): ItemResponse<T> {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<Error | DexieError | null>(null)
  const requestIdRef = useRef<number>(0)

  useEffect(() => {
    const currentRequestId = ++requestIdRef.current
    const queryAndSetState = () =>
      db
        .table(tableName)
        .get(id)
        .then((item) => {
          if (currentRequestId === requestIdRef.current) {
            if (item === undefined || item === null) {
              setData(null)
              setError(new Error('Object not found'))
            } else {
              setData(item)
              setError(null)
            }
          }
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [id, tableName])

  return { data, error }
}

function useGetByIds<T>(tableName: string, ids: string[]): ListResponse<T> {
  const [data, setData] = useState<Array<T> | null>(null)
  const [error, setError] = useState(null)
  const requestIdRef = useRef<number>(0)

  useDeepCompareEffect(() => {
    const currentRequestId = ++requestIdRef.current
    const queryAndSetState = () =>
      db
        .table(tableName)
        .bulkGet(ids)
        .then((items) => {
          if (currentRequestId === requestIdRef.current) {
            setData(items.filter((item) => item !== undefined))
            setError(null)
          }
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [ids, tableName])

  return { data, error }
}

function useGetPaginatedList<T>(
  tableName: string,
  search: string,
  page: number,
  size: number,
  options: {
    sortBy?: string
    sortDirection?: 'desc' | 'asc'
    filter?: (item: T) => boolean
  } = {}
): PaginatedResponse<T> {
  const [data, setData] = useState<PaginatedData<T> | null>(null)
  const [error, setError] = useState(null)
  const requestIdRef = useRef<number>(0)

  useEffect(() => {
    const currentRequestId = ++requestIdRef.current

    function queryAndSetState() {
      const getQuery = async (
        search: string
      ): Promise<{ items: Array<T>; count: number }> => {
        if (search === '') {
          return getPaginated(db, tableName, (page - 1) * size, size, {
            sortBy: options.sortBy,
            sortDirection: options.sortDirection,
            filter: options.filter,
          })
        } else {
          return getPaginatedWithPrefixSearch(
            db,
            tableName,
            'searchIndexed',
            search.split(' ').filter((term) => term !== ''),
            (page - 1) * size,
            size,
            {
              sortBy: options.sortBy,
              sortDirection: options.sortDirection,
              filter: options.filter,
            }
          )
        }
      }

      getQuery(search)
        .then(({ items, count }) => {
          // Only update state if this request is the most recent
          if (requestIdRef.current === currentRequestId) {
            setData({
              items,
              total: count,
              page,
              size,
            })
            setError(null)
          }
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [
    tableName,
    search,
    page,
    size,
    options.sortBy,
    options.sortDirection,
    options.filter,
  ])

  return { data, error }
}

export {
  useGetList,
  useGetById,
  useGetByIds,
  attachTableListener,
  useGetPaginatedList,
}
