import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Project } from '../types'
import { useGetAllProjects } from '../db/projects'
import { ChipsArea } from '../../../shared/filter/ChipsArea'
import { ClearChipButton } from '../../../shared/filter/ClearChipButton'
import { ContentArea } from '../../../shared/content'
import { ChipButton } from '../../../shared/filter/ChipButton'
import { projectTypeTranslations } from '../shared/ProjectTypeBadge'
import { getUsedProjectTypes } from './projectTypeFilter'
import { ProjectsOverview } from './ProjectsOverview'

type Props = {
  searching: boolean
  search: string
  page: number
  size: number
}

const ProjectsOverviewContent: React.FC<Props> = ({
  searching,
  search,
  page,
  size,
}) => {
  const intl = useIntl()

  const visibilityFilter = useCallback((item: Project): boolean => {
    return item.options.visibility.projects
  }, [])

  const { data: allProjects } = useGetAllProjects(visibilityFilter)
  const usedProjectTypes = getUsedProjectTypes(allProjects).map((type) => {
    return {
      type: type,
      name: intl.formatMessage(projectTypeTranslations[type]),
    }
  })
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<string[]>([])

  const selectionFilter = useCallback(
    (item: Project) => {
      if (!visibilityFilter(item)) {
        return false
      }

      if (selectedProjectTypes.length === 0) {
        return true
      }
      return selectedProjectTypes.includes(item.type)
    },
    [selectedProjectTypes, visibilityFilter]
  )

  return (
    <div>
      <ChipsArea>
        {usedProjectTypes.map(({ type, name }) => (
          <ChipButton
            key={type}
            onClick={() => {
              if (selectedProjectTypes.includes(type)) {
                setSelectedProjectTypes(
                  selectedProjectTypes.filter(
                    (selectedProjectType) => selectedProjectType !== type
                  )
                )
              } else {
                setSelectedProjectTypes([...selectedProjectTypes, type])
              }
            }}
            selected={selectedProjectTypes.includes(type)}
          >
            {name}
          </ChipButton>
        ))}
        {selectedProjectTypes.length > 0 && (
          <ClearChipButton
            onClick={() => {
              setSelectedProjectTypes([])
            }}
          />
        )}
      </ChipsArea>
      <ContentArea>
        <ProjectsOverview
          searching={searching}
          search={search}
          page={page}
          size={size}
          filter={selectionFilter}
          projectTypes={selectedProjectTypes}
        />
      </ContentArea>
    </div>
  )
}
export { ProjectsOverviewContent }
