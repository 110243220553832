import React from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

export type RoundedButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export type LinkRoundedButtonProps = LinkProps

const FloatingButton = React.forwardRef<HTMLButtonElement, RoundedButtonProps>(
  ({ type = 'button', className, children, ...props }, ref) => {
    return (
      <button
        className={classNames(
          'flex items-center justify-center rounded-full text-black p-4 shadow-lg',
          props.disabled
            ? 'bg-gray-500 hover:bg-gray-300'
            : 'bg-primary-500 hover:bg-primary-300',
          className
        )}
        ref={ref}
        type={type}
        {...props}
      >
        {children}
      </button>
    )
  }
)

const FloatingLinkButton: React.FC<LinkRoundedButtonProps> = ({
  className = '',
  children,
  ...props
}) => {
  return (
    <Link
      className={classNames(
        'flex items-center justify-center rounded-full text-black p-4 shadow-lg',
        'bg-primary-500 hover:bg-primary-300',
        className
      )}
      {...props}
    >
      {children}
    </Link>
  )
}

export { FloatingButton, FloatingLinkButton }
