import { defineMessage } from 'react-intl'
import React, { useState } from 'react'
import { AppLayout } from '../../layouts/app'
import { useSearchAndPaginationQueryUrlParameters } from '../../shared/pagination/routerPagination'
import { translationsAddress } from './translations/translationsAddress'
import AddressOverviewContent from './overview/AddressOverviewContent'
import { AddressoverviewHeader } from './overview/AddressOverviewHeader'

const AddressesOverviewPage: React.FC = () => {
  const { page, size, search } = useSearchAndPaginationQueryUrlParameters()
  const [showSearch, setShowSearch] = useState<boolean>(search !== '')
  const searching = showSearch && search !== ''
  return (
    <AppLayout
      title={{
        messageDescriptor: defineMessage(translationsAddress.overviewTitle),
      }}
    >
      <AddressoverviewHeader
        showSearch={showSearch}
        setShowSearch={setShowSearch}
      />
      <AddressOverviewContent
        searching={searching}
        search={search}
        page={page}
        size={size}
      />
    </AppLayout>
  )
}

export { AddressesOverviewPage }
