import { Project } from '../types'
import { ProjectType } from '../types'

export function getUsedProjectTypes(
  projects: Array<Project> | null
): Array<ProjectType> {
  const usedProjectTypes = new Set<ProjectType>()
  if (!projects) {
    return []
  }
  projects.forEach((project) => {
    usedProjectTypes.add(project.type)
  })

  return Object.values(ProjectType).filter((projectType: ProjectType) => {
    return usedProjectTypes.has(projectType)
  })
}
