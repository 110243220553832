import React from 'react'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import { SelectionInput } from '../../../shared/form/SelectionInput'
import { ActivityFieldState, Category, Group, GroupFieldConfig } from '../types'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityFormFields } from './types'

const ConfigSelect: React.FC<{
  activityGroup: Group
  categories: Array<Category> | null
  formik: FormikProps<ActivityFormFields>
  fieldsConfig: GroupFieldConfig | undefined
}> = ({ activityGroup, categories, formik, fieldsConfig }) => {
  const intl = useIntl()
  const { values, handleChange, setFieldValue } = formik
  const category = categories?.find(
    (category) => category.id === values.activityCategoryId
  )

  const updateDefaultValues = (categoryId: string): void => {
    setFieldValue('activityCategoryId', categoryId)

    const category = categories?.find((category) => category.id === categoryId)

    if (category?.defaultTypeId) {
      setFieldValue('activityTypeId', category?.defaultTypeId)
    } else {
      setFieldValue('activityTypeId', '')
    }

    if (category?.defaultProgressId) {
      let progress = category?.progresses.find((progress) => {
        return progress.id === category.defaultProgressId
      })

      if (progress && progress.activityGroupIds.includes(activityGroup.id)) {
        setFieldValue('activityProgressId', progress.id)
      } else {
        setFieldValue('activityProgressId', '')
      }
    } else {
      setFieldValue('activityProgressId', '')
    }
  }

  return categories ? (
    <>
      {fieldsConfig?.activityCategoryId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="activityCategoryId"
          onChange={(event) => updateDefaultValues(event.target.value)}
          label={intl.formatMessage(translationsActivity.detailHeroCategory)}
          required={fieldsConfig?.activityCategoryId.required}
          disabled={
            fieldsConfig?.activityCategoryId.state ===
            ActivityFieldState.ReadOnly
          }
        >
          <option value="" disabled>
            {intl.formatMessage({
              id: 'activity.form.select.category',
              defaultMessage: 'Select category',
            })}
          </option>
          {categories &&
            categories
              .sort((a, b) => a.sort.localeCompare(b.sort))
              .map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
        </SelectionInput>
      )}
      {fieldsConfig?.activityTypeId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          name="activityTypeId"
          onChange={handleChange}
          label={intl.formatMessage(translationsActivity.detailHeroType)}
          required={fieldsConfig?.activityTypeId.required}
          disabled={
            fieldsConfig?.activityTypeId.state === ActivityFieldState.ReadOnly
          }
        >
          <option value="" disabled>
            {intl.formatMessage({
              id: 'activity.form.select.type',
              defaultMessage: 'Select type',
            })}
          </option>
          {category?.types &&
            category?.types
              .sort((a, b) => a.sort.localeCompare(b.sort))
              .map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
        </SelectionInput>
      )}
      {fieldsConfig?.activityProgressId.state !== ActivityFieldState.Hidden && (
        <SelectionInput
          onChange={handleChange}
          name="activityProgressId"
          label={intl.formatMessage(translationsActivity.detailHeroProgress)}
          required={fieldsConfig?.activityProgressId.required}
          disabled={
            fieldsConfig?.activityProgressId.state ===
            ActivityFieldState.ReadOnly
          }
        >
          <option value="" disabled>
            {intl.formatMessage({
              id: 'activity.form.select.progress',
              defaultMessage: 'Select progress',
            })}
          </option>
          {category?.progresses &&
            category?.progresses
              .filter((progress) =>
                progress.activityGroupIds.includes(activityGroup.id)
              )
              .sort((a, b) => a.sort.localeCompare(b.sort))
              .map((progress) => (
                <option key={progress.id} value={progress.id}>
                  {progress.name}
                </option>
              ))}
        </SelectionInput>
      )}
    </>
  ) : null
}

export { ConfigSelect }
