import React, { ReactNode, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import IconMoreVert from '@aboutbits/react-material-icons/dist/IconMoreVert'
import {
  FloatingArea,
  FloatingButton,
  FloatingLinkButton,
} from '../floatingArea'
import { IconClose } from '../svgs'

export function FloatingMenu({ children }: { children: ReactNode }) {
  const intl = useIntl()
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (expanded) {
      const closeMenu = () => setExpanded(false)
      document.addEventListener('click', closeMenu)
      return () => {
        document.removeEventListener('click', closeMenu)
      }
    }
  }, [expanded, setExpanded])

  return (
    <FloatingArea className={'sm:hidden'}>
      {expanded ? (
        <>
          {children}
          <FloatingButton onClick={() => setExpanded(false)}>
            <IconClose
              className="h-6 w-6 fill-current"
              title={intl.formatMessage({
                id: 'shared.floatingMenu.close',
                defaultMessage: 'Close action menu',
              })}
            />
          </FloatingButton>
        </>
      ) : (
        <FloatingButton onClick={() => setExpanded(true)}>
          <IconMoreVert
            className="h-6 w-6 fill-current"
            title={intl.formatMessage({
              id: 'shared.floatingMenu.open',
              defaultMessage: 'Open action menu',
            })}
          />
        </FloatingButton>
      )}
    </FloatingArea>
  )
}

export function FloatingMenuButtonLink({
  to,
  children,
  className,
}: {
  to: string
  children: ReactNode
  className?: string
}) {
  return (
    <FloatingLinkButton to={to} className={classNames('p-3', className)}>
      {children}
    </FloatingLinkButton>
  )
}
