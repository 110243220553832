import { format } from 'date-fns'

export function replaceUserAndDate(
  input: string | null,
  username: string | null
): string {
  if (input == null) {
    return ''
  }

  const currentDate = format(new Date(), 'dd-MM-yyyy')
  const replacedDate = input.replace(/%date%/g, currentDate)

  if (username != null) {
    return replacedDate.replace(/%user%/g, username)
  } else {
    return replacedDate.replace(/%user%/g, '')
  }
}
