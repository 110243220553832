import { useContext } from 'react'
import { Field, FieldAttributes } from 'formik'
import classNames from 'classnames'
import { RadioGroupContext } from './RadioGroupContext'

const RadioGroupRadio: React.FC<
  FieldAttributes<any> &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({ className, ...props }) => {
  const radioGroupContext = useContext(RadioGroupContext)

  const checked = props.value === radioGroupContext?.value

  const fieldClass = 'absolute opacity-0 w-0 h-0 cursor-pointer peer'
  const checkmarkBaseClass =
    'inline-block shrink-0 h-4 w-4 bg-white rounded-full relative border-2 bottom-px mr-1.5 peer-focus:ring-2 peer-focus:border-primary-500'
  const checkmarkCheckedClass =
    'after:content-[""] after:absolute after:h-2 after:w-2 after:bg-black after:rounded-full after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2'
  const checkmarkClass =
    checkmarkBaseClass + (checked ? ' ' + checkmarkCheckedClass : '')

  return (
    <label className={classNames('flex items-center', className)}>
      <Field
        type="radio"
        name={radioGroupContext?.name}
        className={fieldClass}
        {...props}
      />
      <span className={checkmarkClass}></span>
      <span>{props.label}</span>
    </label>
  )
}

export { RadioGroupRadio }
