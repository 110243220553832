import { v4 as uuid } from 'uuid'
import React, { useState } from 'react'
import { Dialog } from '@reach/dialog'
import { ActivityArticle } from '../../types'
import { AddArticleView } from './steps/AddArticleView'
import { ArticleAmountView } from './steps/ArticleAmountView'

const ArticleDialog: React.FC<{
  isOpen: boolean
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: ActivityArticle) => void
  label: string
  selectableIds: string[]
}> = ({ isOpen, onConfirm, onDismiss, label, selectableIds }) => {
  const [articleId, setArticleId] = useState<string>('')

  const amountStep = (id: string) => {
    setArticleId(id)
  }

  const saveArticle = (amount: string, descriptionText: string) => {
    onConfirm({
      id: uuid(),
      articleId: articleId,
      count: amount,
      description: descriptionText,
    })
    setArticleId('')
  }

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={label}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      {articleId === '' && (
        <AddArticleView
          onDismiss={onDismiss}
          onConfirm={amountStep}
          selectableIds={selectableIds}
        />
      )}
      {articleId !== '' && (
        <ArticleAmountView
          onDismiss={() => setArticleId('')}
          onConfirm={saveArticle}
          articleId={articleId}
        />
      )}
    </Dialog>
  )
}

export { ArticleDialog }
