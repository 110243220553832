import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react'

import { FieldErrorMessage } from './FieldErrorMessage'
import { useAutosizeTextArea } from './useAutosizeTextarea'

const ALLOWED_COMPONENTS = ['input', 'textarea'] as const
const DEFAULT_COMPONENT = 'input' as const

type InputWithLabelProps<
  E extends typeof ALLOWED_COMPONENTS[number] = typeof DEFAULT_COMPONENT
> = ComponentPropsWithoutRef<E> & {
  component?: E
  name: string
  label: ReactNode
} & (
    | {
        component: 'textarea'
        autosize?: boolean
      }
    | {
        component?: Exclude<E, 'textarea'>
        autosize?: never
      }
  )

function InputWithLabel<
  E extends typeof ALLOWED_COMPONENTS[number] = typeof DEFAULT_COMPONENT
>({ label, autosize, children, component, ...props }: InputWithLabelProps<E>) {
  const [field, meta] = useField({ name: props.name })
  const ref = useRef<HTMLElement>(null)

  useAutosizeTextArea(autosize ? ref : null, field.value)

  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-black'

  if (meta.touched && meta.error) {
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (props.disabled) {
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }

  return (
    <div className="flex-1 flex-col">
      <div className={classNames('border-b border-gray-500', borderCss)}>
        <label className="block w-full" htmlFor={props.id ?? props.name}>
          <span className="block text-2xs">{label}</span>
        </label>
        <Field
          id={props.name}
          {...field}
          {...props}
          component={component ?? DEFAULT_COMPONENT}
          innerRef={ref}
          className={classNames(
            'block pl-0 pb-1 pt-0.5 bg-transparent text-black focus:outline-none w-full border-none',
            inputCss,
            autosize ? 'overflow-hidden resize-none' : ''
          )}
        />
      </div>
      <ErrorMessage name={props.name} component={FieldErrorMessage} />
    </div>
  )
}

export { InputWithLabel }
