import React, { ReactNode } from 'react'
import { DialogSelectionListItem } from './DialogSelectionListItem'

type Id = string | number

type Props<T extends Id> = {
  text: string
  adornment?: ReactNode
  itemId: T
  onSelect: (itemId: T) => void
  className?: string
}

function DialogSelectionListItemWithText<T extends Id>({
  text,
  adornment,
  itemId,
  onSelect,
  className,
}: Props<T>) {
  return (
    <DialogSelectionListItem
      itemId={itemId}
      onSelect={onSelect}
      className={className}
    >
      <div className="flex-1 text-left">{text}</div>
      {adornment}
    </DialogSelectionListItem>
  )
}

export { DialogSelectionListItemWithText }
