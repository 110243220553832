import {
  IconContactSupport,
  IconError,
  IconMail,
  IconRecordVoiceOver,
  IconShoppingCart,
  IconAdminPanelSettings,
  IconSupportAgent,
  IconAllInbox,
  IconAddLocationAlt,
  IconMarkUnreadMailbox,
  IconAttachMoney,
  IconDirectionsBus,
} from '../../shared/svgs'
import { IconProps } from '../../shared/svgs/types'
import { AddressTypeIcon } from './types'

export function getIcon(
  addressTypeIcon: AddressTypeIcon | undefined
): React.FC<IconProps> {
  switch (addressTypeIcon) {
    case AddressTypeIcon.Management:
      return IconAdminPanelSettings
    case AddressTypeIcon.ContactPerson:
      return IconRecordVoiceOver
    case AddressTypeIcon.Contact:
      return IconContactSupport
    case AddressTypeIcon.Consultant:
      return IconSupportAgent
    case AddressTypeIcon.Department:
      return IconAllInbox
    case AddressTypeIcon.AdditionalAddress:
      return IconAddLocationAlt
    case AddressTypeIcon.DeliveryAddress:
      return IconMarkUnreadMailbox
    case AddressTypeIcon.BillingAddress:
      return IconAttachMoney
    case AddressTypeIcon.OrderAddress:
      return IconShoppingCart
    case AddressTypeIcon.PECAddress:
      return IconMail
    case AddressTypeIcon.Supplier:
      return IconDirectionsBus
    case AddressTypeIcon.DunningAddress:
      return IconError
    default:
      return IconError
  }
}
