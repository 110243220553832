import React from 'react'
import { CostCenterGroup } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconStore as CostCenterIcon } from '../../../shared/svgs'
import { PaginatedData } from '../../../db/types'
import { PaginationActions } from '../../../shared/pagination/types'
import { CostCenterGroupsSelectionItem } from './CostCenterGroupsSelectionItem'

type Props = {
  title: string
  empty: string
  data: PaginatedData<CostCenterGroup>
  onSelect: (costCenterGroupId: string) => void
} & PaginationActions

const CostCenterGroupsSelectionList: React.FC<Props> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((costCenterGroup, index) => (
          <CostCenterGroupsSelectionItem
            key={index}
            costCenterGroup={costCenterGroup}
            onSelect={onSelect}
          />
        ))}
        {data.items.length === 0 && (
          <Empty icon={CostCenterIcon} text={empty} />
        )}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { CostCenterGroupsSelectionList }
