import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { IconClose } from '../../../shared/svgs'
import { useGetProjectById } from '../db/projects'
import { Error } from '../../../shared/error'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { ProjectEditSection } from './ProjectEditSection'

const ProjectEdit: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { data, error } = useGetProjectById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage
            id="project.edit.title"
            defaultMessage="Edit project"
          />
        </HeaderTitle>
      </HeaderArea>
      {data && (
        <AsyncView
          data={data}
          error={error}
          renderSuccess={(data) => <ProjectEditSection data={data} />}
          renderError={(error) => <Error text={error && error.message} />}
        />
      )}
    </>
  )
}

export { ProjectEdit }
