import { RefObject, useEffect } from 'react'

export function useAutosizeTextArea(
  element: RefObject<HTMLElement> | null,
  value: string
) {
  useEffect(() => {
    const adjustHeight = () => {
      if (element && element.current) {
        const ref = element.current
        ref.style.height = '0px'
        const scrollHeight = ref.scrollHeight
        ref.style.height = scrollHeight + 'px'
      }
    }

    adjustHeight()
    window.addEventListener('resize', adjustHeight)

    return () => {
      window.removeEventListener('resize', adjustHeight)
    }
  }, [element, value])
}
