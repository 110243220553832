import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { IconDelete } from '../svgs'
import { translationsShared } from '../translations/translationsShared'
import { Button, Variant } from './Button'

const DeleteButton: React.FC<{ onClick: () => void; className?: string }> = ({
  onClick,
  className,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={Variant.primaryOutlined}
      className={classNames(
        'flex justify-center md:w-40 border-red-500 text-red-500 hover:bg-red-500 hover:opacity-75 transform ease-in-out duration-75 focus:text-red-500 ',
        className
      )}
    >
      <div className="inline-flex flex-row items-center">
        <IconDelete height="24" />
        <span className="inline-block pl-1">
          <FormattedMessage {...translationsShared.delete} />
        </span>
      </div>
    </Button>
  )
}

export { DeleteButton }
