import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UseSearchQuery, UsePagination, UseSearchAndPagination } from './types'

const convert = (parameter: string | null, fallback: number): number => {
  if (parameter !== null) {
    const converted = parseInt(parameter)

    if (Number.isInteger(converted) && converted > 0) {
      return converted
    }
  }

  return fallback
}

const useSearchQueryUrlParameters = (): UseSearchQuery => {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    search: searchParams.get('search') || '',
    searchActions: {
      search: (query: string) => {
        if (query === '') {
          searchParams.delete('search')
        } else {
          searchParams.set('search', query)
        }
        searchParams.delete('page')
        searchParams.delete('size')

        setSearchParams(searchParams)
      },
      clear: () => {
        searchParams.delete('search')
        searchParams.delete('page')
        searchParams.delete('size')

        setSearchParams(searchParams)
      },
    },
  }
}

const usePaginationQueryUrlParameters = (): UsePagination => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setPage = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  return {
    page: convert(searchParams.get('page'), 1),
    size: convert(searchParams.get('size'), 15),
    paginationActions: {
      setPage,
    },
  }
}

const useSearchAndPaginationQueryUrlParameters = (): UseSearchAndPagination => {
  const searchParameters = useSearchQueryUrlParameters()
  const paginationParameters = usePaginationQueryUrlParameters()

  return {
    ...searchParameters,
    ...paginationParameters,
  }
}

export {
  useSearchQueryUrlParameters,
  usePaginationQueryUrlParameters,
  useSearchAndPaginationQueryUrlParameters,
}
