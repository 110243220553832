import Axios, { AxiosResponse } from 'axios'
import { v4 as uuid } from 'uuid'
import { axiosGet, formatDateForAPI } from '../../shared/utils/apiUtils'
import { Notification } from './types'

const getNotifications = (): Promise<Array<Notification>> =>
  axiosGet('/v1/user/notifications')

const createNotification = async (notification: Notification): Promise<any> => {
  const id = uuid()

  const res = await Axios.put<Record<string, unknown>, AxiosResponse<any>>(
    `/v1/user/notifications/${id}`,
    {
      ...notification,
      id: id,
      createdAt: formatDateForAPI(new Date()),
      updatedAt: formatDateForAPI(new Date()),
    }
  )

  return res.data
}

const saveNotification = async (
  notification: Notification
): Promise<Notification> => {
  const res = await Axios.put<Notification>(
    `/v1/user/notifications/${notification.id}`,
    { ...notification, updatedAt: formatDateForAPI(new Date()) }
  )

  return res.data
}

const deleteNotification = async (id: string): Promise<void> =>
  Axios.delete(`/v1/user/notifications/${id}`)

const archiveNotification = async (
  notification: Notification
): Promise<Notification> => {
  const res = await Axios.put<Notification>(
    `/v1/user/notifications/${notification.id}/archive`,
    notification
  )

  return res.data
}

export {
  getNotifications,
  createNotification,
  saveNotification,
  deleteNotification,
  archiveNotification,
}
