import React, { ComponentType } from 'react'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { IconProps } from '../../shared/svgs/types'
import { IconArrowRightAlt, IconLock } from '../../shared/svgs'

type PropType = {
  active: boolean
  title: string
  to: string
  Icon: ComponentType<IconProps>
}

const HomeSection: React.FC<PropType> = ({ active, Icon, title, to }) => {
  const intl = useIntl()

  return (
    <NavLink to={active ? to : ''}>
      <div
        className={`mx-4 my-2 flex-1 rounded-lg ${
          active ? 'bg-primary-500' : 'bg-gray-300'
        }`}
      >
        <div className="flex justify-between">
          <div className="py-6 px-5">
            <div
              className={`flex h-16 w-16 items-center justify-center rounded-full ${
                active ? 'bg-white' : 'bg-primary-500'
              }`}
            >
              <Icon height="50" />
            </div>
            <div className="mt-5">
              <h1 className="text-xl">{title}</h1>
            </div>
          </div>
          <div className="relative mt-5 mr-3">
            {!active ? (
              <div className="absolute top-0 right-0 inline-flex items-center rounded-full bg-primary-500 py-1 px-4">
                <IconLock height="20" />
                <span className="ml-2 text-sm">
                  {intl.formatMessage({
                    id: 'home.nav.enable',
                    defaultMessage: 'Enable',
                  })}
                </span>
              </div>
            ) : (
              <div />
            )}
            <IconArrowRightAlt
              height="40"
              className="absolute right-0 bottom-0 mb-2"
            />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export { HomeSection }
