import React from 'react'
import { Form, Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { IconClose } from '../../../../../shared/svgs'
import { ContentArea } from '../../../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { InputWithLabel } from '../../../../../shared/form'
import { SectionActions } from '../../../../../shared/section/SectionActions'
import { CancelButton } from '../../../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../../../shared/form/button/SubmitButton'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ButtonVariant } from '../../../../../shared/button'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { translationsActivity } from '../../../translations/translationsActivity'
import { useGetArticleById } from '../../../db/article'

const ArticleAmountView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (amount: string, descriptionText: string) => void
  amount?: string
  descriptionText?: string
  articleId: string
}> = ({
  onConfirm,
  onDismiss,
  amount = '1',
  descriptionText = '',
  articleId,
}) => {
  const intl = useIntl()
  const { data: article } = useGetArticleById(articleId)

  const initialValue: {
    count: string
    description: string
  } = {
    count: amount,
    description: descriptionText ?? '',
  }

  const schema = Yup.object().shape({
    count: Yup.string().required(
      intl.formatMessage(translationsShared.validationRequired)
    ),
  })

  return (
    <>
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={onDismiss}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="h-7 w-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">
          <FormattedMessage {...translationsActivity.articleAmountTitle} />
        </DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Formik
          initialValues={initialValue}
          onSubmit={(values) => {
            onConfirm(values.count, values.description)
          }}
          validationSchema={schema}
          validateOnChange={true}
        >
          <Form>
            <Section>
              <SectionTitle>{article ? article.name : ''}</SectionTitle>
              <SectionList>
                <SectionItemForm>
                  <InputWithLabel
                    id="count"
                    name="count"
                    type="string"
                    label={intl.formatMessage({
                      id: 'activity.article.amount',
                      defaultMessage: 'Amount',
                    })}
                  />
                  <InputWithLabel
                    id="description"
                    name="description"
                    type="string"
                    label={intl.formatMessage({
                      id: 'activity.article.description',
                      defaultMessage: 'Description',
                    })}
                  />
                </SectionItemForm>
              </SectionList>
            </Section>
            <SectionActions className="py-5 md:py-5">
              <CancelButton
                variant={ButtonVariant.primaryLightOutlined}
                onClick={onDismiss}
              />
              <SubmitButton variant={ButtonVariant.primaryLight} />
            </SectionActions>
          </Form>
        </Formik>
      </ContentArea>
    </>
  )
}

export { ArticleAmountView }
