import React from 'react'
import classnames from 'classnames'

const DialogTitle: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <h1
      className={classnames(
        className,
        'my-5 flex flex-1 items-end text-3xl md:my-0 md:text-2xl lg:min-h-0'
      )}
    >
      {children}
    </h1>
  )
}

export { DialogTitle }
