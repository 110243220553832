import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { AddressEdit } from './edit/AddressEdit'
import { translationsAddress } from './translations/translationsAddress'

const AddressEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsAddress.editTitle }}>
      <AddressEdit id={id as string} />
    </AppLayout>
  )
}

export { AddressEditPage }
