import React from 'react'
import classnames from 'classnames'

const SectionActions: React.FC<{
  className?: string
}> = ({ className, children }) => {
  return (
    <div
      className={classnames(
        'flex flex-col-reverse justify-center space-y-4 space-y-reverse px-3 md:flex-row md:justify-end md:space-y-0 md:space-x-4 md:px-0',
        className
      )}
    >
      {children}
    </div>
  )
}

export { SectionActions }
