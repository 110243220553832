import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '../svgs'
import { translationsShared } from '../translations/translationsShared'

const SectionPaginationContainer: React.FC = ({ children }) => (
  <div className="flex justify-between py-5 px-3 md:rounded-lg md:p-5">
    {children}
  </div>
)

const SectionPaginationPreviousContent: React.FC = () => (
  <>
    <IconKeyboardArrowLeft className="inline-block h-6 w-6 fill-current" />
    <span className="hidden md:block">
      <FormattedMessage {...translationsShared.paginationPrevious} />
    </span>
  </>
)

const SectionPaginationNextContent: React.FC = () => (
  <>
    <span className="hidden md:block">
      <FormattedMessage {...translationsShared.paginationNext} />
    </span>
    <IconKeyboardArrowRight className="inline-block h-6 w-6 fill-current" />
  </>
)

const SectionPaginationPagesList: React.FC = ({ children }) => (
  <ul className="flex items-center space-x-4">{children}</ul>
)

const SectionPaginationPagesListItem: React.FC = ({ children }) => (
  <li>{children}</li>
)

export {
  SectionPaginationContainer,
  SectionPaginationPreviousContent,
  SectionPaginationNextContent,
  SectionPaginationPagesList,
  SectionPaginationPagesListItem,
}
