import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { NOTIFICATION_TYPES_FIELDS, NotificationType } from '../types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { NotificationAssignmentType } from './types'

export const useGetValidationSchema = () => {
  const intl = useIntl()

  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )

  return Yup.object().shape({
    type: Yup.string().required(requiredField),
    message: Yup.string()
      .max(
        1000,
        intl.formatMessage({
          id: 'notification.form.validation.message',
          defaultMessage: 'The message must not be longer than 255 characters',
        })
      )
      .nullable(),
    time: Yup.date()
      .nullable()
      .when('type', {
        is: (type: NotificationType) =>
          NOTIFICATION_TYPES_FIELDS[type]?.fields.includes('time'),
        then: (schema) => schema.required(requiredField),
      }),
    assignmentType: Yup.string().oneOf(
      Object.values(NotificationAssignmentType)
    ),
    addressGroupId: Yup.string().when('assignmentType', {
      is: NotificationAssignmentType.addressGroup,
      then: (schema) => schema.required(requiredField),
    }),
    userId: Yup.string().when('assignmentType', {
      is: NotificationAssignmentType.user,
      then: (schema) => schema.required(requiredField),
    }),
  })
}
