import { AxiosRequestConfig } from 'axios'
import { Language } from '../domain/user/types'

export enum Module {
  timeTacking = 'time-tracking',
  addresses = 'addresses',
  activities = 'activities',
}

export enum Permission {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
}

export type User = {
  id: string
  name: string
  email: string
  language: Language
  username: string
}

export type ModuleAccessControl = {
  module: Module
  permissions: {
    [Permission.create]: boolean
    [Permission.edit]: boolean
    [Permission.delete]: boolean
  }
}

export type Me = {
  user: User
  modules: ModuleAccessControl[]
}

export type RetryQueueItem = {
  resolve: (value?: any) => void
  reject: (error?: any) => void
  config: AxiosRequestConfig
}
