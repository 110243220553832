import React from 'react'
import { defineMessage } from 'react-intl'
import { AppLayout } from '../../layouts/app'
import { TimeTrackingOverview } from './overview/TimeTrackingOverview'
import { translationsTimetracking } from './translations/translationsTimeTracking'

const TimeTrackingOverviewPage: React.FC = () => (
  <AppLayout
    title={{
      messageDescriptor: defineMessage(translationsTimetracking.overviewTitle),
    }}
  >
    <TimeTrackingOverview />
  </AppLayout>
)

export { TimeTrackingOverviewPage }
