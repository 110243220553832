import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { IconFavorite, IconFavoriteBorder } from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { overrideCostCenter } from '../db/costCenters'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { SectionItemAction } from '../../../shared/section'
import { CostCenter } from '../types'
import { AppContext } from '../../../app/AppContext'

export const CostCenterFavoriteAction: React.FC<{
  costCenter: CostCenter
}> = ({ costCenter }) => {
  const intl = useIntl()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { addAlertErrorMessage } = useContext(AppContext)

  return (
    <SectionItemAction
      icon={costCenter.favorite ? IconFavorite : IconFavoriteBorder}
      label={
        costCenter.favorite
          ? intl.formatMessage({
              id: 'costCenter.favorite.unmark',
              defaultMessage: 'Unmark as favorite',
            })
          : intl.formatMessage({
              id: 'costCenter.favorite.mark',
              defaultMessage: 'Mark as favorite',
            })
      }
      disabled={disabled}
      onClick={async () => {
        setDisabled(true)
        try {
          const updatedCostCenter = await (costCenter.favorite
            ? unmarkAsFavorite(costCenter.id)
            : markAsFavorite(costCenter.id))

          await overrideCostCenter(updatedCostCenter)
        } catch (error: any) {
          addAlertErrorMessage(extractErrorMessage(error))
        } finally {
          setDisabled(false)
        }
      }}
    />
  )
}
