import React from 'react'
import { Link } from 'react-router-dom'

const SectionListLink: React.FC<{ to: string }> = ({ to, children }) => {
  return (
    <div className="mt-10 flex justify-center">
      <Link to={to} className="inline-flex items-center justify-center">
        {children}
      </Link>
    </div>
  )
}

export { SectionListLink }
