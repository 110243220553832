import React from 'react'
import { Dialog } from '@reach/dialog'
import { useIntl } from 'react-intl'

import { CostCentersSelection } from '../../costCenter/selection/CostCentersSelection'
import { DismissType } from '../../../shared/dialog/types'

type Props = {
  costCenterGroupId: string | null
  isOpen: boolean
  dismissType?: DismissType
  onConfirm: (value: string) => void
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
}

const SelectCostCenterDialog: React.FC<Props> = ({
  costCenterGroupId,
  isOpen,
  dismissType = DismissType.close,
  onDismiss,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={dismissType === DismissType.close ? onDismiss : undefined}
      aria-label={intl.formatMessage({
        id: 'timeTracking.select.costCenter.title',
        defaultMessage: 'Select cost center',
      })}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <CostCentersSelection
        costCenterGroupId={costCenterGroupId}
        dismissType={dismissType}
        onDismiss={onDismiss}
        {...props}
      />
    </Dialog>
  )
}

export { SelectCostCenterDialog }
