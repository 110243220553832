import React from 'react'

import { TimeTrackingRecord, RecordType } from '../types'
import {
  TimeTrackingWidgetActionStart,
  TimeTrackingWidgetActionStop,
  TimeTrackingWidgetActionPause,
  TimeTrackingWidgetActionProjectCostCenter,
  Variant,
} from './TimeTrackingWidgetAction'

type WidgetProps = {
  lastRecordType: RecordType
}

const TimeTrackingWidget: React.FC<{ data: TimeTrackingRecord[] }> = ({
  data,
}) => {
  let lastRecordType = RecordType.WORK_STOP

  if (data.length > 0) {
    const lastRecord = data.sort((a, b) => b.time.localeCompare(a.time))[0]
    lastRecordType = lastRecord.type
  }

  return (
    <div>
      <TimeTrackingWidgetDesktop lastRecordType={lastRecordType} />
      <TimeTrackingWidgetMobile lastRecordType={lastRecordType} />
    </div>
  )
}

const TimeTrackingWidgetDesktop: React.FC<WidgetProps> = ({
  lastRecordType,
}) => {
  return (
    <div className="hidden flex-row justify-between md:flex md:space-x-1 lg:space-x-4">
      {lastRecordType === RecordType.WORK_STOP ||
      lastRecordType === RecordType.WORK_PAUSE ? (
        <TimeTrackingWidgetActionStart />
      ) : (
        <>
          <TimeTrackingWidgetActionStop />
          <TimeTrackingWidgetActionPause />
          <TimeTrackingWidgetActionProjectCostCenter />
        </>
      )}
    </div>
  )
}

const TimeTrackingWidgetMobile: React.FC<WidgetProps> = ({
  lastRecordType,
}) => {
  return (
    <div className="block rounded-b-lg bg-gray-700 md:hidden">
      <div className="flex flex-row justify-center py-1">
        {lastRecordType === RecordType.WORK_STOP ||
        lastRecordType === RecordType.WORK_PAUSE ? (
          <TimeTrackingWidgetActionStart />
        ) : (
          <TimeTrackingWidgetActionProjectCostCenter />
        )}
      </div>
      {lastRecordType === RecordType.WORK_STOP ||
      lastRecordType === RecordType.WORK_PAUSE ? null : (
        <div className="flex flex-row divide-x divide-gray-600 border-t border-gray-600">
          <TimeTrackingWidgetActionStop variant={Variant.inline} />
          <TimeTrackingWidgetActionPause variant={Variant.inline} />
        </div>
      )}
    </div>
  )
}

export { TimeTrackingWidget }
