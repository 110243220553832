import { Html5Qrcode } from 'html5-qrcode'
import React, { useEffect } from 'react'

type QRCodeProps = {
  onSuccess: (text: string) => void
  onError: (message: string) => void
  className?: string
}

const QRCode: React.FC<QRCodeProps> = ({ onSuccess, onError, className }) => {
  useEffect(() => {
    const config = { fps: 10, qrbox: { width: 250, height: 250 } }

    const html5Qrcode = new Html5Qrcode('qr-code-scanner', {
      experimentalFeatures: undefined,
      formatsToSupport: undefined,
      verbose: undefined,
      useBarCodeDetectorIfSupported: true,
    })

    html5Qrcode
      .start(
        { facingMode: 'environment' },
        config,
        (text) => {
          onSuccess(text)
        },
        () => {
          html5Qrcode.resume()
        }
      )
      .catch((e) => {
        onError(e)
      })

    return () => {
      if (html5Qrcode.isScanning) {
        html5Qrcode.stop().catch((error) => {
          console.error('Failed to stop html5Qrcode: ', error)
        })
      }
    }
  }, [onError, onSuccess])

  return <div id="qr-code-scanner" className={className} />
}

export { QRCode }
