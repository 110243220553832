import React, { useState } from 'react'
import ReachAlert from '@reach/alert'
import classNames from 'classnames'
import { useInterval } from '../../packages/reactUseInterval'
import { IconClose, IconWarning } from '../svgs'

const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000

export type Alert = {
  id: string
  type: AlertType
  message: string
}

export enum AlertType {
  ERROR,
}

export const AlertMessage: React.FC<{
  alert: Alert
  onRemove: () => void
}> = ({ alert, onRemove }) => {
  const [delay, setDelay] = useState<number | null>(TEN_SECONDS_IN_MILLISECONDS)

  const remove = () => {
    setDelay(null)
    onRemove()
  }

  useInterval(() => {
    remove()
  }, delay)

  const colors = alert.type === AlertType.ERROR ? 'bg-red-50 text-red-500' : ''
  const Icon = alert.type === AlertType.ERROR ? IconWarning : null

  return (
    <ReachAlert
      className={classNames(
        'p-3 rounded-md flex items-stretch text-sm',
        colors
      )}
    >
      {Icon !== null && (
        <div className="mr-2">
          <Icon className="h-5 w-5 fill-current" />
        </div>
      )}
      <p className="max-w-4xl flex-1 leading-relaxed">{alert.message}</p>
      <div className="ml-2">
        <button onClick={remove}>
          <IconClose className="h-5 w-5 fill-current" />
        </button>
      </div>
    </ReachAlert>
  )
}
