import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { HorizontalSpacer } from '../spacing'
import { useMenuToggle } from '../../layouts/app/MenuContext'
import { IconMenu } from '../svgs'
import { HeaderLargeAction } from './HeaderLargeAction'
import { HeaderLeftArea } from './index'

const HeaderArea: React.FC<{
  navigation?: ReactNode
}> = ({ navigation = null, children }) => {
  const intl = useIntl()
  const menuToggle = useMenuToggle()

  return (
    <HorizontalSpacer>
      <div className="flex items-center">
        {navigation === null ? (
          <HeaderLeftArea className="block md:hidden">
            <HeaderLargeAction
              icon={IconMenu}
              label={intl.formatMessage({
                id: 'app.nav.menu',
                defaultMessage: 'Menu',
              })}
              onClick={menuToggle}
            />
          </HeaderLeftArea>
        ) : (
          navigation
        )}
        {children}
      </div>
    </HorizontalSpacer>
  )
}

export { HeaderArea }
