import { MessageDescriptor, defineMessages } from 'react-intl'
import { NotificationType } from '../types'
export const translationsNotification = defineMessages({
  resourceAdd: {
    id: 'notification.resource.add',
    defaultMessage: 'Add Notification',
  },
  addTitle: {
    id: 'notification.add.title',
    defaultMessage: 'Add Notification',
  },
  archive: {
    id: 'notification.archive',
    defaultMessage: 'Archive',
  },
  typesDueDate: {
    id: 'notification.types.due_date',
    defaultMessage: 'Due Date',
  },
  typesEmail: {
    id: 'notification.types.email',
    defaultMessage: 'Email',
  },
  typesEmployee: {
    id: 'notification.types.employee',
    defaultMessage: 'Employee',
  },
  typesIsResponsible: {
    id: 'notification.types.is_responsible',
    defaultMessage: 'Is Responsible',
  },
  typesReminder: {
    id: 'notification.types.reminder',
    defaultMessage: 'Reminder',
  },
  typesDefault: {
    id: 'notification.types.default',
    defaultMessage: 'Default',
  },
})
export function translationsNotificationType(
  notificationType: NotificationType
): MessageDescriptor {
  switch (notificationType) {
    case NotificationType.due_date:
      return translationsNotification.typesDueDate
    case NotificationType.email:
      return translationsNotification.typesEmail
    case NotificationType.employee:
      return translationsNotification.typesEmployee
    case NotificationType.is_responsible:
      return translationsNotification.typesIsResponsible
    case NotificationType.reminder:
      return translationsNotification.typesReminder
    default:
      return notificationType
  }
}
export function translationsNotificationTypeString(
  notificationType: string
): MessageDescriptor {
  switch (notificationType) {
    case 'due_date':
      return translationsNotification.typesDueDate
    case 'email':
      return translationsNotification.typesEmail
    case 'employee':
      return translationsNotification.typesEmployee
    case 'is_responsible':
      return translationsNotification.typesIsResponsible
    case 'reminder':
      return translationsNotification.typesReminder
    default:
      return translationsNotification.typesDefault
  }
}
