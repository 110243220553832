import React from 'react'
import { PaginatedData } from '../../../../db/types'
import { PaginationActions } from '../../../../shared/pagination/types'
import { Address } from '../../../address/types'
import {
  Section,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../../shared/section'
import { Empty } from '../../../../shared/empty'
import { IconStore } from '../../../../shared/svgs'
import { AddressDialogItem } from './AddressDialogItem'

type AddressDialogListProps = {
  title: string
  empty: string
  data: PaginatedData<Address>
  onSelect: (addressId: string) => void
} & PaginationActions

const AddressDialogList: React.FC<AddressDialogListProps> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((address) => (
          <AddressDialogItem
            address={address}
            key={address.id}
            onSelect={onSelect}
          />
        ))}
        {data.items.length === 0 && <Empty icon={IconStore} text={empty} />}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { AddressDialogList }
