import { useCallback } from 'react'
import {
  useGetActivityById,
  useGetActivityCategories,
  useGetActivityGroups,
} from './db/activities'
import { findActivityGroup } from './form/activityGroupUtils'
import { Category, Group } from './types'

export const useGetActivityLink = (id: string) => {
  const { data: activity } = useGetActivityById(id)
  const { data: activityGroups } = useGetActivityGroups()

  if (activity == null) return null

  const activityGroup = findActivityGroup(activity, activityGroups)

  if (activityGroup == null) return null

  return `/activity-groups/${activityGroup.id}/activities/${activity.id}`
}

export const useGetFilteredCategoriesForActivityGroup = (
  activityGroup: Group
) => {
  const filterCategoriesForActivityGroup = useCallback(
    (item: Category) => {
      return (
        activityGroup.categoryIds !== undefined &&
        activityGroup.categoryIds.includes(item.id)
      )
    },
    [activityGroup.categoryIds]
  )

  const { data: categories } = useGetActivityCategories(
    filterCategoriesForActivityGroup
  )

  return categories
}
