import { AsyncView } from '@aboutbits/react-toolbox'
import React from 'react'
import { useIntl } from 'react-intl'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { useGetActivities } from '../db/activities'
import { Activity, Group, GroupListView } from '../types'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityOverviewViewList } from './ActivityOverviewViewList'
import { ActivityOverviewViewClusteredByStartDate } from './ActivityOverviewViewClusteredByStartDate'

type Props = {
  searching: boolean
  search: string
  page: number
  size: number
  activityGroup: Group
  filter?: (item: Activity) => boolean
}

const ActivityOverviewViewStage: React.FC<Props> = ({
  searching,
  search,
  page,
  size,
  activityGroup,
  filter,
}) => {
  const intl = useIntl()
  const { data: activities } = useGetActivities(search, page, size, {
    filter,
    sortBy:
      activityGroup?.listView === GroupListView.clusterByStartDate
        ? 'startDateIndexed'
        : 'sortIndexed',
    sortDirection: activityGroup.listSortDirection,
  })

  const title = searching
    ? intl.formatMessage({
        id: 'activity.overview.search.result',
        defaultMessage: 'Search result',
      })
    : intl.formatMessage({
        id: 'activity.overview.list',
        defaultMessage: 'All activities',
      })

  const empty = searching
    ? intl.formatMessage({
        id: 'activity.overview.search.empty',
        defaultMessage: 'No activities found',
      })
    : intl.formatMessage(translationsActivity.overviewListEmpty)

  return (
    <AsyncView
      data={activityGroup && activities}
      renderLoading={<SectionListLoading title={title} />}
      renderSuccess={
        activityGroup &&
        activities &&
        (activityGroup.listView === GroupListView.clusterByStartDate ? (
          <ActivityOverviewViewClusteredByStartDate
            empty={empty}
            data={activities}
            activityGroup={activityGroup}
          />
        ) : (
          <ActivityOverviewViewList
            title={title}
            empty={empty}
            data={activities}
            activityGroup={activityGroup}
          />
        ))
      }
    />
  )
}

export { ActivityOverviewViewStage }
