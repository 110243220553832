import { useIntl, FormattedMessage } from 'react-intl'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSearch,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { IconSearch } from '../../../shared/svgs'
import { translationsProject } from '../translations/translationsProject'

type Props = {
  showSearch: boolean
  setShowSearch: (showSearch: boolean) => void
}
const ProjectOverviewHeader: React.FC<Props> = ({
  showSearch,
  setShowSearch,
}) => {
  const intl = useIntl()
  const { search, searchActions } = useSearchAndPaginationQueryUrlParameters()
  const startSearch = (): void => {
    setShowSearch(true)
  }
  const stopSearch = (): void => {
    setShowSearch(false)
    searchActions.clear()
  }
  return (
    <>
      {showSearch ? (
        <HeaderSearch
          text={search}
          setText={searchActions.search}
          stopSearch={stopSearch}
          clearSearch={searchActions.clear}
        />
      ) : (
        <HeaderArea>
          <HeaderTitle>
            <FormattedMessage {...translationsProject.overviewTitle} />
          </HeaderTitle>
          <HeaderRightArea>
            <HeaderSmallAction
              icon={IconSearch}
              label={intl.formatMessage(
                translationsProject.overviewSearchStart
              )}
              onClick={startSearch}
            />
          </HeaderRightArea>
        </HeaderArea>
      )}
    </>
  )
}
export { ProjectOverviewHeader }
