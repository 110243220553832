import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from 'react-intl'
import { AxiosError } from 'axios'
import { FormGroup, Input } from '../../../shared/form'
import { getMe, logIn } from '../../../auth/api'
import {
  FormErrorBody,
  joinFieldErrorMessages,
} from '../../../shared/utils/apiUtils'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { IconLock, IconPerson } from '../../../shared/svgs'
import { ErrorMessage } from '../../../shared/messages'
import { translationsShared } from '../../../shared/translations/translationsShared'

const Login: React.FC = () => {
  const intl = useIntl()
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null)

  const initialValues = {
    username: '',
    password: '',
  }

  const schema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage(translationsShared.validationRequired)
    ),
    password: Yup.string().required(
      intl.formatMessage(translationsShared.validationRequired)
    ),
  })

  const onSuccess = (): void => {
    window.location.pathname = '/'
  }

  const onSubmit = async (
    values: { username: string; password: string },
    { setSubmitting, setErrors }: any
  ): Promise<void> => {
    try {
      setApiErrorMessage(null)
      //In order to always have a valid CSRF token, we make a request to the "me" endpoint before doing the login.
      try {
        await getMe()
      } catch (e) {}
      await logIn(values)
      onSuccess()
    } catch (error: any) {
      const { response }: AxiosError<FormErrorBody> = error
      if (response) {
        setErrors(joinFieldErrorMessages(response.data.errors))
        setApiErrorMessage(response.data.message || null)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <FormGroup>
          <Input
            autoCapitalize="none"
            icon={IconPerson}
            name="username"
            type="username"
            placeholder={intl.formatMessage(translationsShared.username)}
          />
        </FormGroup>
        <FormGroup>
          <Input
            icon={IconLock}
            name="password"
            type="password"
            placeholder={intl.formatMessage(translationsShared.password)}
          />
        </FormGroup>
        <SubmitButton className="mt-6 w-full">
          <FormattedMessage id="guest.login.btn" defaultMessage="Login" />
        </SubmitButton>
        {apiErrorMessage && (
          <ErrorMessage className="mt-10">{apiErrorMessage}</ErrorMessage>
        )}
      </Form>
    </Formik>
  )
}

export { Login }
