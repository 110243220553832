import React from 'react'
import { useIntl } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { SectionListVariant } from '../../../shared/section/SectionList'
import { CostCenter } from '../types'
import { CostCentersSelectionItem } from './CostCentersSelectionItem'

export const CostCentersSelectionListFavorites: React.FC<{
  data: CostCenter[]
  onSelect: (costCenterId: string) => void
}> = ({ data, onSelect }) => {
  const intl = useIntl()

  return data.length > 0 ? (
    <Section>
      <SectionTitle>
        {intl.formatMessage({
          id: 'costCenter.overview.list.favorites',
          defaultMessage: 'Favorites',
        })}
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {data.map((costCenter, index) => (
          <CostCentersSelectionItem
            key={index}
            costCenter={costCenter}
            onSelect={onSelect}
          />
        ))}
      </SectionList>
    </Section>
  ) : null
}
