import { FileReference } from '../file/types'

export type Address = {
  id: string
  name: string
  label: string
  search: string | null
  sort: string | null
  phoneNumbers: Array<AddressPhoneNumber>
  emailAddresses: Array<AddressEmailAddress>
  address: PhysicalAddress
  parentId: string | null
  favorite: boolean
  files: Array<FileReference>
  addressTypeId: string
}

export type PhysicalAddress = {
  street: string
  city: string
  cap: string
  country: string
  latitude: number
  longitude: number
}

export type AddressPhoneNumber = {
  label: string
  phoneNumber: string
}

export type AddressEmailAddress = {
  label: string
  emailAddress: string
}

export type AddressGroup = {
  id: string
  name: string
  notifications: boolean
}
export type AddressType = {
  id: string
  name: string
  sort?: string
  default: boolean
  icon: AddressTypeIcon
  visibility: {
    activities: boolean
  }
}

export enum AddressTypeIcon {
  Management = 'management',
  ContactPerson = 'contact_person',
  Contact = 'contact',
  Consultant = 'consultant',
  Department = 'department',
  AdditionalAddress = 'additional_address',
  DeliveryAddress = 'delivery_address',
  BillingAddress = 'billing_address',
  OrderAddress = 'order_address',
  PECAddress = 'pec_address',
  Supplier = 'supplier',
  DunningAddress = 'dunning_address',
}
