import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { IconClose } from '../../../../../shared/svgs'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { ContentArea } from '../../../../../shared/content'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { QRCode } from '../../../../../shared/qrcode'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { translationsActivity } from '../../../translations/translationsActivity'

const QrCodeView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onError: (error: string) => void
  onScan: (result: string) => void
}> = ({ onDismiss, onScan, onError }) => {
  const intl = useIntl()

  return (
    <>
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={onDismiss}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="h-7 w-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">
          <FormattedMessage {...translationsActivity.articleSearchStart} />
        </DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Section>
          <SectionTitle>
            <FormattedMessage {...translationsActivity.articleQRTitle} />
          </SectionTitle>
          <SectionList>
            <SectionItem>
              <QRCode
                onSuccess={(text) => {
                  onScan(text)
                }}
                onError={(message) => {
                  onError(message)
                }}
                className="flex-1"
              />
            </SectionItem>
          </SectionList>
        </Section>
      </ContentArea>
    </>
  )
}

export { QrCodeView }
