import Dexie from 'dexie'
import { Module, ModuleAccessControl } from '../../auth/types'

function diffObject<T extends Record<string, unknown>>(
  base: Array<T>,
  to: Array<T>,
  searchBy: string
): Array<T> {
  return base.filter((bd) => !to.some((td) => bd[searchBy] === td[searchBy]))
}

async function updateDatabase<T extends Record<string, unknown>>(
  db: Dexie,
  table: string,
  data: Array<T>,
  key = 'id'
) {
  await db.table(table).bulkPut(data)

  const dbData = await db.table(table).toArray()
  const toDelete = diffObject(dbData, data, key).map((d) => d[key])

  await db.table(table).bulkDelete(toDelete)
}

function checkSyncPermissions(
  modules: Array<ModuleAccessControl>,
  checkFor: Module
): boolean {
  return modules.some((m) => m.module === checkFor)
}

export { diffObject, updateDatabase, checkSyncPermissions }
