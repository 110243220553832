import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconWarning } from '../svgs'
import { Section, SectionList, SectionTitle } from '../section'
import { translationsShared } from '../translations/translationsShared'

const Error: React.FC<{ text: string | null }> = ({ text }) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage {...translationsShared.errorTitle} />
      </SectionTitle>
      <SectionList>
        <div className="flex flex-col items-center justify-center bg-gray-300 p-12 md:bg-transparent md:p-16">
          <div className="rounded-full bg-gray-500 p-6 text-gray-300 md:p-8">
            <IconWarning className="h-8 w-8 fill-current md:h-10 md:w-10" />
          </div>
          <p className="mt-4 text-center text-md font-medium leading-normal text-gray-500 md:mt-6 md:text-xl">
            {text}
          </p>
        </div>
      </SectionList>
    </Section>
  )
}

export { Error }
