import { useFormikContext } from 'formik'
import { Blocker, History, Transition } from 'history'
import {
  ContextType,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { LeaveDirtyFormDialog } from '../dialog/LeaveDirtyFormDialog'

// This is a temporary workaround since react router v6 does not provide a way to block requests
// see https://github.com/remix-run/react-router/issues/8139

interface NavigatorWithBlock extends Navigator {
  block: History['block']
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: NavigatorWithBlock
}

// source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(
    NavigationContext
  ) as NavigationContextWithBlock

  useEffect(() => {
    if (!when) {
      return
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

export function LeaveDirtyFormPrompt(): ReactElement {
  const formikContext = useFormikContext()
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const blockedTx = useRef<Transition | null>(null)

  const blocker = useCallback((tx: Transition) => {
    setDialogVisible(true)
    blockedTx.current = tx
  }, [])

  useBlocker(blocker, formikContext.dirty)

  return (
    <LeaveDirtyFormDialog
      isOpen={dialogVisible}
      onDismiss={() => setDialogVisible(false)}
      onConfirm={() => blockedTx.current?.retry()}
    />
  )
}
