import React from 'react'
import { Link } from 'react-router-dom'
import { Project } from '../types'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { ProjectFavoriteAction } from '../shared/ProjectFavoriteAction'
import { ProjectTypeBadge } from '../shared/ProjectTypeBadge'

const ProjectsOverviewItem: React.FC<{
  project: Project
}> = ({ project }) => {
  return (
    <Link to={`/projects/${project.id}`} key={project.id} className="block">
      <SectionItem>
        <ProjectFavoriteAction project={project} />
        <div className="flex-1">{project.name}</div>
        <ProjectTypeBadge projectType={project.type} />
        <div>
          <IconKeyboardArrowRight className="h-6 w-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { ProjectsOverviewItem }
