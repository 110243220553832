import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'

import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { useGetTimeTrackingRecordById } from '../db/records'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { TimeTrackingEditForm } from './TimeTrackingEditForm'
import { TimeTrackingLoading } from './TimeTrackingLoading'

const TimeTrackingEdit: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const onBack = (): void => {
    navigate(-1)
  }

  const { data, error } = useGetTimeTrackingRecordById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={onBack}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage
            id="timeTracking.edit.title"
            defaultMessage="Edit time tracking"
          />
        </HeaderTitle>
      </HeaderArea>

      <AsyncView
        data={data}
        error={error}
        renderLoading={<TimeTrackingLoading />}
        renderSuccess={(data) => data && <TimeTrackingEditForm data={data} />}
        renderError={(error) => <Error text={error && error.message} />}
      />
    </>
  )
}

export { TimeTrackingEdit }
