import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InputWithLabel } from '../../../shared/form'
import { FileReference } from '../types'
import { IconDelete } from '../../../shared/svgs'
import { SectionItem } from '../../../shared/section'
import { FilePreview } from '../shared/FilePreview'
import { BACKEND_API } from '../../../config/env'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { useGetFileById } from '../db/files'

const FileFormItem: React.FC<{
  file: FileReference
  onDelete: () => void
  name: string
  canDelete: boolean
}> = ({ file, name, onDelete, canDelete }) => {
  const intl = useIntl()
  const { data: remoteFile } = useGetFileById(file.fileId)
  const url = remoteFile?.cloudLink
    ? remoteFile.cloudLink
    : `${BACKEND_API}/v1/user/files/${file.fileId}`

  return (
    <SectionItem>
      <div className="flex flex-1 flex-col">
        {canDelete && (
          <div className="mb-8 flex flex-row items-start justify-between">
            <FilePreview url={url} alt={file.description} />
            <button
              type="button"
              aria-label={intl.formatMessage(translationsShared.fileFormDelete)}
              onClick={onDelete}
            >
              <IconDelete className="h-5" />
            </button>
          </div>
        )}

        <div className="space-y-5">
          <InputWithLabel
            type="text"
            name={`${name}.description`}
            label={
              <FormattedMessage
                {...translationsShared.fileFormFieldDescription}
              />
            }
            placeholder={intl.formatMessage(
              translationsShared.fileFormFieldDescription
            )}
          />
        </div>
      </div>
    </SectionItem>
  )
}

export { FileFormItem }
