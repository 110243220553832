import Axios, { AxiosResponse } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { CostCenter, CostCenterGroup } from './types'

export type CostCenterGroupsResponse = Array<CostCenterGroup>
export type CostCentersResponse = Array<CostCenter>

async function getCostCenterGroups(): Promise<CostCentersResponse> {
  return axiosGet('v1/user/cost-center-groups')
}

async function getCostCenters(): Promise<CostCentersResponse> {
  return axiosGet('v1/user/cost-centers')
}

async function markAsFavorite(id: string): Promise<CostCenter> {
  const res = await Axios.put<
    Record<string, unknown>,
    AxiosResponse<CostCenter>
  >(`v1/user/cost-centers/${id}/favorite`, {})
  return res.data
}

async function unmarkAsFavorite(id: string): Promise<CostCenter> {
  const res = await Axios.delete<
    Record<string, unknown>,
    AxiosResponse<CostCenter>
  >(`v1/user/cost-centers/${id}/favorite`, {})
  return res.data
}

export { getCostCenterGroups, getCostCenters, markAsFavorite, unmarkAsFavorite }
