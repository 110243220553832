import Axios from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import {
  Activity,
  Type,
  Article,
  Group,
  Category,
  ActivityHierarchy,
} from './types'

const getActivities = (): Promise<Array<Activity>> =>
  axiosGet('/v1/user/activities')

const getActivityConfig = (): Promise<{
  activityCategories: Array<Category>
  activityTypes: Array<Type>
  activityGroups: Array<Group>
}> => axiosGet('/v1/user/activities/config')

const getArticles = (): Promise<Array<Article>> => axiosGet('/v1/user/articles')

async function createActivity(activity: Activity): Promise<Activity> {
  const res = await Axios.post<Activity>(
    `/v1/user/activities/${activity.id}`,
    activity
  )

  return res.data
}

async function updateActivity(activity: Activity): Promise<Activity> {
  const res = await Axios.put<Activity>(
    `/v1/user/activities/${activity.id}`,
    activity
  )

  return res.data
}

const deleteActivity = async (id: string): Promise<void> =>
  Axios.delete(`/v1/user/activities/${id}`)

const getActivityHierarchies = (): Promise<Array<ActivityHierarchy>> =>
  axiosGet('/v1/user/activity-hierarchies')

async function createActivityHierarchy({
  parentId,
  childId,
}: ActivityHierarchy): Promise<Activity> {
  const res = await Axios.post<Activity>(
    `/v1/user/activity-hierarchies/${parentId}/${childId}`
  )

  return res.data
}

export {
  getActivities,
  getArticles,
  getActivityConfig,
  createActivity,
  updateActivity,
  deleteActivity,
  getActivityHierarchies,
  createActivityHierarchy,
}
