import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import IconFilterList from '@aboutbits/react-material-icons/dist/IconFilterList'
import { useSearchParams } from 'react-router-dom'
import IconFilterListOff from '@aboutbits/react-material-icons/dist/IconFilterListOff'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSearch,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { IconPerson, IconPersonOff, IconSearch } from '../../../shared/svgs'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import { ActivityOverviewAddressFilter } from './ActivityOverviewAddressFilter'
import { ActivityOverviewAssigneeFilter } from './ActivityOverviewAssigneeFilter'

type Props = {
  title: string | undefined
  showSearch: boolean
  setShowSearch: (showSearch: boolean) => void
  activityId: string
}

const ActivityOverviewHeader: React.FC<Props> = ({
  title,
  showSearch,
  setShowSearch,
  activityId,
}) => {
  const intl = useIntl()
  const { search, searchActions } = useSearchAndPaginationQueryUrlParameters()
  const [filterParams] = useSearchParams()

  const [showAssigneeFilter, setShowAssigneeFilter] = useState<boolean>(false)
  const [showAddressFilter, setShowAddressFilter] = useState<boolean>(false)

  const startSearch = (): void => {
    setShowSearch(true)
  }

  const stopSearch = (): void => {
    setShowSearch(false)
    searchActions.clear()
  }

  return (
    <>
      {showSearch ? (
        <HeaderSearch
          text={search}
          setText={searchActions.search}
          stopSearch={stopSearch}
          clearSearch={searchActions.clear}
        />
      ) : (
        <>
          <HeaderArea>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderRightArea>
              <div className="space-x-4">
                <HeaderSmallAction
                  icon={
                    filterParams.get('assignedAddress') == null
                      ? IconPersonOff
                      : IconPerson
                  }
                  label={intl.formatMessage({
                    id: 'activity.overview.filter.assignee',
                    defaultMessage: 'Filter by assignee',
                  })}
                  onClick={() => setShowAssigneeFilter(true)}
                />
                <HeaderSmallAction
                  icon={
                    filterParams.get('address') == null
                      ? IconFilterListOff
                      : IconFilterList
                  }
                  label={intl.formatMessage({
                    id: 'activity.overview.filter.address',
                    defaultMessage: 'Filter by address',
                  })}
                  onClick={() => setShowAddressFilter(true)}
                />
                <HeaderSmallAction
                  icon={IconSearch}
                  label={intl.formatMessage({
                    id: 'activity.overview.search',
                    defaultMessage: 'Search activites',
                  })}
                  onClick={startSearch}
                />
              </div>
            </HeaderRightArea>
          </HeaderArea>
        </>
      )}
      <ActivityOverviewAssigneeFilter
        isOpen={showAssigneeFilter}
        onDismiss={() => setShowAssigneeFilter(false)}
        activityId={activityId}
      />
      <ActivityOverviewAddressFilter
        isOpen={showAddressFilter}
        onDismiss={() => setShowAddressFilter(false)}
      />
    </>
  )
}

export { ActivityOverviewHeader }
