import React from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage, useIntl } from 'react-intl'
import IconFormatListBulleted from '@aboutbits/react-material-icons/dist/IconFormatListBulleted'
import {
  useGetChildActivities,
  useGetParentActivities,
} from '../db/activityHierarchies'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { RelatedActivitiesList } from '../related/RelatedActivitiesList'
import { Error } from '../../../shared/error'
import { Empty } from '../../../shared/empty'
import { Section } from '../../../shared/section'

const ActivityHierarchy: React.FC<{
  activityId: string
}> = ({ activityId }) => {
  const { data: parentActivities, error: parentError } =
    useGetParentActivities(activityId)
  const { data: childActivities, error: childError } =
    useGetChildActivities(activityId)

  return (
    <AsyncView
      data={
        parentActivities && childActivities
          ? { parentActivities, childActivities }
          : null
      }
      error={parentError || childError}
      renderLoading={<SectionListLoading title="" />}
      renderSuccess={(data) => {
        if (
          !data ||
          (data.parentActivities.length === 0 &&
            data.childActivities.length === 0)
        ) {
          return <EmptySection />
        }

        return (
          <>
            {data.parentActivities.length > 0 && (
              <RelatedActivitiesList
                title={
                  <FormattedMessage
                    id="activity.detail.hierachies.parentActivities"
                    defaultMessage="Parent activities"
                  />
                }
                activities={data.parentActivities}
              />
            )}
            {data.childActivities.length > 0 && (
              <RelatedActivitiesList
                title={
                  <FormattedMessage
                    id="activity.detail.hierachies.childActivities"
                    defaultMessage="Child activities"
                  />
                }
                activities={data.childActivities}
              />
            )}
          </>
        )
      }}
      renderError={(error) => <Error text={error && error.message} />}
    />
  )
}

const EmptySection = () => {
  const intl = useIntl()

  return (
    <Section>
      <Empty
        icon={IconFormatListBulleted}
        text={intl.formatMessage({
          id: 'activity.detail.hierachies.empty',
          defaultMessage: 'No linked activities available',
        })}
      />
    </Section>
  )
}

export { ActivityHierarchy }
