import React from 'react'
import classnames from 'classnames'

const ActionButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, className, ...props }) => {
  return (
    <button
      className={classnames(
        className,
        'flex w-1/3 flex-row items-center justify-center py-4 text-xs hover:bg-gray-500 focus:outline-none md:w-1/4'
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export { ActionButton }
