import { ComponentType } from 'react'
import { IconProps } from '../../shared/svgs/types'
import {
  IconFormatListBulleted,
  IconPermContactCalendar,
  IconStore,
} from '../../shared/svgs'
import { ResourceType } from '../../shared/utils/resourceTypes'

export type Notification = {
  id: string
  sort?: string
  userId: string | null
  type: NotificationType
  addressGroupId: string | null
  referencedResourceType: string
  referencedResourceId: string
  time: string | null
  message: string
  archived: boolean
  visibility: {
    dashboard: boolean
  }
  createdAt: string
  updatedAt: string
}

export type ViewNotification = Notification & {
  referencedResourceName: string | null
  assignedName: string | null
}

export enum NotificationType {
  is_responsible = 'is_responsible',
  email = 'email',
  reminder = 'reminder',
  due_date = 'due_date',
  employee = 'employee',
}

export const RESOURCE_TYPE_ICONS: {
  [key: string]: ComponentType<IconProps>
} = {
  [ResourceType.project]: IconStore,
  [ResourceType.address]: IconPermContactCalendar,
  [ResourceType.activity]: IconFormatListBulleted,
}

type NotificationTypesFields = {
  [key in NotificationType | string]?: {
    fields: string[]
    enableAssignmentTypeNone?: boolean
  }
}

export const RESOURCE_TYPE_URL_MAPPER: { [key: string]: string } = {
  [ResourceType.project]: 'projects',
  [ResourceType.address]: 'addresses',
  [ResourceType.activity]: 'activities',
}

export const NOTIFICATION_TYPES_FIELDS: NotificationTypesFields = {
  [NotificationType.due_date]: {
    fields: ['time'],
    enableAssignmentTypeNone: true,
  },
  [NotificationType.reminder]: {
    fields: ['message', 'time'],
  },
  [NotificationType.email]: {
    fields: ['message', 'time'],
  },
}
