import React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

import { Button } from '../../button'
import { IconClose } from '../../svgs'
import { Variant } from '../../button/Button'
import { translationsShared } from '../../translations/translationsShared'
import styles from './CancelButton.module.css'

const CancelButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: Variant }
> = ({
  children = <FormattedMessage {...translationsShared.cancel} />,
  className,
  variant = Variant.primaryOutlined,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      className={classnames(
        className,
        'flex flex-1 justify-center md:w-40 md:flex-initial'
      )}
      {...props}
    >
      <div className="flex flex-row items-center justify-center">
        <IconClose
          className={classnames(styles['size-close-icon'], 'mr-1 inline-block')}
        />
        <span className="inline-block">{children}</span>
      </div>
    </Button>
  )
}

export { CancelButton }
