import { ResourceType } from '../../../shared/utils/resourceTypes'
import { Activity } from '../../activity/types'
import { Address, AddressGroup } from '../../address/types'
import { Notification, ViewNotification } from '../types'
import { Project } from '../../project/types'
import { User } from '../../user/types'

type AdditionalNotificationData = {
  users?: User[] | null
  addressGroups?: AddressGroup[] | null
  projects?: Project[] | null
  addresses?: Address[] | null
  activities?: Activity[] | null
}

function populateNotificationData(
  notifications: Notification[] | null | undefined,
  additionalData: AdditionalNotificationData
): ViewNotification[] | null {
  if (notifications === null || notifications === undefined) {
    return null
  }

  return notifications.map((notification) => {
    let resource: Project | Address | Activity | null | undefined = null
    let assignment: User | AddressGroup | null | undefined = null

    if (notification.userId) {
      assignment = additionalData.users?.find(
        (user) => user.id === notification.userId
      )
    } else if (notification.addressGroupId) {
      assignment = additionalData.addressGroups?.find(
        (group) => group.id === notification.addressGroupId
      )
    }

    switch (notification.referencedResourceType) {
      case ResourceType.project:
        resource = additionalData.projects?.find(
          (project) => project.id === notification.referencedResourceId
        )
        break
      case ResourceType.address:
        resource = additionalData.addresses?.find(
          (address) => address.id === notification.referencedResourceId
        )
        break
      case ResourceType.activity:
        resource = additionalData.activities?.find(
          (activity) => activity.id === notification.referencedResourceId
        )
        break
    }

    return {
      ...notification,
      referencedResourceName: resource?.name,
      assignedName: assignment?.name,
    } as ViewNotification
  })
}

export { populateNotificationData }
