import React from 'react'
import { FormattedMessage, FormattedTime } from 'react-intl'
import classnames from 'classnames'

import { EnrichedTimeTracking } from '../TimeTrackingOverviewList'
import { SectionItem } from '../../../../shared/section'
import { TimeTrackingTypeSwitch } from '../../shared/TimeTrackingTypeSwitch'
import { RecordType } from '../../types'
import {
  IconAlarm as TimeTrackingIcon,
  IconPause,
  IconPlayArrow,
  IconStop,
} from '../../../../shared/svgs'
import { useHasModulePermission } from '../../../../auth/hooks'
import { Module, Permission } from '../../../../auth/types'
import { SectionItemActionContainer } from '../../../../shared/section/SectionItemActionContainer'
import { translationsRecordType } from '../../translations/translationsTimeTracking'
import { TimeTrackingItemDelete } from './TimeTrackingItemDelete'
import { TimeTrackingItemDuplicate } from './TimeTrackingItemDuplicate'
import { TimeTrackingItemEdit } from './TimeTrackingItemEdit'

type Props = {
  timeTracking: EnrichedTimeTracking
  showActions: boolean
  onClick: (value: string) => void
}

const TimeTrackingItem: React.FC<Props> = ({
  timeTracking,
  showActions,
  onClick,
}) => {
  const hasCreatePermissions = useHasModulePermission(
    Module.timeTacking,
    Permission.create
  )
  const hasEditPermissions = useHasModulePermission(
    Module.timeTacking,
    Permission.edit
  )
  const hasDeletePermissions = useHasModulePermission(
    Module.timeTacking,
    Permission.delete
  )

  return (
    <div
      className={classnames(
        showActions ? 'bg-white shadow-lg' : '',
        'divide-y divide-gray-500'
      )}
    >
      <button
        className="block w-full text-left focus:outline-none"
        onClick={() => onClick(timeTracking.id)}
      >
        <SectionItem>
          <div className="self-start">
            <TimeTrackingTypeSwitch
              cases={{
                [RecordType.PROJECT_COSTCENTER]: (
                  <TimeTrackingIcon className="h-6 w-6" />
                ),
                [RecordType.WORK_START]: <IconPlayArrow className="h-6 w-6" />,
                [RecordType.WORK_STOP]: <IconStop className="h-6 w-6" />,
                [RecordType.WORK_PAUSE]: <IconPause className="h-6 w-6" />,
              }}
              value={timeTracking.type}
            />
          </div>
          <div className="flex-1 flex-col pl-3 font-bold md:pl-0">
            <TimeTrackingTypeSwitch
              cases={{
                [RecordType.PROJECT_COSTCENTER]: (
                  <>
                    <p>{timeTracking.projectName || timeTracking.projectId}</p>
                    <p className="mt-3 font-normal">
                      {timeTracking.costCenterName || timeTracking.costCenterId}
                    </p>
                  </>
                ),
              }}
              fallback={
                <FormattedMessage
                  {...translationsRecordType(timeTracking.type)}
                />
              }
              value={timeTracking.type}
            />
          </div>
          <div>
            <FormattedTime value={timeTracking.time} />
          </div>
        </SectionItem>
      </button>
      {showActions && (
        <SectionItemActionContainer>
          {hasEditPermissions && <TimeTrackingItemEdit id={timeTracking.id} />}
          {hasCreatePermissions && (
            <TimeTrackingItemDuplicate id={timeTracking.id} />
          )}
          {hasDeletePermissions && (
            <TimeTrackingItemDelete id={timeTracking.id} />
          )}
        </SectionItemActionContainer>
      )}
    </div>
  )
}

export { TimeTrackingItem }
