import { useEffect, useState } from 'react'
import { AddressGroup } from '../types'
import { db } from '../../../db'
import { ListResponse, TABLE_ADDRESS_GROUPS } from '../../../db/types'
import { attachTableListener, useGetById, useGetList } from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const overrideAddressGroups = async (
  addressGroups: Array<AddressGroup>
): Promise<void> => {
  await updateDatabase(db, TABLE_ADDRESS_GROUPS, addressGroups)
}

const useGetAllAddressGroups = () =>
  useGetList<AddressGroup>(TABLE_ADDRESS_GROUPS)

const useGetAddressGroupsForNotifications = (): ListResponse<AddressGroup> => {
  const [data, setData] = useState<Array<AddressGroup> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      db.table(TABLE_ADDRESS_GROUPS)
        .filter((a: AddressGroup) => a.notifications)
        .sortBy('name')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ADDRESS_GROUPS)
  }, [])

  return { data, error }
}

const useGetAddressGroupById = (id: string) =>
  useGetById<AddressGroup>(TABLE_ADDRESS_GROUPS, id)

export {
  overrideAddressGroups,
  useGetAllAddressGroups,
  useGetAddressGroupsForNotifications,
  useGetAddressGroupById,
}
