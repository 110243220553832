import { AsyncView } from '@aboutbits/react-toolbox'
import React from 'react'
import { Error } from '../../../shared/error'
import { useGetAddressById } from '../../address/db/addresses'
import { ProjectType } from '../types'
import { ProjectDetailHero } from './ProjectDetailHero'

type Props = {
  addressId: string
  projectType: ProjectType
}

export function ProjectAddress({ addressId, projectType }: Props) {
  const { data: projectAddressData, error: projectAddressError } =
    useGetAddressById(addressId)

  return (
    <AsyncView
      data={projectAddressData}
      error={projectAddressError}
      renderSuccess={(data) => {
        return (
          data && <ProjectDetailHero data={data} projectType={projectType} />
        )
      }}
      renderError={(projectAddressError) => (
        <Error text={projectAddressError && projectAddressError.message} />
      )}
    />
  )
}
