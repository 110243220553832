import { AddressType } from '../types'
import { db } from '../../../db'
import { TABLE_ADDRESS_TYPES } from '../../../db/types'
import { useGetById, useGetList } from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const overrideAddressTypes = async (
  addressTypes: Array<AddressType>
): Promise<void> => {
  const enrichedAddressTypes = addressTypes.map((addressType) => {
    return {
      ...addressType,
      sortIndexed: addressType.sort ?? '',
    }
  })

  await updateDatabase(db, TABLE_ADDRESS_TYPES, enrichedAddressTypes)
}
const useGetAllAddressTypes = (
  filter?: (addressType: AddressType) => boolean
) =>
  useGetList<AddressType>(TABLE_ADDRESS_TYPES, {
    sortBy: 'sortIndexed',
    filter,
  })
const useGetAddressTypeById = (id: string) =>
  useGetById<AddressType>(TABLE_ADDRESS_TYPES, id)

export { overrideAddressTypes, useGetAllAddressTypes, useGetAddressTypeById }
