import React, { ReactElement, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { AsyncView } from '@aboutbits/react-toolbox'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { ContentArea } from '../../../shared/content'
import {
  deleteNotification as deleteNotificationFromDB,
  overrideNotification,
  useGetNotificationById,
} from '../db/notifications'
import { SectionActions } from '../../../shared/section/SectionActions'
import { ButtonInternalLink } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { DeleteButton } from '../../../shared/button/DeleteButton'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { DeleteDialog } from '../../../shared/dialog/DeleteDialog'
import { IconCheck } from '../../../shared/svgs'
import { populateNotificationData } from '../overview/populateNotificationData'
import { useGetUserById } from '../../user/db/users'
import { useGetAddressGroupById } from '../../address/db/addressGroups'
import { Notification } from '../types'
import { archiveNotification, deleteNotification } from '../api'
import { AppContext } from '../../../app/AppContext'
import { useGetNotificationLink } from '../utils'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsProject } from '../../project/translations/translationsProject'
import { translationsNotification } from '../translations/translationsNotification'
import { NotificationDetailHero } from './NotificationDetailHero'
import { ReferencedProject } from './ReferencedProject'
import { ReferencedAddress } from './ReferencedAddress'
import { ReferencedActivity } from './ReferencedActivity'
import { MessageSection } from './MessageSection'

const NotificationDetail: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(false)
  const [disableArchiveButton, setDisableArchiveButton] =
    useState<boolean>(false)

  const onNotificationDelete = async () => {
    try {
      setDisableDeleteButton(true)

      await deleteNotification(id)

      await deleteNotificationFromDB(id)

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    } finally {
      setShowDeleteDialog(false)
      setDisableDeleteButton(false)
    }
  }

  const onNotificationArchive = async () => {
    if (!viewNotification) {
      return
    }

    try {
      setDisableArchiveButton(true)

      const updatedNotification = await archiveNotification(viewNotification)

      await overrideNotification(updatedNotification)

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
      setDisableArchiveButton(false)
    }
  }

  const onBack = (): void => {
    navigate(-1)
  }

  const { data: notificationData } = useGetNotificationById(id)
  const { data: userData } = useGetUserById(notificationData?.userId || '')
  const { data: addressGroupData } = useGetAddressGroupById(
    notificationData?.addressGroupId || ''
  )

  const viewNotification =
    populateNotificationData(notificationData ? [notificationData] : null, {
      addressGroups: addressGroupData ? [addressGroupData] : null,
      users: userData ? [userData] : null,
    })?.[0] || null

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsProject.detailBack)}
            onClick={onBack}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage
            id="notification.detail.title"
            defaultMessage="Notification"
          />
        </HeaderTitle>
        <HeaderRightArea>
          <HeaderSmallAction
            icon={IconCheck}
            label={intl.formatMessage(translationsNotification.archive)}
            disabled={disableArchiveButton}
            onClick={onNotificationArchive}
          />
        </HeaderRightArea>
      </HeaderArea>

      <ContentArea>
        <AsyncView
          data={viewNotification}
          renderSuccess={(viewNotification) =>
            viewNotification && (
              <>
                <NotificationDetailHero notification={viewNotification} />
                <MessageSection message={viewNotification.message} />

                {viewNotification.referencedResourceType ===
                ResourceType.project ? (
                  <ReferencedProject
                    id={viewNotification.referencedResourceId}
                  />
                ) : viewNotification.referencedResourceType ===
                  ResourceType.address ? (
                  <ReferencedAddress
                    id={viewNotification.referencedResourceId}
                  />
                ) : viewNotification.referencedResourceType ===
                  ResourceType.activity ? (
                  <ReferencedActivity
                    id={viewNotification.referencedResourceId}
                  />
                ) : null}

                <SectionActions>
                  <DeleteButton onClick={() => setShowDeleteDialog(true)} />
                  <NotificationEditButton notification={viewNotification} />
                </SectionActions>
              </>
            )
          }
        />
      </ContentArea>
      <DeleteDialog
        isOpen={showDeleteDialog}
        disableDelete={disableDeleteButton}
        onDismiss={() => setShowDeleteDialog(false)}
        onConfirm={onNotificationDelete}
        title={intl.formatMessage({
          id: 'notification.detail.delete.title',
          defaultMessage: 'Delete notification',
        })}
        text={intl.formatMessage({
          id: 'notification.detail.delete.text',
          defaultMessage: 'Are you sure you want to delete this notification?',
        })}
      />
    </>
  )
}

function NotificationEditButton({
  notification,
}: {
  notification: Notification
}): ReactElement {
  const notificationLink = useGetNotificationLink(notification)

  return (
    <ButtonInternalLink
      to={`${notificationLink}/edit`}
      variant={Variant.primary}
    >
      <FormattedMessage {...translationsShared.edit} />
    </ButtonInternalLink>
  )
}

export { NotificationDetail }
