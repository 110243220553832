import { db } from '../index'
import {
  TABLE_SETTINGS,
  SETTINGS_ID_SYNC_TIMESTAMP,
  SETTINGS_ID_AUTH_ME,
} from '../types'
import { Me } from '../../auth/types'
import { useGetById } from '../hooks'

const setSyncDate = async (date: Date): Promise<void> => {
  await db
    .table(TABLE_SETTINGS)
    .put({ id: SETTINGS_ID_SYNC_TIMESTAMP, date: date })
}

const getSyncDate = async (): Promise<Date> => {
  const data = await db.table(TABLE_SETTINGS).get(SETTINGS_ID_SYNC_TIMESTAMP)

  return data.date
}

const useGetSyncDate = () => {
  const { data } = useGetById<{ date: Date }>(
    TABLE_SETTINGS,
    SETTINGS_ID_SYNC_TIMESTAMP
  )

  return data ? data.date : null
}

const setAuthMe = async (me: Me): Promise<void> => {
  await db.table(TABLE_SETTINGS).put({ id: SETTINGS_ID_AUTH_ME, me })
}

const getAuthMe = async (): Promise<Me | null> => {
  const data = await db.table(TABLE_SETTINGS).get(SETTINGS_ID_AUTH_ME)

  return data?.me ?? null
}

export { setSyncDate, getSyncDate, useGetSyncDate, setAuthMe, getAuthMe }
