import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { IconClose } from '../../../shared/svgs'
import { useGetActivityById } from '../db/activities'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityEditSection } from './ActivityEditSection'

const ActivityEdit: React.FC<{ id: string; activityGroupId: string }> = ({
  id,
  activityGroupId,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { data } = useGetActivityById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage {...translationsActivity.editTitle} />
        </HeaderTitle>
      </HeaderArea>
      <AsyncView
        data={data}
        renderSuccess={(data) =>
          data && (
            <ActivityEditSection
              activity={data}
              activityGroupId={activityGroupId}
            />
          )
        }
      />
    </>
  )
}

export { ActivityEdit }
