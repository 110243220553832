import React from 'react'
import { useParams } from 'react-router-dom'
import { defineMessage } from 'react-intl'
import { AppLayout } from '../../layouts/app'
import { TimeTrackingEdit } from './edit/TimeTrackingEdit'
import { translationsTimetracking } from './translations/translationsTimeTracking'

const TimeTrackingEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout
      title={{
        messageDescriptor: defineMessage(
          translationsTimetracking.overviewTitle
        ),
      }}
    >
      <TimeTrackingEdit id={id as string} />
    </AppLayout>
  )
}

export { TimeTrackingEditPage }
