import React from 'react'
import { CostCenter } from '../types'
import { CostCenterFavoriteAction } from '../shared/CostCenterFavoriteAction'
import { DialogSelectionListItemWithActionAndText } from '../../../shared/dialog/selection/DialogSelectionListItemWithActionAndText'

const CostCentersSelectionItem: React.FC<{
  costCenter: CostCenter
  onSelect: (costCenterId: string) => void
}> = ({ costCenter, onSelect }) => {
  return (
    <DialogSelectionListItemWithActionAndText
      itemId={costCenter.id}
      text={costCenter.name}
      action={<CostCenterFavoriteAction costCenter={costCenter} />}
      onSelect={onSelect}
    />
  )
}

export { CostCentersSelectionItem }
