import React from 'react'
import { PaginatedData } from '../../../../db/types'
import { PaginationActions } from '../../../../shared/pagination/types'
import {
  Section,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../../shared/section'
import { Empty } from '../../../../shared/empty'
import { IconFormatListBulleted } from '../../../../shared/svgs'
import { Article } from '../../types'
import { DialogSelectionListItem } from '../../../../shared/dialog/selection/DialogSelectionListItem'

type ArticleDialogListProps = {
  title: string
  empty: string
  data: PaginatedData<Article>
  onSelect: (articleId: string) => void
} & PaginationActions

const ArticleDialogList: React.FC<ArticleDialogListProps> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((article, index) => (
          <DialogSelectionListItem
            key={index}
            onSelect={onSelect}
            itemId={article.id}
          >
            <div className="flex flex-1 flex-col text-left">
              <h2>{article.name}</h2>
              <h3 className="break-all pt-1 text-sm">{article.codes[0]}</h3>
            </div>
          </DialogSelectionListItem>
        ))}
        {data.items.length === 0 && (
          <Empty icon={IconFormatListBulleted} text={empty} />
        )}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { ArticleDialogList }
