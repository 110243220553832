import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import {
  getCostCenterById,
  useGetAllCostCenters,
  useGetCostCenterGroupsBySearch,
  useGetFavoriteCostCenters,
} from '../db/costCenters'
import { DialogSelectionLoading } from '../../../shared/dialog/selection/DialogSelectionLoading'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { CostCenter } from '../types'
import { DismissType } from '../../../shared/dialog/types'
import { DialogGenericAction } from '../../../shared/dialog/DialogGenericAction'
import { IconQrCodeScanner } from '../../../shared/svgs'
import { QrCodeView } from '../../activity/form/activityForm/steps/QrCodeView'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { CostCenterGroupsSelectionList } from './CostCenterGroupsSelectionList'
import { CostCenterGroupsSelectionWithoutGroup } from './CostCenterGroupsSelectionNoGroup'
import { CostCentersSelectionListFavorites } from './CostCentersSelectionListFavorites'

type Props = {
  dismissType?: DismissType
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string | null) => void
  onConfirmFavorite: (value: string) => void
}

const CostCenterGroupsSelection: React.FC<Props> = ({
  dismissType = DismissType.close,
  onDismiss,
  onConfirm,
  onConfirmFavorite,
}) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const [scanning, setScanning] = useState<boolean>(false)
  const searching = search !== ''

  const optionsCostCenters = useMemo(() => {
    return {
      filter: (item: CostCenter) => {
        return item.groupId === null
      },
    }
  }, [])

  const { data: dataCostCenters, error: errorCostCenters } =
    useGetAllCostCenters(optionsCostCenters)
  const { data: dataGroups, error: errorGroups } =
    useGetCostCenterGroupsBySearch(search, page, size)

  const { data: dataFavorites } = useGetFavoriteCostCenters()

  const title = searching
    ? intl.formatMessage({
        id: 'costCenterGroup.overview.search.result',
        defaultMessage: 'Search result',
      })
    : intl.formatMessage({
        id: 'costCenterGroup.overview.list.all',
        defaultMessage: 'All cost center groups',
      })

  const empty = searching
    ? intl.formatMessage({
        id: 'costCenterGroup.overview.search.empty',
        defaultMessage: 'No cost center groups could be found',
      })
    : intl.formatMessage({
        id: 'costCenterGroup.overview.list.empty',
        defaultMessage: 'No cost center groups available',
      })
  return !scanning ? (
    <>
      <DialogSearchHeader
        searchActions={searchActions}
        search={search}
        searchLabel={intl.formatMessage({
          id: 'costCenterGroup.overview.search.start',
          defaultMessage: 'Search for cost center groups',
        })}
        title={
          <FormattedMessage
            id="costCenterGroup.overview.title"
            defaultMessage="Cost center groups"
          />
        }
        dismissType={dismissType}
        onDismiss={onDismiss}
        additionalAction={
          <DialogGenericAction
            icon={IconQrCodeScanner}
            label={intl.formatMessage(translationsShared.scanQRCodeTitle)}
            onClick={() => {
              setScanning(!scanning)
            }}
          />
        }
      />
      <ContentArea>
        {search === '' && (
          <AsyncView
            data={dataFavorites}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <CostCentersSelectionListFavorites
                  data={favoritesData}
                  onSelect={onConfirmFavorite}
                />
              )
            }
          />
        )}
        <AsyncView
          data={dataCostCenters}
          error={errorCostCenters}
          renderSuccess={(data) =>
            !searching &&
            data &&
            data.length > 0 && (
              <CostCenterGroupsSelectionWithoutGroup
                onSelect={() => onConfirm(null)}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(error) => <Error text={error && error.message} />}
        />
        <AsyncView
          data={dataGroups}
          error={errorGroups}
          renderLoading={<DialogSelectionLoading title={title} />}
          renderSuccess={(data) =>
            data && (
              <CostCenterGroupsSelectionList
                title={title}
                empty={empty}
                data={data}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(error) => <Error text={error && error.message} />}
        />
      </ContentArea>
    </>
  ) : (
    <QrCodeView
      onDismiss={() => setScanning(false)}
      onError={() => {
        setScanning(false)
      }}
      onScan={async (result) => {
        if (result) {
          try {
            const item = await getCostCenterById(result)

            if (item) {
              onConfirmFavorite(item.id)
            } else {
              setScanning(false)
            }
          } catch (e) {
            console.error(e)
            setScanning(false)
          }
        }
      }}
    />
  )
}

export { CostCenterGroupsSelection }
