import React from 'react'
import classNames from 'classnames'

export const FloatingArea: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'fixed bottom-8 right-8 flex flex-col gap-4 items-center',
        className
      )}
    >
      {children}
    </div>
  )
}
