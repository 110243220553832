import { MessageDescriptor, defineMessages } from 'react-intl'
import { RecordType } from '../types'

export const translationsTimetracking = defineMessages({
  overviewTitle: {
    id: 'timeTracking.project.overview.title',
    defaultMessage: 'Projects',
  },
  timeTrackingOverviewTitle: {
    id: 'timeTracking.overview.title',
    defaultMessage: 'Time Tracking',
  },
  editTimelable: {
    id: 'timeTracking.edit.time.label',
    defaultMessage: 'Time',
  },
  editDateLabel: {
    id: 'timeTracking.edit.date.label',
    defaultMessage: 'Date',
  },
  editDescriptionLabel: {
    id: 'timeTracking.edit.description.label',
    defaultMessage: 'Description',
  },
  actionWorkPauseLabel: {
    id: 'timeTracking.action.work_pause.label',
    defaultMessage: 'Pause',
  },
  actionWorkProjectCostCenterLabel: {
    id: 'timeTracking.action.work_project_costCenter.label',
    defaultMessage: 'Project & Cost Center',
  },
  actionWorkStartLabel: {
    id: 'timeTracking.action.work_start.label',
    defaultMessage: 'Start Work',
  },
  actionWorkStopLable: {
    id: 'timeTracking.action.work_stop.label',
    defaultMessage: 'End Work',
  },
})

export function translationsRecordType(
  recordType: RecordType
): MessageDescriptor {
  switch (recordType) {
    case RecordType.WORK_START:
      return translationsTimetracking.actionWorkStartLabel
    case RecordType.WORK_STOP:
      return translationsTimetracking.actionWorkStopLable
    case RecordType.WORK_PAUSE:
      return translationsTimetracking.actionWorkPauseLabel
    case RecordType.PROJECT_COSTCENTER:
      return translationsTimetracking.actionWorkProjectCostCenterLabel
    default:
      return recordType
  }
}
