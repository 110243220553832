import React from 'react'
import { ErrorMessage, FieldAttributes, useField, Field } from 'formik'
import classNames from 'classnames'
import { FieldErrorMessage } from './FieldErrorMessage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Checkbox: React.FC<
  FieldAttributes<any> &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & { label: string }
> = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-gray-500'

  if (meta.touched && meta.error) {
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (props.disabled) {
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }

  return (
    <div className="flex flex-1 flex-row items-end">
      <div
        className={classNames(
          'flex-1 flex flex-row items-center border-b',
          borderCss
        )}
      >
        <label className="pb-1 pt-0.5">
          <Field
            type="checkbox"
            {...field}
            {...props}
            className={classNames(
              'mr-2 text-black border-black border-1 focus:outline-none bg-transparent',
              inputCss
            )}
            checked={field.value}
          />
          {label}
        </label>
      </div>
      <ErrorMessage name={props.name} component={FieldErrorMessage} />
    </div>
  )
}

export { Checkbox }
