import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ResourceType } from '../../shared/utils/resourceTypes'
import { NotificationAdd } from '../notification/add/NotificationAdd'
import { translationsNotification } from '../notification/translations/translationsNotification'

const AddressNotificationAddPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsNotification.addTitle }}>
      <NotificationAdd
        resourceType={ResourceType.address}
        resourceId={id as string}
      />
    </AppLayout>
  )
}

export { AddressNotificationAddPage }
