import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ProjectEdit } from './edit/ProjectEdit'

const ProjectEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'project.edit.title' } }}>
      <ProjectEdit id={id as string} />
    </AppLayout>
  )
}

export { ProjectEditPage }
