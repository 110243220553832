import React from 'react'
import { Link } from 'react-router-dom'
import { AdditionalAddress } from '../types'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { AddressTypeBadge } from '../../address/shared/AddressTypeBadge'

const AdditionalAddressItem: React.FC<{ data: AdditionalAddress }> = ({
  data,
}) => {
  return (
    <Link to={`/addresses/${data.addressId}`} className="block">
      <SectionItem>
        <div className="flex-1">{data.address?.name}</div>
        {data.address && (
          <AddressTypeBadge typeId={data.address.addressTypeId} />
        )}
        <div>
          <IconKeyboardArrowRight className="h-6 w-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { AdditionalAddressItem }
