import React from 'react'
import classnames from 'classnames'

const SectionGridItem: React.FC<{
  className?: string
}> = ({ children, className = '' }) => {
  return (
    <div
      className={classnames(
        'w-full overflow-hidden px-1 md:w-1/2 lg:w-1/2 xl:w-1/2',
        className
      )}
    >
      {children}
    </div>
  )
}

export { SectionGridItem }
