import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { NotificationDetail } from '../notification/detail/NotificationDetail'
import { translationsActivity } from './translations/translationsActivity'

const ActivityNotificationDetailPage: React.FC = () => {
  const { notificationId } = useParams<{ notificationId: string }>()

  return (
    <AppLayout
      title={{ messageDescriptor: translationsActivity.addressDetailTitle }}
    >
      <NotificationDetail id={notificationId as string} />
    </AppLayout>
  )
}

export { ActivityNotificationDetailPage }
