import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { ContentArea } from '../../../shared/content'
import { AuthContext } from '../../../auth/AuthContext'
import { NotificationOverviewList } from '../../notification/overview/NotificationOverviewList'
import { useGetFilteredNotificationsForUser } from '../../notification/db/notifications'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { Help } from '../../../shared/help/Help'
import { translationsDashboard } from '../translations/translationsDashboard'

const DashboardOverview: React.FC = () => {
  const intl = useIntl()
  const auth = useContext(AuthContext)

  const userId = auth.data?.user.id || ''

  const title = intl.formatMessage(
    translationsDashboard.overviewNotificationsTitle
  )

  const { data } = useGetFilteredNotificationsForUser(userId)

  return (
    <>
      <HeaderArea>
        <HeaderTitle>
          <FormattedMessage {...translationsDashboard.overviewTitle} />
        </HeaderTitle>
      </HeaderArea>
      <ContentArea>
        <AsyncView
          data={data}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(data) =>
            data && (
              <NotificationOverviewList
                title={title}
                notifications={data}
                showReferencedResource
                showGoToAction
              />
            )
          }
        />
        <Help href="https://camid.eu" />
      </ContentArea>
    </>
  )
}

export { DashboardOverview }
