import { User } from '../types'
import { TABLE_USERS } from '../../../db/types'
import { db } from '../../../db'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import {
  useGetById,
  useGetByIds,
  useGetList,
  useGetPaginatedList,
} from '../../../db/hooks'
import { Address } from '../../address/types'

const generateIndexedSearch = (user: User): Array<string> => {
  if (user.name === undefined || user.name === null) {
    return []
  }

  return user.name.toLocaleLowerCase().split(' ')
}

const generateIndexedSort = (user: User) => {
  return user.name ?? ''
}

const overrideUsers = async (users: User[]): Promise<void> => {
  const searchableUsers = users.map((user: User) => {
    return {
      ...user,
      searchIndexed: generateIndexedSearch(user),
      sortIndexed: generateIndexedSort(user),
    }
  })
  await updateDatabase(db, TABLE_USERS, searchableUsers)
}

const useGetAllUsers = () => useGetList<User>(TABLE_USERS)

const useGetUsers = (
  search: string,
  page: number,
  size: number,
  filter?: (item: User) => boolean
) =>
  useGetPaginatedList<Address>(TABLE_USERS, search, page, size, {
    sortBy: 'sortIndexed',
    filter,
  })

const useGetUsersByIds = (ids: string[]) => {
  return useGetByIds<User>(TABLE_USERS, ids)
}

const useGetUserById = (id: string) => useGetById<User>(TABLE_USERS, id)

export {
  overrideUsers,
  useGetAllUsers,
  useGetUsers,
  useGetUserById,
  useGetUsersByIds,
}
