import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { NotificationFormFields } from '../forms/types'
import { NotificationForm } from '../forms/NotificationForm'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { Notification } from '../types'
import { saveNotification } from '../api'
import { overrideNotification } from '../db/notifications'
import { AppContext } from '../../../app/AppContext'
import { getAssignmentType } from '../utils'

const NotificationEditSection: React.FC<{
  notification: Notification
}> = ({ notification }) => {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const initialValues: NotificationFormFields = {
    ...notification,
    assignmentType: getAssignmentType(notification),
  }

  const onSubmit = async (value: NotificationFormFields): Promise<void> => {
    try {
      const updatedNotification = await saveNotification(value as Notification)

      await overrideNotification(updatedNotification)

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return <NotificationForm initialValues={initialValues} onSubmit={onSubmit} />
}

export { NotificationEditSection }
