import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityDetail } from './detail/ActivityDetail'
import { translationsActivity } from './translations/translationsActivity'

const ActivityDetailPage: React.FC = () => {
  const { activityId, activityGroupId } = useParams<{
    activityId: string
    activityGroupId: string
  }>()
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('tab')

  return (
    <AppLayout title={{ messageDescriptor: translationsActivity.detailTitle }}>
      <ActivityDetail
        activityId={activityId as string}
        activityGroupId={activityGroupId as string}
        tab={currentTab}
      />
    </AppLayout>
  )
}

export { ActivityDetailPage }
