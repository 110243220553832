import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityAdd } from './add/ActivityAdd'
import { translationsActivity } from './translations/translationsActivity'

const ActivityAddPage: React.FC = () => {
  const { activityGroupId } = useParams<{
    activityGroupId: string
  }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsActivity.addTitle }}>
      <ActivityAdd activityGroupId={activityGroupId as string} />
    </AppLayout>
  )
}

export { ActivityAddPage }
