import { TabPanel, useTabsContext } from '@reach/tabs'

export const LazyTabPanel: React.FC<{ index: number }> = ({
  index,
  children,
  ...props
}) => {
  const { selectedIndex } = useTabsContext()

  return selectedIndex === index ? (
    <TabPanel {...props}>{children}</TabPanel>
  ) : (
    <TabPanel {...props} />
  )
}
