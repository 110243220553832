import { ActivityArticle, Article } from '../types'

type ActivityArticleDetail = {
  id: string
  count: string
  description: string
  name: string
  code: string
}

function populateArticleData(
  base: Array<ActivityArticle>,
  articles: Array<Article> | null
): Array<ActivityArticleDetail> {
  if (!articles) {
    return []
  }

  return base.reduce(
    (acc: Array<ActivityArticleDetail>, data: ActivityArticle) => {
      const article = articles.find((article) => article.id === data.articleId)

      if (article) {
        acc.push({
          id: data.articleId,
          count: data.count,
          description: data.description,
          name: article.name,
          code: article.codes[0],
        })
      }

      return acc
    },
    []
  )
}

export type { ActivityArticleDetail }
export { populateArticleData }
