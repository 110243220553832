import React, { ComponentType, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from '../button/ActionButton'
import { IconEdit } from '../svgs'
import { IconProps } from '../svgs/types'

function SectionItemActionButton({
  onClick,
  label,
  icon: Icon = IconEdit,
}: {
  onClick: () => void
  label: string
  icon?: ComponentType<IconProps>
}): ReactElement {
  return (
    <ActionButton type="button" onClick={onClick}>
      <Icon height={24} />
      <span className="sr-only !pl-2 sm:not-sr-only">{label}</span>
    </ActionButton>
  )
}

function SectionItemActionLink({
  link,
  label,
  icon: Icon = IconEdit,
}: {
  link: string
  label: string
  icon?: ComponentType<IconProps>
}): ReactElement {
  return (
    <Link
      to={link}
      className="flex w-1/3 flex-row items-center justify-center py-4 text-xs hover:bg-gray-500 focus:outline-none md:w-1/4"
    >
      <Icon height={24} />
      <span className="sr-only !pl-2 sm:not-sr-only">{label}</span>
    </Link>
  )
}

export { SectionItemActionButton, SectionItemActionLink }
