import { TabList } from '@reach/tabs'

export const TabListNavigation: React.FC = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <TabList className="mt-2 flex items-center overflow-x-auto whitespace-nowrap border-b border-gray-300 bg-white md:mt-4 md:space-x-4 md:border-none md:bg-transparent md:p-1">
      {children}
    </TabList>
  )
}
