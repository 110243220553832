import React from 'react'
import classnames from 'classnames'

const SectionGrid: React.FC<{ className?: string }> = ({
  className = '',
  children,
}) => {
  return (
    <div
      className={classnames('-mx-1 flex flex-wrap overflow-hidden', className)}
    >
      {children}
    </div>
  )
}

export { SectionGrid }
