import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, SectionList } from '../../../shared/section'
import { PaginationActions } from '../../../shared/pagination/types'
import { DialogSelectionListItem } from '../../../shared/dialog/selection/DialogSelectionListItem'

type Props = {
  onSelect: () => void
} & PaginationActions

const CostCenterGroupsSelectionWithoutGroup: React.FC<Props> = ({
  onSelect,
}) => {
  return (
    <Section>
      <SectionList>
        <DialogSelectionListItem itemId={'x'} onSelect={onSelect}>
          <div className="flex-1 text-left">
            <FormattedMessage
              id="costCenterGroup.overview.list.noGroup"
              defaultMessage="Cost centers without a group"
            />
          </div>
        </DialogSelectionListItem>
      </SectionList>
    </Section>
  )
}

export { CostCenterGroupsSelectionWithoutGroup }
