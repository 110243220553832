import React, { useState } from 'react'
import { useIntl, FormattedDate } from 'react-intl'

import { TimeTrackingRecord } from '../types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconAlarm as TimeTrackingIcon } from '../../../shared/svgs'
import { groupByDate } from '../../../shared/utils/arrayUtils'
import { TimeTrackingItem } from './TimeTrackingItem/TimeTrackingItem'

export type EnrichedTimeTracking = TimeTrackingRecord & {
  costCenterName: string | undefined
  projectName: string | undefined
}

type Props = {
  data: EnrichedTimeTracking[]
}

const EmptySection = () => {
  const intl = useIntl()

  return (
    <Section>
      <Empty
        icon={TimeTrackingIcon}
        text={intl.formatMessage({
          id: 'timeTracking.overview.list.empty',
          defaultMessage: 'No time tracking history available',
        })}
      />
    </Section>
  )
}

const TimeTrackingList: React.FC<Props> = ({ data }) => {
  const [idOfSelectedRecord, setIdOfSelectedRecord] = useState<string | null>(
    null
  )

  const timeTrackingsGroupedByDay = groupByDate('time', data)
  const toggleSelectedRecord = (id: string) => {
    if (idOfSelectedRecord === id) {
      setIdOfSelectedRecord(null)
    } else {
      setIdOfSelectedRecord(id)
    }
  }

  return (
    <>
      {timeTrackingsGroupedByDay.map((timeTrackingsPerDate) => {
        return (
          <Section key={timeTrackingsPerDate.date}>
            <SectionTitle>
              <FormattedDate value={timeTrackingsPerDate.date} />
            </SectionTitle>
            <SectionList>
              {timeTrackingsPerDate.data.map((timeTracking) => {
                return (
                  <TimeTrackingItem
                    key={timeTracking.id}
                    timeTracking={timeTracking}
                    showActions={idOfSelectedRecord === timeTracking.id}
                    onClick={toggleSelectedRecord}
                  />
                )
              })}
            </SectionList>
          </Section>
        )
      })}
    </>
  )
}

const TimeTrackingOverviewList: React.FC<Props> = ({ data }) => {
  return data.length === 0 ? <EmptySection /> : <TimeTrackingList data={data} />
}

export { TimeTrackingOverviewList }
