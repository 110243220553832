import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { InputWithLabel } from '../../../shared/form'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { IconRemoveCircleOutline } from '../../../shared/svgs'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { AddressFormFields } from './types'

const EditFields: React.FC<{
  index: number
  emailText: string
  removeAction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onBlur?: () => void
}> = ({ index, emailText, removeAction, onBlur }) => {
  const intl = useIntl()
  return (
    <div className="flex">
      <InputWithLabel
        onBlur={onBlur}
        name={`emailAddresses.${index}.emailAddress`}
        placeholder={emailText}
        label={
          <FormattedMessage
            id="address.edit.email.label"
            defaultMessage="Email address"
          />
        }
      />
      <Button onClick={removeAction} variant={Variant.secondary}>
        <IconRemoveCircleOutline
          height="24"
          title={intl.formatMessage(translationsShared.delete)}
        />
      </Button>
    </div>
  )
}

const EmailEditSection: React.FC<{ data: AddressFormFields }> = ({ data }) => {
  const intl = useIntl()
  let arrayHelperBinding: FieldArrayRenderProps

  return (
    <div className="mb-10 space-y-5">
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="address.edit.title.email"
            defaultMessage="Email"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <FieldArray
              name="emailAddresses"
              validateOnChange={false}
              render={(arrayHelpers) => {
                arrayHelperBinding = arrayHelpers

                return (
                  <div className="space-y-5">
                    {data.emailAddresses && data.emailAddresses.length > 0 ? (
                      data.emailAddresses.map((number, index) => (
                        <EditFields
                          index={index}
                          key={index}
                          emailText={intl.formatMessage({
                            id: 'address.edit.email.placeholder',
                            defaultMessage: 'Email address',
                          })}
                          removeAction={() => {
                            arrayHelpers.remove(index)
                          }}
                        />
                      ))
                    ) : (
                      <FormattedMessage
                        id="address.edit.empty.email.label"
                        defaultMessage="No email addresses"
                      />
                    )}
                  </div>
                )
              }}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
      <SectionListAddButton
        text={intl.formatMessage({
          id: 'address.button.add.mail',
          defaultMessage: 'Add email address',
        })}
        onClick={() => {
          arrayHelperBinding.push({ emailAddress: '', label: '' })
        }}
      />
    </div>
  )
}

export { EmailEditSection }
