import React from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import { Section, SectionTitle } from '../../../shared/section'
import { SectionHero } from '../../../shared/section/SectionHero'
import {
  Activity,
  ActivityFieldState,
  Category,
  Group,
  Progress,
  Type,
} from '../types'
import { SectionItemContentWithIcon } from '../../../shared/section/SectionItemContentWithIcon'
import { IconEvent } from '../../../shared/svgs'
import { getDurationAsString } from '../form/dateFormatters'
import { translationsActivity } from '../translations/translationsActivity'

const ConfigItems: React.FC<{ type: string; data: string | undefined }> = ({
  type,
  data,
}) => {
  return data ? (
    <div>
      <span className="font-bold">{type}: </span> {data}
    </div>
  ) : (
    <div>
      <span className="font-bold">{type}: </span> -
    </div>
  )
}

const ActivityDate: React.FC<{
  date: string | null
  lable: string
  dateHidden: boolean
  timeHidden: boolean
}> = ({ date, lable, dateHidden, timeHidden }) => {
  return date ? (
    <SectionItemContentWithIcon icon={IconEvent}>
      <span className="font-bold">{lable}</span>:{' '}
      {!dateHidden && <FormattedDate value={new Date(date)} />}
      {!dateHidden && !timeHidden && ' - '}
      {!timeHidden && <FormattedTime value={new Date(date)} />}
    </SectionItemContentWithIcon>
  ) : null
}

const allFieldsHidden = (activityGroup: Group): boolean => {
  const fieldsToCheck = [
    activityGroup.fields.startDate.dateState,
    activityGroup.fields.startDate.timeState,
    activityGroup.fields.endDate.dateState,
    activityGroup.fields.endDate.timeState,
    activityGroup.fields.dueDate.dateState,
    activityGroup.fields.dueDate.timeState,
    activityGroup.fields.activityCategoryId.state,
    activityGroup.fields.activityTypeId.state,
    activityGroup.fields.activityProgressId.state,
  ]

  return fieldsToCheck.every((state) => state === ActivityFieldState.Hidden)
}

const ActivityDetailHero: React.FC<{
  activity: Activity
  categories: Array<Category>
  activityGroup: Group
}> = ({ activity, categories, activityGroup }) => {
  const intl = useIntl()
  let category: Category | undefined
  let type: Type | undefined
  let progress: Progress | undefined

  if (allFieldsHidden(activityGroup)) {
    return null
  }

  const hideStartDate =
    activityGroup.fields.startDate.dateState === ActivityFieldState.Hidden &&
    activityGroup.fields.startDate.timeState === ActivityFieldState.Hidden
  const hideEndDate =
    activityGroup.fields.endDate.dateState === ActivityFieldState.Hidden &&
    activityGroup.fields.endDate.timeState === ActivityFieldState.Hidden

  category = categories.find(
    (category) => category.id === activity.activityCategoryId
  )
  type = category?.types.find((type) => type.id === activity.activityTypeId)
  progress = category?.progresses.find(
    (progress) => progress.id === activity.activityProgressId
  )
  //To doo
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage {...translationsActivity.detailTitle} />
      </SectionTitle>
      <SectionHero>
        <div>
          <h2 className="text-3xl">{activity.name}</h2>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full space-y-5 overflow-hidden lg:w-1/2">
            {!hideStartDate && (
              <ActivityDate
                date={activity.startDate}
                lable={intl.formatMessage({
                  id: 'activity.detail.startDate',
                  defaultMessage: 'Start date',
                })}
                dateHidden={
                  activityGroup.fields.startDate.dateState ===
                  ActivityFieldState.Hidden
                }
                timeHidden={
                  activityGroup.fields.startDate.timeState ===
                  ActivityFieldState.Hidden
                }
              />
            )}
            {!hideEndDate && (
              <ActivityDate
                date={activity.endDate}
                lable={intl.formatMessage({
                  id: 'activity.detail.endDate',
                  defaultMessage: 'End date',
                })}
                dateHidden={
                  activityGroup.fields.endDate.dateState ===
                  ActivityFieldState.Hidden
                }
                timeHidden={
                  activityGroup.fields.endDate.timeState ===
                  ActivityFieldState.Hidden
                }
              />
            )}
            {activityGroup.fields.dueDate.dateState !==
              ActivityFieldState.Hidden && (
              <ActivityDate
                date={activity.dueDate}
                lable={intl.formatMessage({
                  id: 'activity.detail.dueDate',
                  defaultMessage: 'Due date',
                })}
                dateHidden={false}
                timeHidden={
                  activityGroup.fields.dueDate.timeState ===
                  ActivityFieldState.Hidden
                }
              />
            )}
            {!hideStartDate ||
              (!hideEndDate && (
                <SectionItemContentWithIcon icon={IconEvent}>
                  <span className="font-bold">
                    <FormattedMessage
                      {...translationsActivity.detailDateDuration}
                    />
                  </span>
                  : {getDurationAsString(activity.startDate, activity.endDate)}
                </SectionItemContentWithIcon>
              ))}
            {activityGroup.fields.billable.state !==
              ActivityFieldState.Hidden && (
              <SectionItemContentWithIcon icon={IconEvent}>
                <span className="font-bold">
                  <FormattedMessage {...translationsActivity.detailBillable} />
                </span>
                :{' '}
                {activity.billable ? (
                  <FormattedMessage
                    id="activity.detail.billable.yes"
                    defaultMessage="Yes"
                  />
                ) : (
                  <FormattedMessage
                    id="activity.detail.billable.no"
                    defaultMessage="No"
                  />
                )}
              </SectionItemContentWithIcon>
            )}
          </div>
          <div className="mt-10 w-full space-y-5 overflow-hidden lg:mt-0 lg:w-1/2">
            {activityGroup.fields.activityCategoryId.state !==
              ActivityFieldState.Hidden && (
              <ConfigItems
                type={intl.formatMessage(
                  translationsActivity.detailHeroCategory
                )}
                data={category?.name}
              />
            )}
            {activityGroup.fields.activityTypeId.state !==
              ActivityFieldState.Hidden && (
              <ConfigItems
                type={intl.formatMessage(translationsActivity.detailHeroType)}
                data={type?.name}
              />
            )}
            {activityGroup.fields.activityProgressId.state !==
              ActivityFieldState.Hidden && (
              <ConfigItems
                type={intl.formatMessage(
                  translationsActivity.detailHeroProgress
                )}
                data={progress?.name}
              />
            )}
          </div>
        </div>
      </SectionHero>
    </Section>
  )
}

export { ActivityDetailHero }
