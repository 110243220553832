import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { Project } from '../types'
import { updateProject } from '../api'
import { overrideProject } from '../db/projects'
import { FileFormSection } from '../../file/form/FileFormSection'
import { SectionActions } from '../../../shared/section/SectionActions'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { AppContext } from '../../../app/AppContext'

type ProjectFormFields = Pick<Project, 'files'>

const ProjectEditSection: React.FC<{ data: Project }> = ({ data }) => {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)
  const [isUploading, setIsUploading] = useState(false)

  const initialValues: ProjectFormFields = {
    files: data.files,
  }

  const onSubmit = async (value: ProjectFormFields): Promise<void> => {
    const newProject = { ...data }
    newProject.files = value.files

    try {
      const updatedProject = await updateProject(data.id, {
        files: newProject.files,
      })

      await overrideProject(updatedProject)

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <FileFormSection name="files" onUploading={setIsUploading} />
        <SectionActions>
          <CancelButton onClick={() => navigate(-1)} />
          <SubmitButton disabled={isUploading} />
        </SectionActions>
      </Form>
    </Formik>
  )
}

export { ProjectEditSection }
