import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { IconFavorite, IconFavoriteBorder } from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { overrideProject } from '../db/projects'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { SectionItemAction } from '../../../shared/section'
import { Project } from '../types'
import { AppContext } from '../../../app/AppContext'
import { translationsProject } from '../translations/translationsProject'

export const ProjectFavoriteAction: React.FC<{
  project: Project
}> = ({ project }) => {
  const intl = useIntl()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { addAlertErrorMessage } = useContext(AppContext)

  return (
    <SectionItemAction
      icon={project.favorite ? IconFavorite : IconFavoriteBorder}
      label={
        project.favorite
          ? intl.formatMessage(translationsProject.favoriteUnmark)
          : intl.formatMessage(translationsProject.favoriteMark)
      }
      disabled={disabled}
      onClick={async () => {
        setDisabled(true)

        try {
          const updatedProject = await (project.favorite
            ? unmarkAsFavorite(project.id)
            : markAsFavorite(project.id))

          await overrideProject(updatedProject)
        } catch (error: any) {
          addAlertErrorMessage(extractErrorMessage(error))
        } finally {
          setDisabled(false)
        }
      }}
    />
  )
}
