import React, { ComponentType } from 'react'
import { IconProps } from '../svgs/types'

const Empty: React.FC<{ icon: ComponentType<IconProps>; text: string }> = ({
  icon: Icon,
  text,
}) => {
  return (
    <div className="flex flex-col items-center justify-center bg-gray-300 p-12 md:bg-transparent md:p-16">
      <div className="rounded-full bg-gray-500 p-6 text-gray-300 md:p-8">
        <Icon className="h-8 w-8 fill-current md:h-10 md:w-10" />
      </div>
      <p className="mt-4 text-center text-md font-medium leading-normal text-gray-500 md:mt-6 md:text-xl">
        {text}
      </p>
    </div>
  )
}

export { Empty }
