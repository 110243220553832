import React from 'react'
import IconHelp from '@aboutbits/react-material-icons/dist/IconHelp'
import { FormattedMessage } from 'react-intl'
import { ButtonExternalLink, Variant } from '../button/Button'
import { translationsShared } from '../translations/translationsShared'

const Help: React.FC<{
  href: string
}> = ({ href }) => {
  return (
    <div className="flex justify-center px-3 md:px-0">
      <ButtonExternalLink
        variant={Variant.secondary}
        className="flex w-full items-center justify-center"
        href={href}
        target="_blank"
      >
        <IconHelp height="24" />
        <span className="ml-1">
          <FormattedMessage {...translationsShared.help} />
        </span>
      </ButtonExternalLink>
    </div>
  )
}

export { Help }
