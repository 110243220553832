import { useIntl } from 'react-intl'
import { ReactElement, useState } from 'react'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconNotifications } from '../../../shared/svgs'
import { Notification } from '../types'
import { useGetUsersByIds } from '../../user/db/users'
import { useGetProjectsByIds } from '../../project/db/projects'
import {
  useGetActivitiesByIds,
  useGetActivityGroups,
} from '../../activity/db/activities'
import { useGetAddressesByIds } from '../../address/db/addresses'
import { useGetAllAddressGroups } from '../../address/db/addressGroups'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { populateNotificationData } from './populateNotificationData'
import { NotificationItem } from './NotificationItem'

type Props = {
  title: string
  notifications: Notification[]
  showAddress?: boolean
  showReferencedResource?: boolean
  showGoToAction?: boolean
}

function extractResourceIds(
  resourceType: ResourceType,
  notifications: Notification[]
) {
  return notifications
    .filter(
      (notification) => resourceType === notification.referencedResourceType
    )
    .map((notification) => notification.referencedResourceId)
}

function NotificationOverviewList({
  title,
  notifications,
  showAddress = false,
  showReferencedResource = false,
  showGoToAction = false,
}: Props): ReactElement {
  const intl = useIntl()

  const { data: projects } = useGetProjectsByIds(
    extractResourceIds(ResourceType.project, notifications)
  )
  const { data: addresses } = useGetAddressesByIds(
    extractResourceIds(ResourceType.address, notifications)
  )
  const { data: activities } = useGetActivitiesByIds(
    extractResourceIds(ResourceType.activity, notifications)
  )
  const { data: users } = useGetUsersByIds(
    notifications
      .filter((notification) => notification.userId !== null)
      .map((notification) => notification.userId as string)
  )
  const { data: activityGroups } = useGetActivityGroups()

  const { data: addressGroups } = useGetAllAddressGroups()

  const viewNotifications = populateNotificationData(notifications, {
    users,
    addressGroups,
    projects,
    addresses,
    activities,
  })

  const [idOfSelectedRecord, setIdOfSelectedRecord] = useState<string | null>(
    null
  )
  const toggleSelectedRecord = (id: string) => {
    if (idOfSelectedRecord === id) {
      setIdOfSelectedRecord(null)
    } else {
      setIdOfSelectedRecord(id)
    }
  }

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {viewNotifications?.length ? (
          viewNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              showAddress={showAddress}
              showReferencedResource={showReferencedResource}
              showActions={idOfSelectedRecord === notification.id}
              showGoToAction={showGoToAction}
              activities={activities}
              activityGroups={activityGroups}
              onClick={toggleSelectedRecord}
            />
          ))
        ) : (
          <Empty
            icon={IconNotifications}
            text={intl.formatMessage({
              id: 'dashboard.overview.list.empty',
              defaultMessage: 'Great, you have completed all the tasks!',
            })}
          />
        )}
      </SectionList>
    </Section>
  )
}

export { NotificationOverviewList }
