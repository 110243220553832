import { Notification, NotificationType } from '../types'

export type NotificationFormFields = Omit<Notification, 'type'> & {
  type: NotificationType | ''
  assignmentType: NotificationAssignmentType
}

export enum NotificationAssignmentType {
  none = 'none',
  user = 'user',
  addressGroup = 'addressGroup',
}
