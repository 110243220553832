import { defineMessages } from 'react-intl'

export const translationsDashboard = defineMessages({
  overviewTitle: {
    id: 'dashboard.overview.title',
    defaultMessage: 'Dashboard',
  },
  overviewNotificationsTitle: {
    id: 'dashboard.overview.notifications.title',
    defaultMessage: 'Notifications',
  },
})
