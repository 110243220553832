export enum Language {
  EN = 'en',
  DE = 'de',
  IT = 'it',
}

export type User = {
  id: string
  name: string
}
