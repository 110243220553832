import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import {
  useGetCostCentersBySearch,
  useGetFavoriteCostCenters,
} from '../db/costCenters'
import { DialogSelectionLoading } from '../../../shared/dialog/selection/DialogSelectionLoading'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { DismissType } from '../../../shared/dialog/types'
import { translationsCostCenter } from '../translations/translatiosnCostCenter'
import { CostCentersSelectionList } from './CostCentersSelectionList'
import { CostCentersSelectionListFavorites } from './CostCentersSelectionListFavorites'

type Props = {
  costCenterGroupId: string | null
  dismissType?: DismissType
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
}

const CostCentersSelection: React.FC<Props> = ({
  costCenterGroupId,
  dismissType = DismissType.close,
  onDismiss,
  onConfirm,
}) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const filter = useCallback(
    (item) => {
      return item.groupId === costCenterGroupId
    },
    [costCenterGroupId]
  )

  const { data: dataFavorites } = useGetFavoriteCostCenters(filter)
  const { data: dataCostCenters, error: errorCostCenters } =
    useGetCostCentersBySearch(search, page, size, filter)

  const title = searching
    ? intl.formatMessage({
        id: 'costCenter.overview.search.result',
        defaultMessage: 'Search result',
      })
    : intl.formatMessage({
        id: 'costCenter.overview.list.all',
        defaultMessage: 'All cost centers',
      })

  const empty = searching
    ? intl.formatMessage({
        id: 'costCenter.overview.search.empty',
        defaultMessage: 'No cost centers could be found',
      })
    : intl.formatMessage({
        id: 'costCenter.overview.list.empty',
        defaultMessage: 'No cost centers available',
      })

  return (
    <>
      <DialogSearchHeader
        searchActions={searchActions}
        search={search}
        searchLabel={intl.formatMessage(
          translationsCostCenter.overviewSearchStart
        )}
        title={
          <FormattedMessage
            id="costCenter.overview.title"
            defaultMessage="Cost centers"
          />
        }
        dismissType={dismissType}
        onDismiss={onDismiss}
      />
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={dataFavorites}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <CostCentersSelectionListFavorites
                  data={favoritesData}
                  onSelect={onConfirm}
                />
              )
            }
          />
        )}
        <AsyncView
          data={dataCostCenters}
          error={errorCostCenters}
          renderLoading={<DialogSelectionLoading title={title} />}
          renderSuccess={(data) =>
            data && (
              <CostCentersSelectionList
                title={title}
                empty={empty}
                data={data}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(error) => <Error text={error && error.message} />}
        />
      </ContentArea>
    </>
  )
}

export { CostCentersSelection }
