import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { DBContainer } from './db/DBContext'
import { Internationalization } from './app/Internationalization'
import { AppRoutes } from './app/AppRoutes'
import { AuthChecker } from './auth'
import ScrollToTop from './app/ScrollToTop'
import { AppContainer } from './app/AppContext'

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <AuthChecker>
        <AppContainer>
          <DBContainer>
            <Internationalization>
              <AppRoutes />
            </Internationalization>
          </DBContainer>
        </AppContainer>
      </AuthChecker>
    </Router>
  )
}

export default App
