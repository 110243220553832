import React from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import { Section, SectionTitle } from '../../../shared/section'
import { SectionHero } from '../../../shared/section/SectionHero'
import { ViewNotification } from '../types'
import { SectionItemContentWithIcon } from '../../../shared/section/SectionItemContentWithIcon'
import { IconEvent, IconAssignmentInd } from '../../../shared/svgs'
import { translationsNotificationType } from '../translations/translationsNotification'

const HeroDate: React.FC<{ date: string | null; intl: string }> = ({
  date,
  intl,
}) => {
  return date ? (
    <SectionItemContentWithIcon icon={IconEvent}>
      <span className="font-bold">{intl}</span>
      : <FormattedDate value={new Date(date)} /> -{' '}
      <FormattedTime value={new Date(date)} />
    </SectionItemContentWithIcon>
  ) : null
}

const NotificationDetailHero: React.FC<{
  notification: ViewNotification
}> = ({ notification }) => {
  const intl = useIntl()
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage
          id="notification.detail.general.title"
          defaultMessage="Details"
        />
      </SectionTitle>
      <SectionHero>
        <div>
          <h2 className="text-3xl">
            <FormattedMessage
              {...translationsNotificationType(notification.type)}
            />
          </h2>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full space-y-5 overflow-hidden lg:w-1/2">
            <HeroDate
              date={notification.time}
              intl={intl.formatMessage({
                id: 'notification.detail.time',
                defaultMessage: 'Date',
              })}
            />
            {notification.assignedName ? (
              <SectionItemContentWithIcon icon={IconAssignmentInd}>
                <span className="font-bold">
                  <FormattedMessage
                    id="notification.detail.assignment"
                    defaultMessage="Assignment"
                  />
                </span>
                : {notification.assignedName}
              </SectionItemContentWithIcon>
            ) : null}
          </div>
        </div>
      </SectionHero>
    </Section>
  )
}

export { NotificationDetailHero }
