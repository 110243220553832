import React, { ChangeEvent } from 'react'
import { ErrorMessage, Field } from 'formik'
import classNames from 'classnames'
import { FieldErrorMessage } from './FieldErrorMessage'

const SelectionInput: React.FC<{
  name: string
  label: string
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  required?: boolean
}> = ({ children, name, label, disabled = false, onChange, required }) => {
  return (
    <div className="flex-1 flex-col">
      <div className="flex flex-row items-center border-b border-gray-500">
        <label className="w-full">
          <span className="block text-2xs">{label}</span>
          <Field
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              onChange ? onChange(event) : null
            }
            disabled={disabled}
            component="select"
            name={name}
            className={classNames(
              'flex-1 form-select pl-0 pb-1 pt-1.5 bg-transparent text-black focus:outline-none w-full border-none',
              disabled ? 'text-gray-500' : 'text-black'
            )}
            required={required}
          >
            {children}
          </Field>
        </label>
      </div>
      <ErrorMessage name={name} component={FieldErrorMessage} />
    </div>
  )
}

export { SelectionInput }
