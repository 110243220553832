import * as Sentry from '@sentry/browser'
import { Module, ModuleAccessControl } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import {
  getActivities,
  getActivityConfig,
  getActivityHierarchies,
  getArticles,
} from './api'
import {
  overrideActivities,
  overrideActivityCategories,
  overrideActivityGroups,
  overrideActivityHierarchies,
} from './db/activities'
import { overrideArticles } from './db/article'

const syncActivities = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const activities = await getActivities()
    await overrideActivities(activities)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

const syncActivityConfigs = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const activityConfig = await getActivityConfig()
    await overrideActivityCategories(activityConfig.activityCategories)
    await overrideActivityGroups(activityConfig.activityGroups)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

const syncArticles = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const articles = await getArticles()
    await overrideArticles(articles)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

const syncActivityHierarchies = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const activityHierarchies = await getActivityHierarchies()
    await overrideActivityHierarchies(activityHierarchies)
  } catch (error: any) {
    if (error.response.status === 401) {
    } else {
      Sentry.captureException(error)
    }
  }
}

export {
  syncActivities,
  syncArticles,
  syncActivityConfigs,
  syncActivityHierarchies,
}
