import React from 'react'
import classNames from 'classnames'

type Props = {
  children?: React.ReactNode
  className?: string
}

const SectionTitle: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        className,
        'px-3 pb-5 text-sm font-medium md:p-5 md:font-bold'
      )}
    >
      {children}
    </div>
  )
}

export { SectionTitle }
