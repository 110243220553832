import React, { ReactNode } from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { ErrorMessage } from 'formik'
import { FieldErrorMessage } from '../../../shared/form/FieldErrorMessage'
import { translationsShared } from '../../../shared/translations/translationsShared'

type Props = {
  onClick: () => void
  touched: boolean
  hasError: boolean
  disabled?: boolean
  label: ReactNode
  value: string
  fieldName: string
}

const SelectWithDialogField: React.FC<Props> = ({
  onClick,
  touched,
  hasError,
  disabled,
  label,
  value,
  fieldName,
}) => {
  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-gray-500'

  if (touched && hasError) {
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (disabled) {
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }
  return (
    <div className="flex-1 flex-col">
      <button
        type="button"
        className={classnames(
          'group block w-full cursor-pointer border-b text-left focus:outline-none',
          borderCss
        )}
        onClick={onClick}
      >
        <span className="block text-2xs group-focus:font-bold">{label}</span>
        <div
          className={classnames(
            'flex flex-1 bg-transparent pt-0.5 pb-1 text-black',
            inputCss
          )}
        >
          {value || <FormattedMessage {...translationsShared.select} />}
        </div>
      </button>
      <ErrorMessage name={fieldName} component={FieldErrorMessage} />
    </div>
  )
}

export { SelectWithDialogField }
