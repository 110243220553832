import * as Sentry from '@sentry/browser'
import { getSettings } from './api'
import { overrideUserAddressGroups } from './db/userAddressGroups'

const syncSettings = async (): Promise<void> => {
  try {
    const settings = await getSettings()

    await overrideUserAddressGroups(
      settings.addressGroupIds.map((id) => {
        return {
          id: id,
        }
      })
    )
  } catch (error) {
    Sentry.captureException(error)
  }
}

export { syncSettings }
