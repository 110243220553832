import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Section, SectionTitle } from '../../../shared/section'
import { SectionHero } from '../../../shared/section/SectionHero'
import { Address } from '../../address/types'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { checkForCompleteAddress } from '../../address/details/checkForCompleteAddress'
import { ProjectTypeBadge } from '../shared/ProjectTypeBadge'
import { ProjectType } from '../types'
import { translationsProject } from '../translations/translationsProject'

const ProjectDetailHero: React.FC<{
  data: Address
  projectType: ProjectType
}> = ({ data, projectType }) => {
  return (
    <Section>
      <SectionTitle>
        <div className="flex items-center justify-between">
          <FormattedMessage {...translationsProject.detailTitle} />
          <ProjectTypeBadge projectType={projectType} />
        </div>
      </SectionTitle>
      <Link to={`/addresses/${data?.id}`}>
        <SectionHero>
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <div className="mb-1">{data?.name}</div>
              {data?.address && checkForCompleteAddress(data) && (
                <address className="not-italic leading-relaxed">
                  {data?.address.street}
                  <br />
                  {data?.address.cap} {data?.address.city} (
                  {data?.address.country})
                </address>
              )}
            </div>
            <IconKeyboardArrowRight className="h-6 w-6" />
          </div>
        </SectionHero>
      </Link>
    </Section>
  )
}

export { ProjectDetailHero }
