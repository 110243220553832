import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityOverviewGroup } from './overview/ActivityOverviewGroup'
import { translationsActivity } from './translations/translationsActivity'

const ActivitiesOverviewGroupPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout
      title={{ messageDescriptor: translationsActivity.overviewTitle }}
    >
      <ActivityOverviewGroup key={id} id={id as string} />
    </AppLayout>
  )
}

export { ActivitiesOverviewGroupPage }
