import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { LocationProvider } from '@aboutbits/react-toolbox'
import { AuthContext } from './AuthContext'

const ProtectedArea: React.FC = ({ children }) => {
  const { authenticated } = useContext(AuthContext)

  return authenticated ? (
    <LocationProvider highAccuracy={true} delay={5 * 60 * 1000}>
      {children}
    </LocationProvider>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
      replace
    />
  )
}

export { ProtectedArea }
