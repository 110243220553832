import { axiosGet } from '../../shared/utils/apiUtils'

export type SettingsResponse = {
  addressGroupIds: Array<string>
}

const getSettings = (): Promise<SettingsResponse> =>
  axiosGet('v1/user/settings')

export { getSettings }
