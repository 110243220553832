import { Article } from '../types'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { db } from '../../../db'
import { TABLE_ARTICLES } from '../../../db/types'
import { useGetById, useGetByIds, useGetPaginatedList } from '../../../db/hooks'

const generateIndexedSearch = (article: Article): Array<string> => {
  if (!article.search) return []

  return article.search?.toLocaleLowerCase().split(' ')
}

const generateIndexedSort = (article: Article) => {
  return article.sort ?? ''
}

const overrideArticles = async (articles: Array<Article>) => {
  const enrichedArticles = articles.map((article) => {
    return {
      ...article,
      searchIndexed: generateIndexedSearch(article),
      sortIndexed: generateIndexedSort(article),
    }
  })

  await updateDatabase(db, TABLE_ARTICLES, enrichedArticles)
}

const useGetPaginatedArticles = (
  search: string,
  page: number,
  size: number,
  filter?: (item: Article) => boolean
) =>
  useGetPaginatedList<Article>(TABLE_ARTICLES, search, page, size, {
    sortBy: 'sortIndexed',
    filter: filter,
  })

const useGetArticlesByIds = (ids: string[]) => {
  return useGetByIds<Article>(TABLE_ARTICLES, ids)
}

const useGetArticleById = (id: string) =>
  useGetById<Article>(TABLE_ARTICLES, id)

const getArticlesByArticleCode = (search: string) => {
  return db.table(TABLE_ARTICLES).where('codes').equals(search).toArray()
}

export {
  overrideArticles,
  useGetPaginatedArticles,
  useGetArticleById,
  useGetArticlesByIds,
  getArticlesByArticleCode,
}
