import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'
import { ActivityFieldState, Category, Group, GroupFieldConfig } from '../types'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityFormFields } from './types'
import { ConfigSelect } from './ConfigSelect'

const allFieldsHidden = (fieldConfig: GroupFieldConfig | undefined): boolean =>
  fieldConfig !== undefined &&
  fieldConfig.name.state === ActivityFieldState.Hidden &&
  fieldConfig.description.state === ActivityFieldState.Hidden &&
  fieldConfig.activityCategoryId.state === ActivityFieldState.Hidden &&
  fieldConfig.activityTypeId.state === ActivityFieldState.Hidden &&
  fieldConfig.activityProgressId.state === ActivityFieldState.Hidden

const GeneralFormSection: React.FC<{
  activityGroup: Group
  categories: Array<Category> | null
  formik: FormikProps<ActivityFormFields>
  fieldsConfig: GroupFieldConfig | undefined
}> = ({ activityGroup, categories, formik, fieldsConfig }) => {
  const intl = useIntl()

  if (allFieldsHidden(fieldsConfig)) {
    return null
  }

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="activity.form.general.title"
            defaultMessage="General"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            {fieldsConfig?.name.state !== ActivityFieldState.Hidden && (
              <InputWithLabel
                type="text"
                name="name"
                placeholder={intl.formatMessage(
                  translationsActivity.formGeneralName
                )}
                label={
                  <FormattedMessage {...translationsActivity.formGeneralName} />
                }
                required={true}
                disabled={
                  fieldsConfig?.name.state === ActivityFieldState.ReadOnly
                }
              />
            )}
            {fieldsConfig?.description.state !== ActivityFieldState.Hidden && (
              <InputWithLabel
                component="textarea"
                name="description"
                rows={5}
                autosize={true}
                placeholder={intl.formatMessage(
                  translationsActivity.formGeneralDescription
                )}
                label={
                  <FormattedMessage
                    {...translationsActivity.formGeneralDescription}
                  />
                }
                required={fieldsConfig?.description.required}
                disabled={
                  fieldsConfig?.description.state ===
                  ActivityFieldState.ReadOnly
                }
              />
            )}
            <ConfigSelect
              activityGroup={activityGroup}
              categories={categories}
              formik={formik}
              fieldsConfig={fieldsConfig}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { GeneralFormSection }
