import { useMemo } from 'react'
import { useGetList } from '../../../db/hooks'
import { TABLE_ACTIVITIES, TABLE_ACTIVITY_HIERARCHIES } from '../../../db/types'
import { Activity, ActivityHierarchy } from '../types'

const useGetParentActivities = (activityId: string) => {
  return _useGetHierarchyActivities(activityId, 'childId', 'parentId')
}

const useGetChildActivities = (activityId: string) => {
  return _useGetHierarchyActivities(activityId, 'parentId', 'childId')
}

const _useGetHierarchyActivities = (
  activityId: string,
  compareKey: keyof ActivityHierarchy,
  returnKey: keyof ActivityHierarchy
) => {
  const hierarchy = useGetList<ActivityHierarchy>(
    TABLE_ACTIVITY_HIERARCHIES,
    useMemo(
      () => ({
        filter: (item) => item[compareKey] === activityId,
      }),
      [activityId, compareKey]
    )
  )

  const hierarchyActivityIds = useMemo(
    () =>
      hierarchy.data ? hierarchy.data.map((item) => item[returnKey]) : null,
    [hierarchy.data, returnKey]
  )

  const activities = useGetList<Activity>(
    TABLE_ACTIVITIES,
    useMemo(
      () => ({
        enabled:
          (hierarchyActivityIds && hierarchyActivityIds.length > 0) || false,
        filter: (item) =>
          hierarchyActivityIds ? hierarchyActivityIds.includes(item.id) : false,
      }),
      [hierarchyActivityIds]
    )
  )

  const data = useMemo(
    () =>
      hierarchyActivityIds && hierarchyActivityIds.length === 0
        ? []
        : activities.data,
    [activities.data, hierarchyActivityIds]
  )

  const error = useMemo(
    () => hierarchy.error || activities.error,
    [hierarchy.error, activities.error]
  )

  return { data, error }
}

export { useGetParentActivities, useGetChildActivities }
