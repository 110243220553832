import React, { useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { FileFormSection } from '../../file/form/FileFormSection'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { EmailEditSection } from './EmailEditSection'
import { PhoneEditSection } from './PhoneEditSection'
import { AddressFormFields } from './types'
import { GeneralEditSection } from './GeneralEditSection'

const AddressForm: React.FC<{
  initialValues: AddressFormFields
  onSubmit: (
    values: AddressFormFields,
    formikHelpers: FormikHelpers<AddressFormFields>
  ) => void
}> = ({ initialValues, onSubmit }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isUploading, setIsUploading] = useState(false)

  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )
  const schema = Yup.object().shape({
    name: Yup.string().required(requiredField),
    address: Yup.object().shape({
      street: Yup.string().nullable(),
      city: Yup.string().nullable(),
      cap: Yup.string().nullable(),
      country: Yup.string().nullable(),
    }),
    phoneNumbers: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(requiredField),
        phoneNumber: Yup.string().required(requiredField),
      })
    ),
    emailAddresses: Yup.array().of(
      Yup.object().shape({
        emailAddress: Yup.string()
          .email(
            intl.formatMessage({
              id: 'address.edit.email.error',
              defaultMessage: 'Invalid Email Address',
            })
          )
          .required(requiredField),
      })
    ),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={schema}
      validateOnChange={false}
    >
      {({ values }) => (
        <Form>
          <LeaveDirtyFormPrompt />
          <GeneralEditSection />
          <PhoneEditSection data={values} />
          <EmailEditSection data={values} />
          <FileFormSection name="files" onUploading={setIsUploading} />
          <SectionActions>
            <CancelButton onClick={() => navigate(-1)} />
            <SubmitButton disabled={isUploading} />
          </SectionActions>
        </Form>
      )}
    </Formik>
  )
}

export { AddressForm }
