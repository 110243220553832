import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, SectionTitle } from '../../../shared/section'
import { Address } from '../types'
import { SectionHero } from '../../../shared/section/SectionHero'
import { SectionGrid } from '../../../shared/section/SectionGrid'
import { SectionGridItem } from '../../../shared/section/SectionGridItem'
import { IconCall, IconMail, IconPlace } from '../../../shared/svgs'
import { SectionItemContentWithIcon } from '../../../shared/section/SectionItemContentWithIcon'
import { translationsAddress } from '../translations/translationsAddress'
import { checkForCompleteAddress } from './checkForCompleteAddress'

const googleMapsLink = (data: Address): string => {
  if (data.address.longitude && data.address.latitude) {
    return `https://www.google.com/maps/place/${data.address.latitude},${data.address.longitude}`
  }

  const { street, country, city, cap } = data.address

  return encodeURI(
    `https://maps.google.com/?q=${street}, ${city}, ${cap}, ${country}`
  )
}

const AddressDetailHero: React.FC<{ data: Address | null }> = ({ data }) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage {...translationsAddress.detailTitle} />
      </SectionTitle>
      <SectionHero>
        <h1 className="text-3xl">{data?.name}</h1>
        <SectionGrid>
          {data?.address && checkForCompleteAddress(data) && (
            <SectionGridItem className="my-3 md:my-4">
              <SectionItemContentWithIcon icon={IconPlace}>
                <a
                  href={googleMapsLink(data)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  <address className="not-italic">
                    <p className="mb-1">
                      {data.address.street ? <>{data.address.street}</> : null}
                    </p>
                    <p>
                      {data.address.cap} {data?.address.city}
                      {data.address.country
                        ? ` (${data.address.country})`
                        : null}
                    </p>
                  </address>
                </a>
              </SectionItemContentWithIcon>
            </SectionGridItem>
          )}
          {data && data.phoneNumbers.length > 0 && (
            <SectionGridItem className="my-3 md:my-4">
              <SectionItemContentWithIcon icon={IconCall}>
                <dl className="space-y-1">
                  {data?.phoneNumbers.map((phone, index) => (
                    <div key={index}>
                      <dt className="inline-block capitalize">
                        {phone.label}:
                      </dt>{' '}
                      <dd className="inline-block">
                        <a
                          href={`tel:${phone.phoneNumber.replace(/ /g, '')}`}
                          className="hover:underline"
                        >
                          {phone.phoneNumber}
                        </a>
                      </dd>
                    </div>
                  ))}
                </dl>
              </SectionItemContentWithIcon>
            </SectionGridItem>
          )}
          {data && data.emailAddresses.length > 0 && (
            <SectionGridItem className="my-3 md:my-4">
              <SectionItemContentWithIcon icon={IconMail}>
                <dl className="space-y-1">
                  {data?.emailAddresses.map((mail, index) => (
                    <div key={index}>
                      <dd className="inline-block">
                        <a
                          href={`mailto:${mail.emailAddress}`}
                          className="hover:underline"
                        >
                          {mail.emailAddress}
                        </a>
                      </dd>
                    </div>
                  ))}
                </dl>
              </SectionItemContentWithIcon>
            </SectionGridItem>
          )}
        </SectionGrid>
      </SectionHero>
    </Section>
  )
}

export { AddressDetailHero }
