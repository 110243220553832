import { AddressGroupReference } from '../types'
import { TABLE_USER_ADDRESS_GROUPS } from '../../../db/types'
import { db } from '../../../db'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { useGetList } from '../../../db/hooks'

const overrideUserAddressGroups = async (
  userAddressGroups: AddressGroupReference[]
): Promise<void> => {
  await updateDatabase(db, TABLE_USER_ADDRESS_GROUPS, userAddressGroups)
}

const useGetAllUserAddressGroups = (
  options: {
    filter?: (item: AddressGroupReference) => boolean
  } = {}
) => useGetList<AddressGroupReference>(TABLE_USER_ADDRESS_GROUPS, options)

export { overrideUserAddressGroups, useGetAllUserAddressGroups }
