import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { IconClose } from '../../../shared/svgs'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsAddress } from '../translations/translationsAddress'
import { AddressAddSection } from './AddressAddSection'

const AddressAdd: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage {...translationsAddress.addTitle} />
        </HeaderTitle>
      </HeaderArea>
      <AddressAddSection />
    </>
  )
}

export { AddressAdd }
