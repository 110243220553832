import { TABLE_ACTIVITY_GROUP_SETTINGS } from '../../../db/types'
import { db } from '../../../db'
import { useGetList } from '../../../db/hooks'
import { ActivityGroupSettings } from '../types'

export function deleteActivityGroupSettingItem(id: string) {
  return db.table(TABLE_ACTIVITY_GROUP_SETTINGS).delete(id)
}

export async function updateActivityGroupSettings(
  id: string,
  assignedUserId: string
) {
  const oldItem = await getActivityGroupSettingItem(id)

  if (oldItem) {
    await db.table(TABLE_ACTIVITY_GROUP_SETTINGS).delete(id)
  }

  return db
    .table(TABLE_ACTIVITY_GROUP_SETTINGS)
    .add({ id: id, assignedUserId: assignedUserId })
}

export function getActivityGroupSettingItem(id: string) {
  return db.table(TABLE_ACTIVITY_GROUP_SETTINGS).get(id)
}

export const useGetActivityGroupSettings = () => {
  return useGetList<ActivityGroupSettings>(TABLE_ACTIVITY_GROUP_SETTINGS)
}
