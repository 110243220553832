import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivitySignature } from './signature/ActivitySignature'
import { translationsActivity } from './translations/translationsActivity'

const ActivitySignaturePage: React.FC = () => {
  const { activityId } = useParams<{ activityId: string }>()

  return (
    <AppLayout title={{ messageDescriptor: translationsActivity.signTitle }}>
      <ActivitySignature activityId={activityId as string} />
    </AppLayout>
  )
}

export { ActivitySignaturePage }
