import { intervalToDuration } from 'date-fns'

const getDurationAsString = (startDate: string, endDate: string): string => {
  const duration = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  })

  const hours =
    duration?.hours?.toString().length === 1
      ? '0' + duration.hours
      : duration.hours
  const minutes =
    duration?.minutes?.toString().length === 1
      ? '0' + duration.minutes
      : duration.minutes

  return `${hours}:${minutes}`
}

const getDurationFromString = (
  duration: string
): { hours: number; minutes: number } | null => {
  const durationParsedString = duration.split(':')

  if (durationParsedString[0] && durationParsedString[1]) {
    return {
      hours: parseInt(durationParsedString[0]),
      minutes: parseInt(durationParsedString[1]),
    }
  } else {
    return null
  }
}

export { getDurationAsString, getDurationFromString }
