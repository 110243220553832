import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import * as serviceWorker from './serviceWorkerRegistration'
import App from './App'
import './config/axios'
import './styles/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_VERSION, SENTRY_DSN } from './config/env'

if (SENTRY_DSN !== undefined && SENTRY_DSN !== null && SENTRY_DSN !== '') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
  })
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

serviceWorker.register({
  onUpdate: (registration) => {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  },
})
