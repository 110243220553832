import React from 'react'
import { Project } from '../types'
import { ProjectFavoriteAction } from '../shared/ProjectFavoriteAction'
import { DialogSelectionListItemWithActionAndText } from '../../../shared/dialog/selection/DialogSelectionListItemWithActionAndText'
import { ProjectTypeBadge } from '../shared/ProjectTypeBadge'

const ProjectsSelectionItem: React.FC<{
  project: Project
  onSelect: (projectId: string) => void
}> = ({ project, onSelect }) => {
  return (
    <DialogSelectionListItemWithActionAndText
      itemId={project.id}
      action={<ProjectFavoriteAction project={project} />}
      text={project.name}
      adornment={<ProjectTypeBadge projectType={project.type} />}
      onSelect={onSelect}
    />
  )
}

export { ProjectsSelectionItem }
