import React, { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import { UseSearchQuery } from '../pagination/types'
import { HeaderLeftArea } from '../header'
import { IconArrowBack, IconClose } from '../svgs'
import { translationsShared } from '../translations/translationsShared'
import { translationsCostCenter } from '../../domain/costCenter/translations/translatiosnCostCenter'
import { DialogSearch } from './DialogSearch'
import { DialogHeaderArea } from './DialogHeaderArea'
import { DialogAction } from './DialogAction'
import { DialogTitle } from './DialogTitle'
import { DialogStartSearchAction } from './DialogStartSearchAction'
import { DismissType } from './types'

type Props = {
  dismissType?: DismissType
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  title: ReactNode
  searchLabel: string
  additionalAction?: ReactNode
} & UseSearchQuery

const DialogSearchHeader: React.FC<Props> = ({
  search,
  dismissType = DismissType.close,
  onDismiss,
  searchActions,
  searchLabel,
  title,
  additionalAction,
}) => {
  const intl = useIntl()
  const [searchShow, setSearchShow] = useState<boolean>(search !== '')

  const startSearch = (): void => {
    setSearchShow(true)
  }

  const stopSearch = (): void => {
    setSearchShow(false)
    searchActions.clear()
  }
  return searchShow ? (
    <DialogSearch
      text={search}
      searchLabel={intl.formatMessage(
        translationsCostCenter.overviewSearchStart
      )}
      title={title}
      setText={searchActions.search}
      stopSearch={stopSearch}
      dismissType={dismissType}
      onDismiss={onDismiss}
    />
  ) : (
    <DialogHeaderArea>
      <HeaderLeftArea>
        <DialogAction
          label={searchLabel}
          onClick={onDismiss}
          className="rounded-full bg-white hover:bg-gray-300"
        >
          {dismissType === DismissType.close && (
            <IconClose
              className="h-7 w-7 fill-current text-gray-700"
              title={intl.formatMessage(translationsShared.close)}
            />
          )}
          {dismissType === DismissType.back && (
            <IconArrowBack
              className="h-7 w-7 fill-current text-gray-700"
              title={intl.formatMessage(translationsShared.goBack)}
            />
          )}
        </DialogAction>
      </HeaderLeftArea>
      <DialogTitle className="text-white">{title}</DialogTitle>
      <div className="inline-flex flex-row justify-end pr-2 pb-2 md:pr-0 md:pb-0">
        {additionalAction}
        <DialogStartSearchAction
          startSearch={startSearch}
          label={searchLabel}
        />
      </div>
    </DialogHeaderArea>
  )
}

export { DialogSearchHeader }
