import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormikContext } from 'formik'
import { add, intervalToDuration, isBefore, sub } from 'date-fns'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'
import {
  convertDateAndTimeToDate,
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
} from '../../../shared/utils/dateUtils'
import {
  formatDateAndTimeForAPI,
  formatDateForAPI,
} from '../../../shared/utils/apiUtils'
import { Checkbox } from '../../../shared/form/Checkbox'
import { ActivityFieldState, GroupFieldConfig } from '../types'
import { translationsActivity } from '../translations/translationsActivity'
import { ActivityFormFields } from './types'
import { getDurationAsString, getDurationFromString } from './dateFormatters'

const InlineDateTime: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-4">
      {children}
    </div>
  )
}

const allFieldsHidden = (fieldConfig: GroupFieldConfig | undefined): boolean =>
  fieldConfig !== undefined &&
  fieldConfig.startDate.dateState === ActivityFieldState.Hidden &&
  fieldConfig.startDate.timeState === ActivityFieldState.Hidden &&
  fieldConfig.endDate.dateState === ActivityFieldState.Hidden &&
  fieldConfig.endDate.timeState === ActivityFieldState.Hidden &&
  fieldConfig.dueDate.dateState === ActivityFieldState.Hidden &&
  fieldConfig.dueDate.timeState === ActivityFieldState.Hidden &&
  fieldConfig.duration.state === ActivityFieldState.Hidden &&
  fieldConfig.billable.state === ActivityFieldState.Hidden

const TimingFormSection: React.FC<{
  fieldsConfig: GroupFieldConfig | undefined
}> = ({ fieldsConfig }) => {
  const { values, setFieldValue } = useFormikContext<ActivityFormFields>()

  if (allFieldsHidden(fieldsConfig)) {
    return null
  }

  const onFocusDueDate = () => {
    if (
      (values.dueDate === null || values.dueDate === '') &&
      (values.dueTime === null || values.dueTime === '')
    ) {
      setFieldValue(
        'dueDate',
        extractDateFromTimestamp(formatDateForAPI(new Date()))
      )

      setFieldValue(
        'dueTime',
        extractTimeFromTimestamp(formatDateForAPI(new Date()))
      )
    }
  }

  const onChangeStartDate = (value: string) => {
    const oldStartDate = convertDateAndTimeToDate(
      values.startDate,
      values.startTime
    )
    const oldEndDate = convertDateAndTimeToDate(values.endDate, values.endTime)

    const duration = intervalToDuration({
      start: oldStartDate,
      end: oldEndDate,
    })

    const newEndDate = formatDateForAPI(
      add(convertDateAndTimeToDate(value, values.startTime), duration)
    )
    setFieldValue('startDate', value)
    setFieldValue('endDate', extractDateFromTimestamp(newEndDate))
    setFieldValue('endTime', extractTimeFromTimestamp(newEndDate))

    setFieldValue('startDateChanged', true)
  }

  const onChangeStartTime = (value: string) => {
    const oldStartDate = convertDateAndTimeToDate(
      values.startDate,
      values.startTime
    )
    const oldEndDate = convertDateAndTimeToDate(values.endDate, values.endTime)

    const duration = intervalToDuration({
      start: oldStartDate,
      end: oldEndDate,
    })

    const newEndDate = formatDateForAPI(
      add(convertDateAndTimeToDate(values.startDate, value), duration)
    )
    setFieldValue('startTime', value)
    setFieldValue('endDate', extractDateFromTimestamp(newEndDate))
    setFieldValue('endTime', extractTimeFromTimestamp(newEndDate))

    setFieldValue('startDateChanged', true)
  }

  const onChangeEndDate = (value: string) => {
    const oldStartDate = convertDateAndTimeToDate(
      values.startDate,
      values.startTime
    )
    const newEndDate = convertDateAndTimeToDate(value, values.endTime)

    if (oldStartDate < newEndDate) {
      setFieldValue('endDate', value)
      setFieldValue('endDateChanged', true)
      setFieldValue('durationChanged', false)
    }
  }

  const onChangeEndTime = (value: string) => {
    const oldStartDate = convertDateAndTimeToDate(
      values.startDate,
      values.startTime
    )
    const newEndDate = convertDateAndTimeToDate(values.endDate, value)

    if (oldStartDate < newEndDate) {
      setFieldValue('endTime', value)
      setFieldValue('endDateChanged', true)
      setFieldValue('durationChanged', false)
    }
  }

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="activity.form.time.title"
            defaultMessage="Time information"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            {(fieldsConfig?.startDate.dateState !== ActivityFieldState.Hidden ||
              fieldsConfig?.startDate.timeState !==
                ActivityFieldState.Hidden) && (
              <InlineDateTime>
                {!fieldsConfig?.startDate.dateState && (
                  <InputWithLabel
                    name="startDate"
                    type="date"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChangeStartDate(event.target.value)
                    }
                    label={
                      <FormattedMessage
                        id="activity.detail.date.start"
                        defaultMessage="Start date"
                      />
                    }
                    required={fieldsConfig?.startDate.required}
                    disabled={
                      fieldsConfig?.startDate.dateState ===
                      ActivityFieldState.ReadOnly
                    }
                  />
                )}
                {fieldsConfig?.startDate.timeState !==
                  ActivityFieldState.Hidden && (
                  <InputWithLabel
                    name="startTime"
                    type="time"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChangeStartTime(event.target.value)
                    }
                    label={
                      <FormattedMessage
                        id="activity.detail.time.start"
                        defaultMessage="Start time"
                      />
                    }
                    required={fieldsConfig?.startDate.required}
                    disabled={
                      fieldsConfig?.startDate.timeState ===
                      ActivityFieldState.ReadOnly
                    }
                  />
                )}
              </InlineDateTime>
            )}
            {(fieldsConfig?.endDate.dateState !== ActivityFieldState.Hidden ||
              fieldsConfig?.endDate?.timeState !==
                ActivityFieldState.Hidden) && (
              <InlineDateTime>
                {!fieldsConfig?.endDate.dateState && (
                  <InputWithLabel
                    name="endDate"
                    type="date"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChangeEndDate(event.target.value)
                    }
                    label={
                      <FormattedMessage
                        id="activity.detail.date.end"
                        defaultMessage="End date"
                      />
                    }
                    required={fieldsConfig?.endDate.required}
                  />
                )}
                {fieldsConfig?.endDate.timeState !==
                  ActivityFieldState.Hidden && (
                  <InputWithLabel
                    name="endTime"
                    type="time"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChangeEndTime(event.target.value)
                    }
                    label={
                      <FormattedMessage
                        id="activity.detail.time.end"
                        defaultMessage="End time"
                      />
                    }
                    required={fieldsConfig?.endDate.required}
                    disabled={
                      fieldsConfig?.endDate.timeState ===
                      ActivityFieldState.ReadOnly
                    }
                  />
                )}
              </InlineDateTime>
            )}
            {(fieldsConfig?.dueDate.dateState !== ActivityFieldState.Hidden ||
              fieldsConfig?.dueDate.timeState) !==
              ActivityFieldState.Hidden && (
              <InlineDateTime>
                {fieldsConfig?.dueDate.dateState !==
                  ActivityFieldState.Hidden && (
                  <InputWithLabel
                    name="dueDate"
                    type="date"
                    label={
                      <FormattedMessage
                        id="activity.detail.date.due"
                        defaultMessage="Due date"
                      />
                    }
                    onFocus={onFocusDueDate}
                    required={fieldsConfig?.dueDate.required}
                    disabled={
                      fieldsConfig?.dueDate.dateState ===
                      ActivityFieldState.ReadOnly
                    }
                  />
                )}
                {fieldsConfig?.dueDate.timeState !==
                  ActivityFieldState.Hidden && (
                  <InputWithLabel
                    name="dueTime"
                    type="time"
                    label={
                      <FormattedMessage
                        id="activity.detail.time.due"
                        defaultMessage="Due time"
                      />
                    }
                    onFocus={onFocusDueDate}
                    required={fieldsConfig?.dueDate.required}
                    disabled={
                      fieldsConfig?.dueDate.timeState ===
                      ActivityFieldState.ReadOnly
                    }
                  />
                )}
              </InlineDateTime>
            )}
            <InlineDateTime>
              {fieldsConfig?.duration.state !== ActivityFieldState.Hidden && (
                <DurationField
                  readOnly={
                    fieldsConfig?.duration.state === ActivityFieldState.ReadOnly
                  }
                />
              )}
              {fieldsConfig?.billable.state !== ActivityFieldState.Hidden && (
                <Checkbox
                  name="billable"
                  label={
                    <FormattedMessage
                      {...translationsActivity.detailBillable}
                    />
                  }
                  disabled={
                    fieldsConfig?.billable.state === ActivityFieldState.ReadOnly
                  }
                />
              )}
            </InlineDateTime>
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

function DurationField({ readOnly }: { readOnly: boolean }) {
  const { values, setFieldValue } = useFormikContext<ActivityFormFields>()

  const onFocus = () => {
    const defaultDuration = getDurationFromString('00:30')

    if (
      values.startDate !== null &&
      values.startDate !== '' &&
      values.startTime !== null &&
      values.startTime !== '' &&
      (values.endDate === null || values.endDate === '') &&
      (values.endTime === null || values.endTime === '') &&
      defaultDuration !== null
    ) {
      const endDate = formatDateForAPI(
        add(
          convertDateAndTimeToDate(values.startDate, values.startTime),
          defaultDuration
        )
      )

      setFieldValue('endDate', extractDateFromTimestamp(endDate))
      setFieldValue('endTime', extractTimeFromTimestamp(endDate))
    }
  }

  const onChange = (value: string) => {
    const duration = getDurationFromString(value)
    if (duration !== null && values.startDate && values.startTime) {
      const endDate = formatDateForAPI(
        add(
          convertDateAndTimeToDate(values.startDate, values.startTime),
          duration
        )
      )

      setFieldValue('endDate', extractDateFromTimestamp(endDate))
      setFieldValue('endTime', extractTimeFromTimestamp(endDate))
      setFieldValue('durationChanged', true)
      setFieldValue('endDateChanged', false)
    } else if (duration !== null && values.endDate && values.endTime) {
      const startDate = formatDateForAPI(
        sub(convertDateAndTimeToDate(values.endDate, values.endTime), duration)
      )

      setFieldValue('startDate', extractDateFromTimestamp(startDate))
      setFieldValue('startTime', extractTimeFromTimestamp(startDate))
      setFieldValue('durationChanged', true)
      setFieldValue('startDateChanged', false)
    }
  }

  return (
    <div className="flex-1 flex-col">
      <div className="flex flex-row items-center border-b border-gray-500">
        <label className="w-full">
          <span className="block text-2xs">
            <FormattedMessage {...translationsActivity.detailDateDuration} />
          </span>
          <input
            type="time"
            onFocus={onFocus}
            onChange={(event) => onChange(event.target.value)}
            value={
              values.startDate &&
              values.startTime &&
              values.endDate &&
              values.endTime &&
              isBefore(
                convertDateAndTimeToDate(values.startDate, values.startTime),
                convertDateAndTimeToDate(values.endDate, values.endTime)
              )
                ? getDurationAsString(
                    formatDateAndTimeForAPI(values.startDate, values.startTime),
                    formatDateAndTimeForAPI(values.endDate, values.endTime)
                  )
                : ''
            }
            className="w-full flex-1 border-none bg-transparent pt-0.5 pb-1 pl-0 text-black placeholder:text-gray-700 focus:outline-none"
            disabled={readOnly}
          />
        </label>
      </div>
    </div>
  )
}

export { TimingFormSection }
