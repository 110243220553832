import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { IconClose } from '../../../shared/svgs'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { Group } from '../../activity/types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsNotification } from '../translations/translationsNotification'
import { NotificationAddSection } from './NotificationAddSection'

const NotificationAdd: React.FC<{
  resourceType: ResourceType
  resourceId: string
  activtyGroup?: Group
}> = ({ resourceType, resourceId, activtyGroup }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage(translationsShared.goBack)}
            onClick={() => navigate(-1)}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage {...translationsNotification.addTitle} />
        </HeaderTitle>
      </HeaderArea>
      <NotificationAddSection
        resourceType={resourceType}
        resourceId={resourceId}
        activityGroup={activtyGroup}
      />
    </>
  )
}

export { NotificationAdd }
