import Dexie, { PromiseExtended } from 'dexie'

/**
 * Here we first reverse the result before we filter it, because if
 * there is no sort field specified, then we can make use of the
 * native sort functionality of IndexedDB. The filter function will
 * always be performed using Javascript.
 *
 * A good hint for that is to see if a function will be performed on
 * the Dexie Table object or the Dexie Collection object. The table
 * object represents native operations of IndexedDB, the collection
 * only Javascript operations.
 *
 * In addition, we split up queries for the count and for fetching the
 * items, because otherwise in some special cases it came to some side
 * effects where the result was not calculated right anymore.
 */
export async function getPaginated<T>(
  db: Dexie,
  tableName: string,
  offset: number,
  limit: number,
  options: {
    sortBy?: string
    sortDirection?: 'desc' | 'asc'
    filter?: (item: T) => boolean
  } = {}
): Promise<{ items: Array<T>; count: number }> {
  let queryCount = db.table(tableName).toCollection()
  let queryItems = options.sortBy
    ? db.table(tableName).orderBy(options.sortBy)
    : db.table(tableName).toCollection()

  if (options.sortDirection === 'desc') {
    queryItems = queryItems.reverse()
  }

  if (options.filter) {
    queryCount = queryCount.filter(options.filter)
    queryItems = queryItems.filter(options.filter)
  }

  return {
    count: await queryCount.count(),
    items: await queryItems.offset(offset).limit(limit).toArray(),
  }
}

/**
 * This function makes checks if ALL search terms match a multi value index.
 * The implementation has been taken from: https://github.com/dexie/Dexie.js/issues/281#issuecomment-229228163
 */
export function getPaginatedWithPrefixSearch<T>(
  db: Dexie,
  tableName: string,
  indexName: string,
  prefixes: string[],
  offset: number,
  limit: number,
  options: {
    sortBy?: string
    sortDirection?: 'desc' | 'asc'
    filter?: (item: T) => boolean
  } = {}
): PromiseExtended {
  return db.transaction('r', db.table(tableName), async function (): Promise<{
    items: Array<T>
    count: number
  }> {
    // Parallel search for all prefixes - just select resulting primary keys
    const results = await Dexie.Promise.all(
      prefixes.map((prefix) =>
        db
          .table(tableName)
          .where(indexName)
          .startsWithIgnoreCase(prefix)
          .primaryKeys()
      )
    )

    // Intersect result set of primary keys
    const reduced = results.reduce((a, b) => {
      const set = new Set(b)
      return a.filter((k) => set.has(k))
    })

    // Finally select entire documents from intersection
    let queryCount = db.table(tableName).where(':id').anyOf(reduced)
    let queryItems = db.table(tableName).where(':id').anyOf(reduced)

    if (options.filter) {
      queryCount = queryCount.filter(options.filter)
      queryItems = queryItems.filter(options.filter)
    }

    if (options.sortDirection === 'desc') {
      queryItems = queryItems.reverse()
    }

    if (options.sortBy) {
      return {
        count: await queryCount.count(),
        items: await queryItems
          .offset(offset)
          .limit(limit)
          .sortBy(options.sortBy),
      }
    } else {
      return {
        count: await queryCount.count(),
        items: await queryItems.offset(offset).limit(limit).toArray(),
      }
    }
  })
}
