import { defineMessages } from 'react-intl'

export const translationsAddress = defineMessages({
  overviewTitle: {
    id: 'address.overview.title',
    defaultMessage: 'Address Overview',
  },
  overviewList: {
    id: 'address.overview.list',
    defaultMessage: 'List of addresses',
  },
  favoriteUnmark: {
    id: 'address.favorite.unmark',
    defaultMessage: 'Unmark as Favorite',
  },
  favoriteMark: {
    id: 'address.favorite.mark',
    defaultMessage: 'Mark as Favorite',
  },
  overviewListEmpty: {
    id: 'address.overview.list.empty',
    defaultMessage: 'No addresses available',
  },
  detailTitle: {
    id: 'address.detail.title',
    defaultMessage: 'Address Details',
  },
  editTitle: {
    id: 'address.edit.title',
    defaultMessage: 'Edit Address',
  },
  buttonAdd: {
    id: 'address.button.add',
    defaultMessage: 'Add Address',
  },
  addTitle: {
    id: 'address.add.title',
    defaultMessage: 'Add Address',
  },
  overviewSearchStart: {
    id: 'address.overview.search.start',
    defaultMessage: 'Search addresses',
  },
})
