import React from 'react'
import { Dialog } from '@reach/dialog'
import { useIntl } from 'react-intl'
import { CostCenterGroupsSelection } from '../../costCenter/selection/CostCenterGroupsSelection'
import { DismissType } from '../../../shared/dialog/types'

type Props = {
  dismissType?: DismissType
  isOpen: boolean
  onConfirm: (value: string | null) => void
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirmFavorite: (value: string) => void
}

const SelectCostCenterGroupDialog: React.FC<Props> = ({
  dismissType = DismissType.close,
  onDismiss,
  isOpen,
  onConfirmFavorite,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={dismissType === DismissType.close ? onDismiss : undefined}
      aria-label={intl.formatMessage({
        id: 'timeTracking.select.costCenterGroup.title',
        defaultMessage: 'Select cost center group',
      })}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <CostCenterGroupsSelection
        dismissType={dismissType}
        onDismiss={onDismiss}
        onConfirmFavorite={onConfirmFavorite}
        {...props}
      />
    </Dialog>
  )
}

export { SelectCostCenterGroupDialog }
