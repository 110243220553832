import React, { ReactElement, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Dialog } from '@reach/dialog'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItem,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconKeyboardArrowRight, IconStore } from '../../../shared/svgs'
import { PaginatedData } from '../../../db/types'
import { PaginationActions } from '../../../shared/pagination/types'
import { DialogSelectionListItemWithText } from '../../../shared/dialog/selection/DialogSelectionListItemWithText'
import { Size } from '../../../shared/section/SectionItem'
import { translationsActivity } from '../translations/translationsActivity'
import { useGetUsers } from '../../user/db/users'
import { User } from '../../user/types'
import { translationsUser } from '../../user/translations/translationsUser'
import {
  deleteActivityGroupSettingItem,
  updateActivityGroupSettings,
} from '../db/responsibilityFIlters'

type Props = {
  isOpen: boolean
  onDismiss: () => void
  activityId: string
}

function ActivityOverviewAssigneeFilter({
  isOpen,
  onDismiss,
  activityId,
}: Props): ReactElement {
  const intl = useIntl()
  const isMounted = useRef(false)
  const [filterParams, setFilterParams] = useSearchParams()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  useEffect(() => {
    if (isMounted.current) {
      return
    }

    isMounted.current = true

    if (filterParams.has('assignedAddress')) {
      const responsibleUserId = filterParams.get('assignedAddress') ?? ''
      void updateActivityGroupSettings(activityId, responsibleUserId)
    }
  }, [activityId, filterParams])

  const searching = search !== ''

  const { data } = useGetUsers(search, page, size)

  const selectUser = (userId: string): void => {
    if (userId === '') {
      filterParams.delete('assignedAddress')
      setFilterParams(filterParams)

      void deleteActivityGroupSettingItem(activityId)
    } else {
      setFilterParams({ assignedAddress: userId })

      void updateActivityGroupSettings(activityId, userId)
    }

    onDismiss()
  }

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={intl.formatMessage(
        translationsActivity.overviewFilterAssigneeTitle
      )}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
      onDismiss={onDismiss}
    >
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={intl.formatMessage(
          translationsActivity.overviewFilterAssigneeTitle
        )}
        searchLabel={intl.formatMessage(
          translationsActivity.overviewFilterAssigneeSearch
        )}
        search={search}
        searchActions={searchActions}
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <UserDialogList
                searching={searching}
                onSelect={selectUser}
                selectedUser={filterParams.get('assignedAddress')}
                paginationActions={paginationActions}
                data={data}
              />
            )
          }
        />
      </ContentArea>
    </Dialog>
  )
}

function UserDialogList({
  searching,
  data,
  onSelect,
  selectedUser,
  paginationActions,
}: {
  searching: boolean
  data: PaginatedData<User>
  selectedUser: string | null
  onSelect: (userId: string) => void
} & PaginationActions) {
  const intl = useIntl()

  const title = searching
    ? intl.formatMessage(translationsActivity.assigneeDialogSearchResult)
    : intl.formatMessage(translationsUser.overviewList)

  const empty = searching
    ? intl.formatMessage(translationsActivity.assigneeDialogSearchEmpty)
    : intl.formatMessage(translationsActivity.assigneeDialogListEmpty)

  return (
    <>
      <ClearFilter onSelect={onSelect} />
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <SectionList>
          {data.items.map((user) => (
            <UserItem
              key={user.id}
              user={user}
              selected={selectedUser != null && user.id === selectedUser}
              onSelect={onSelect}
            />
          ))}
          {data.items.length === 0 && <Empty icon={IconStore} text={empty} />}
        </SectionList>
        <SectionPaginationInMemory
          total={data.total}
          size={data.size}
          page={data.page}
          paginationActions={paginationActions}
        />
      </Section>
    </>
  )
}

function ClearFilter({
  onSelect,
}: {
  onSelect: (userId: string) => void
}): ReactElement {
  const intl = useIntl()

  return (
    <Section>
      <SectionList>
        <DialogSelectionListItemWithText
          text={intl.formatMessage({
            id: 'user.overview.filter.clear',
            defaultMessage: 'Clear filter',
          })}
          itemId=""
          onSelect={onSelect}
        />
      </SectionList>
    </Section>
  )
}

function UserItem({
  user,
  selected,
  onSelect,
}: {
  user: User
  selected: boolean
  onSelect: (userId: string) => void
}): ReactElement {
  return (
    <button
      onClick={() => onSelect(user.id)}
      className={classNames(
        'block w-full focus:outline-none',
        selected ? 'bg-primary-500' : 'hover:bg-gray-500'
      )}
    >
      <SectionItem size={Size.sm}>
        <div className="flex-1 text-left">{user.name}</div>
        <IconKeyboardArrowRight className="h-5 w-5" />
      </SectionItem>
    </button>
  )
}

export { ActivityOverviewAssigneeFilter }
