import React, { useCallback, useState } from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useIntl } from 'react-intl'
import { DialogSearchHeader } from '../../../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../../../shared/content'
import { ArticleDialogList } from '../ArticleDialogList'
import { useSearchAndPaginationInMemory } from '../../../../../shared/pagination/inMemoryPagination'
import {
  getArticlesByArticleCode,
  useGetPaginatedArticles,
} from '../../../db/article'
import { DialogGenericAction } from '../../../../../shared/dialog/DialogGenericAction'
import { IconQrCodeScanner } from '../../../../../shared/svgs'
import { translationsActivity } from '../../../translations/translationsActivity'
import { Article } from '../../../types'
import { QrCodeView } from './QrCodeView'

const AddArticleView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
  selectableIds: string[]
}> = ({ onConfirm, onDismiss, selectableIds }) => {
  const intl = useIntl()
  const [scanning, setScanning] = useState<boolean>(false)
  let { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const filter = useCallback(
    (article: Article) => {
      return selectableIds.includes(article.id)
    },
    [selectableIds]
  )
  const { data } = useGetPaginatedArticles(search, page, size, filter)

  const title = searching
    ? intl.formatMessage({
        id: 'activity.article.search.result',
        defaultMessage: 'Search result',
      })
    : intl.formatMessage({
        id: 'activity.article.title',
        defaultMessage: 'All articles',
      })

  const empty = searching
    ? intl.formatMessage({
        id: 'activity.article.search.empty',
        defaultMessage: 'No articles could be found',
      })
    : intl.formatMessage({
        id: 'activity.article.list.empty',
        defaultMessage: 'No article available',
      })
  return !scanning ? (
    <>
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={intl.formatMessage(translationsActivity.articleAdd)}
        searchLabel={intl.formatMessage(
          translationsActivity.articleSearchStart
        )}
        search={search}
        searchActions={searchActions}
        additionalAction={
          <DialogGenericAction
            icon={IconQrCodeScanner}
            label={intl.formatMessage(translationsActivity.articleQRTitle)}
            onClick={() => {
              setScanning(!scanning)
            }}
          />
        }
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <ArticleDialogList
                title={title}
                empty={empty}
                data={data}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
        />
      </ContentArea>
    </>
  ) : (
    <QrCodeView
      onDismiss={() => setScanning(false)}
      onError={() => {
        setScanning(false)
      }}
      onScan={async (result) => {
        if (result) {
          try {
            const items = await getArticlesByArticleCode(result)

            if (items.length > 0) {
              onConfirm(items[0].id)
            } else {
              searchActions.search(result)
              setScanning(false)
            }
          } catch (e) {
            console.error(e)
            searchActions.search(result)
            setScanning(false)
          }
        }
      }}
    />
  )
}

export { AddArticleView }
