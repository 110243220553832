import * as Sentry from '@sentry/browser'
import { ovverideFiles } from './db/files'
import { getFiles } from './api'

export const syncFiles = async (): Promise<void> => {
  try {
    const files = await getFiles()
    await ovverideFiles(files)
  } catch (error) {
    Sentry.captureException(error)
  }
}
